import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';

import { MenuService } from "./providers/data/menu/menu.service";
import { LoginService } from "./providers/login.provider";
import { UsersProvider } from './providers/users.provider';
import { SampleComponent } from './main/sample/sample.component';
import { AuthGuard } from './providers/guards/auth-guard.service';
import { InitScreenComponent } from './main/init-screen/init-screen.component';
import { InitScreenModule } from './main/init-screen/init-screen.module';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { AppConfigService } from './providers/data/app-config.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CalendarComponent } from './main/calendar/calendar.component';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

const appRoutes: Routes = [   

    // Not found
    { path: '**', redirectTo: 'pages/error-404' },
    {
        path     : '',
        component: InitScreenComponent
    },
    {
        path: 'calendario',
        loadChildren: () => import('./main/calendar/calendar.module').then(m => m.CalendarModule)

    },
    {
        path     : 'app',
        loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'users',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path        : 'notifications',
        loadChildren: () => import('./main/notifications/notifications.module').then(m => m.NotificationsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path        : 'management', 
        loadChildren: () => import('./main/expedient/expedient.module').then(m => m.ExpedientModule)
    },
    {
        path        : 'promotion',
        loadChildren: () => import('./main/promotions/promotions.module').then(m => m.PromotionsModule),
        runGuardsAndResolvers: 'always',
    },
    {
        path        : 'agreements',
        loadChildren: () => import('./main/agreements/agreements.module').then(m => m.AgreementsModule)
    },
    {
        path: 'agreements-sign',
        loadChildren: () => import('./main/agreements-sign/agreements-sign.module').then(m => m.AgreementsSignModule)
    },
    {
        path        : 'sentences',
        loadChildren: () => import('./main/sentences/todo.module').then(m => m.SentencesModule)
    },
    {
        path: 'history-sentences',
        loadChildren: () => import('./main/history-sentences/history-sentences.module').then(m => m.HistorySentencesModule)
    },
    {
        path: 'visor',
        loadChildren: () => import('./main/visor/visor.module').then(m=>m.VisorModule)
    },
    {
        path: 'estadistic',
        loadChildren: () => import('./main/estadistic/visor.module').then(m=>m.EstadisticModule)
    },
    {
        path: 'sign-doc',
        loadChildren: () => import('./main/file-manager/file-manager.module').then(m=>m.FileManagerModule)
    },    
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        GuidedTourModule,

        // App modules
        LayoutModule,
        SampleModule,
        InitScreenModule,
        Error404Module
    ],
    bootstrap   : [
        AppComponent
    ],
    providers :[ GuidedTourService, { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class AppModule
{
    constructor(private _router:Router, 
                private _settings: AppConfigService,
                private _route: ActivatedRoute,
                private _menuService: MenuService,
                private _userService: UsersProvider,
                private _loginservice: LoginService) {

        const tokenSession = sessionStorage.getItem('tk');
        if (tokenSession !== null && tokenSession !== undefined) {
            const typeUser = this._settings.getNameTypeUser();
            console.log("typeUSer",typeUser);
            const user = sessionStorage.getItem('usr') ? JSON.parse(sessionStorage.getItem('usr')) : { vfcd: false };
            if(typeUser === 'empleado' || typeUser === 'superadmin' || typeUser === 'colaborador' || typeUser === 'Actuario' || typeUser === 'Secretario de acuerdos' || typeUser === 'Magistrado sala regional' || typeUser === 'Magistrado sala superior' || typeUser == 'Secretario general de acuerdos' || typeUser == 'Secretario proyectista sala regional' || typeUser == 'Secretario proyectista sala superior' || typeUser == 'Auxiliar' || typeUser == 'Magistrado Presidente'){
                        this._menuService.setMenu(typeUser);
            }else {
                if(user.vfcd) {
                    this._getInfoCitizenAuthoritie(user);
                }else {
                    this._router.navigate(['pages/errors/error-500']);
                }
            }
        }else {
            //cath path url login
            if(this._router.url === '/' || this._router.url === '/login') {
                //this._router.navigate(['/inicio']);
            }else {
               // this._router.navigate(['/pages/auth/register']);
            }
        }

    }   

    _getInfoCitizenAuthoritie(user){
        if(user.tipo == 'empleado'){
            const typeUser = 'getEmployeeData';
            const user_num = user.empe;
            this._userService[typeUser](user_num).subscribe(
                (response) => {;
                    const save_user = this._settings.toEncrypt(JSON.stringify(response['data']));
                    sessionStorage.setItem('tyus', save_user.toString());
                    this._menuService.setMenu(user.tipo);
                   // this._router.navigate(['inicio']);
                },
                (error) => console.error(error)
            );
        }else{
            const typeUser = user.ciud !== 0 ? 'getCitizenById' : 'getAutorityById';
            const user_num = user.ciud !== 0 ? user.ciud : user.auto;
            this._userService[typeUser](user_num).subscribe(
                (response) => {;
                    const save_user = this._settings.toEncrypt(JSON.stringify(response['data']));
                    sessionStorage.setItem('tyus', save_user.toString());
                    this._menuService.setMenu(user.tipo);
                   // this._router.navigate(['inicio']);
                },
                (error) => console.error(error)
            );
        }
    }
}
