import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Router } from '@angular/router';
import { MailComposeDialogComponentPdfAutoridad } from 'app/main/pdfMod2/pdf.component';
import { MailComposeDialogComponentPdfCiudadano } from 'app/main/pdfMod3/pdf.component';

import { MatDialog } from '@angular/material/dialog';
import Typewriter from 't-writer.js';

@Component({
  selector: 'init-screen',
  templateUrl: './init-screen.component.html',
  styleUrls: ['./init-screen.component.scss']
})
export class InitScreenComponent implements OnInit {

  currentUser:any;
  typeEmployee:any;
  tutorialCitizen: any = { name: 'Ciudadano', url: 'https://youtube.com'};
  tutorialAuthority: any = { name: 'Autoridad', url: 'https://youtube.com'};
  dialogRef: any;

  constructor(private _fuseConfigService: FuseConfigService,
    private _settings: AppConfigService,
    private _router: Router,
    public _matDialog: MatDialog,
    ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
          navbar   : {
              hidden: true
          },
          toolbar  : {
              hidden: true
          },
          footer   : {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
    };


  }

  ngOnInit(): void {
    this.currentUser  = this._settings.getCurrentUser();

    const userCurrent = this._settings.getCurrentUser();
    this.typeEmployee  = userCurrent ? userCurrent.tipo : undefined;
    this.tutorialCitizen = { name: 'Ciudadano', url: this._settings.getUrlTutorialCitizen() };
    this.tutorialAuthority = { name: 'Autoridad', url: this._settings.getUrlTutorialAuthority() };
    
    if(this.typeEmployee !== undefined) {
      this._router.navigate(['/app/inicio']);
    }else {
        console.log('not user')
        //this._router.navigate(['/pages/auth/login']);
    }

    // const target = document.querySelector('.tw')
    // const writer = new Typewriter(target, {
    //   loop: true, 
    //   typeColor: 'white' ,
    //   typeSpeed: 50 
    
    // })
    
    // writer
    //   .strings(
    //     1000,
    //     "Iniciamos una nueva era en la impartición de Justicia Administrativa, una era de innovación tecnológica, con la implementación del Tribunal Electrónico para la Justicia Administrativa."
    //   )
    //   .start()

  }

  toSignup(){
    this._router.navigate(['/pages/auth/login']);
  }
  abrirmodal2(inputFileName){
    const type = 1; //No generar URL
    console.log("Mod PDF", inputFileName);

    this.composeDialogurl2(inputFileName, type);
}
composeDialogurl2(file, type): void
{
this.dialogRef = this._matDialog.open(MailComposeDialogComponentPdfCiudadano, {
    width: '1000px',
    height:'700px',
    panelClass: 'mail-compose-dialog',
    data      : {
        file: file,
        type: type,
    }
});
}
  abrirmodal(inputFileName){
    const type = 1; //No generar URL
    console.log("Mod PDF", inputFileName);

    this.composeDialogurl(inputFileName, type);
}
composeDialogurl(file, type): void
{
this.dialogRef = this._matDialog.open(MailComposeDialogComponentPdfAutoridad, {
    width: '1000px',
    height:'700px',
    panelClass: 'mail-compose-dialog',
    data      : {
        file: file,
        type: type,
    }
});
}
}
