<div class="dialog-content-wrapper">
    

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
        <form class="pdfForm">
            <embed id="previewF" type="application/pdf" width="950px" height="650px"/>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        
    </div>
</div>
