import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';

@Injectable({providedIn: 'root'})
export class AuthService {
    public jwtHelper: JwtHelperService = new JwtHelperService();
    constructor() { }
    
    isAutenticated(){
        const token = sessionStorage.getItem('tk');
        if(token !== null &&
            sessionStorage.getItem('usr') !== null
            ) {
            return true
        }
        return false;
    }

    isEmployee(){
        const token = sessionStorage.getItem('tk');
        if(token !== null &&
            sessionStorage.getItem('em') !== null
            ) {
            return true
        }
        return false;
    }

    isActuario(){
        const token = sessionStorage.getItem('tk');
        if(token !== null &&
            sessionStorage.getItem('tyem') !== 'Actuario'
            ) {
            return true
        }
        return false;
    }

    isSecretario(){
        const token = sessionStorage.getItem('tk');
        if(token !== null &&
            sessionStorage.getItem('tyem') !== 'Actuario'
            ) {
            return true
        }
        return false;
    }
}