import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  urlTopic:string = '';
  urlMags:string = '';
  urlOfficExpe: string = '';
  urlOfficExpe2: string = '';
  urlOfficExpe3: string = '';
  urlOfficsente: string = '';

  urlRegistrable: string = '';
  onNewTopicForm: Subject<any>;
  onTopicSelected: Subject<any>;

  constructor(private _httpClient: HttpClient, private _settings: AppConfigService) { 
    this.urlTopic = this._settings.getUrlApi() + 'topics';
    this.urlMags = this._settings.getUrlApi() + 'employees/';
    this.urlOfficExpe = this._settings.getUrlApi() + 'partes-expedient';
    this.urlOfficExpe2 = this._settings.getUrlApi() + 'partes-expedient2';
    this.urlOfficExpe3 = this._settings.getUrlApi() + 'partes-expedient3';
    this.urlOfficsente = this._settings.getUrlApi() + 'partes-expedientsentencia';

    
    this.urlRegistrable = this._settings.getUrlApi() + 'registration-expedient';
    this.onNewTopicForm = new Subject();
    this.onTopicSelected = new Subject();
  }

  getTopicsByDepartment(nume){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.get(this.urlTopic+'/'+nume, optionHeaders);
  }

  getEmployeeMags(id){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlMags+id+'/magistrates', optionHeaders);
  }

  getEmployeeProye(id){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.get(this.urlMags+id+'/proyectistas', optionHeaders);
  }

  getOfficialExpedient(data,type){
    let optionHeaders = this._settings.getHeadersJson();

    if (type===null) {
      return this._httpClient.get(this.urlOfficExpe+'?une='+data, optionHeaders);
    }

    return this._httpClient.get(this.urlOfficExpe+'?'+type+'='+data, optionHeaders);
  }
  getOfficialExpedient2(data,type){
    let optionHeaders = this._settings.getHeadersJson();

    if (type===null) {
      return this._httpClient.get(this.urlOfficExpe2+'?une='+data, optionHeaders);
    }

    return this._httpClient.get(this.urlOfficExpe2+'?'+type+'='+data, optionHeaders);
  }
  getOfficialExpedient3(data,type){
    let optionHeaders = this._settings.getHeadersJson();

    if (type===null) {
      return this._httpClient.get(this.urlOfficExpe3+'?une='+data, optionHeaders);
    }

    return this._httpClient.get(this.urlOfficExpe3+'?'+type+'='+data, optionHeaders);
  }
  getOfficialsentencia(data,type){
    let optionHeaders = this._settings.getHeadersJson();

    if (type===null) {
      return this._httpClient.get(this.urlOfficsente+'?une='+data, optionHeaders);
    }

    return this._httpClient.get(this.urlOfficsente+'?'+type+'='+data, optionHeaders);
  }
  getRegistrable(params){
    let optionHeaders = this._settings.getHeadersJson();
    return this._httpClient.post(this.urlRegistrable, params,optionHeaders);
  }

  saveTopic(data){
    let optionHeaders = this._settings.getHeadersMultipart();
    return this._httpClient.post(this.urlTopic, data,optionHeaders);
  }
}
