import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppConfigService } from '../app-config.service';
import { adminMenu, actuaryMenu, streamMenu, secretaryMenu, authorityMenu, authorityAdminMenu, streamMag, streamProyec, streamAux, streamMagSup } from "./menu-data";

@Injectable({providedIn: 'root'})
export class MenuService {

    navigation: Array<any>;

    constructor(private _fuseNavigationService: FuseNavigationService,
                private _settings: AppConfigService) {
        this.navigation = [];
        
        // Get default navigation
        //this.navigation = navigation;

    }

    addMenu(newNavigation) {
        //set new navigation
        this.navigation = newNavigation;
         // Register the navigation to the service
        this._fuseNavigationService.register('principal', newNavigation);
        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('principal');
    }

    getMenu() {
        return this.navigation;
    }

    setMenu = (typeUser: String) => {
        //const info = this.auth.getDecodedAccessToken(userToken).data;
        console.log("tipo de usuario iniciado service", typeUser);
        const menu = this._fuseNavigationService.getCurrentNavigation();  
        if( menu == undefined) {
            switch (typeUser) {
                case 'superadmin':
                case 'colaborador':
                    this.addMenu(adminMenu);
                    break;
                case 'Actuario':
                    this.addMenu(actuaryMenu);
                    break;
                case 'Secretario de acuerdos':
                    this.addMenu(secretaryMenu);
                    break;
                case 'ciudadano':
                    this.addMenu(streamMenu);
                    break;
                case 'autoridad':
                    const user = this._settings.getInfoUserSystem();
                    const permission = user.conf;
                    this.addMenu(authorityMenu);
                    if(user.admi || user.repr){
                        this.delMenu();
                        this.getMenuAuthorityByPermission(permission)
                    }
                    break;
                case 'Magistrado sala regional':
                    this.addMenu(streamMag);
                break;
                case 'Magistrado sala superior':
                    this.addMenu(streamMagSup);
                break;
                case 'Magistrado Presidente':
                    this.addMenu(streamMag);
                    break;
                case 'Secretario general de acuerdos':
                    this.addMenu(secretaryMenu);
                break;
                case 'Secretario proyectista sala regional':
                    this.addMenu(streamProyec);
                break;
                case 'Secretario proyectista sala superior':
                    this.addMenu(streamProyec);
                break;
                case 'Auxiliar':
                    this.addMenu(streamAux);
                break;
            }    
        }else {
            this.delMenu();
            this.setMenu(typeUser);
        }
    }

    delMenu() {
        //delete current navigation
        this._fuseNavigationService.unregister('principal'); 
    }

    getMenuAuthorityByPermission(permission) {
        if (permission) {
            const permissionsUser = JSON.parse(permission);
            const permissions = [{id: 'sample', father: 'sample'}, ...permissionsUser];
            const indexPromotionMenu = permissionsUser.find(item => item.id.includes('create-promotion'));
            if(indexPromotionMenu){
                permissions.push({id: 'create-demand', father: 'manager-promotion'});
            }
            this.validPermission(authorityAdminMenu[0], permissions, 'father')
            .then(resultMenu => { 
                authorityAdminMenu[0].children = resultMenu;
                this.addMenu(authorityAdminMenu)
            }) 
            .catch(err => { return  authorityMenu });

        }else {
            this.addMenu(authorityAdminMenu)
        }

    }

    async validPermission(menu, permission, search) {
        const nameMenus = permission.map(m => m[search]);
        const options = await menu.children.reduce((acc, currenItem, index) => {
            if(nameMenus.includes(currenItem.id)){
                if(currenItem.hasOwnProperty('children')){
                    this.validPermission(currenItem, permission, 'id').then(
                        resultChildren => currenItem.children = resultChildren
                    ).catch((err) => console.warn('error al cargar menu', err));
                }
                return [...acc, currenItem]
            }
            return acc;
        }, []);
        return options;
    }

}
/* params Menu
id       : 'sample',
title    : 'Dashboard',
translate?: 'NAV.SAMPLE.TITLE',
type     : 'item',
icon     ?: 'email',
url      ?: '/sample',
badge    ?: {
    title    : '25',
    translate?: 'NAV.SAMPLE.BADGE',
    bg       : '#F44336',
    fg       : '#FFFFFF'
}
*/