import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { DocumentProvider } from 'app/providers/documents.register.provider';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit {
  informationGuard: any = '';

  constructor(public matDialogRef: MatDialogRef<InformationModalComponent>,
              private _settings: AppConfigService,    private _documentService: DocumentProvider,
              ) { }

  ngOnInit(): void {
    this._documentService.getcalendarioguard().subscribe(
      (response)=>{
        this.informationGuard = response['descripcion'];
      }
    )
   
  }

}
