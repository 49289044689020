<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

    <div class="content" fxLayout="column" fxLayoutAlign="center center">

        <div class="error-code">404</div>

        <div class="message">No fue posible encontrar la ruta</div>

        <!--div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon s-24">search</mat-icon>
            <input placeholder="Search for something" fxFlex>
        </div-->

        <a class="back-link" [routerLink]="'/notifications/mail/inbox'">Regresar al Inbox</a>

    </div>

</div>