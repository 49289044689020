<div class="dialog-content-wrapper">
    <div mat-dialog-content>
		<div [innerHTML]="informationGuard"></div>
        <button mat-raised-button
                color="accent"
                (click)="matDialogRef.close()"
                class="save-button"
                aria-label="SAVE">
            Aceptar
        </button>
    </div>
</div>
