import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef, NgModule } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourcesProvider } from 'app/providers/resources.provider';
import { ExpedientProvider } from 'app/providers/expedient.provider';
import { UsersProvider } from "app/providers/users.provider";
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { FileAnexo, TypeFile, OrganoJurisdiccional, TipoParte } from 'app/main/promotions/demanda-form/options.data.form';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TypeChildProceduresSentence, TypeJudgmentNulidad2, TypeChildIncidentSentence, TypeChildResourceSentence, TypeSentence, TypeSentidoSentence, TypeVotations, TypeJudgmentNulidad, TypeProces, MagPortalSentences, OrganoEmisorPortal, MateriaPortal, TypeEmiPortal } from 'app/providers/resources.provider';
import { SentenceFormService } from './sentence-form.service';
import { TopicsService } from 'app/main/topics/topics.service';
import { TodoService } from 'app/main/sentences/todo.service';

import { MatDialog } from '@angular/material/dialog';
import { MailComposeDialogComponentPdf } from 'app/main/pdfMod/pdf.component';
import { DocumentProvider } from 'app/providers/documents.register.provider';
import { SelectFileSign, message, SelectPfx, OpenPfx, PKCS7creado, extFileUpload, resetFlagsSignature } from 'app/main/sign/common/functions/cliente';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'mail-compose',
    templateUrl: './compose.component.html',
    styleUrls: ['./compose.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MailComposeDialogComponent {
    isLoading: boolean = false;
    isAcuerdoNotification: boolean = false;
    isEmplazarAutoridad: boolean = false;
    isEmplazarCiudadano: boolean = false;
    isEmplazarParticular: boolean = false;
    isFisicNotification: boolean = false;
    isFisicNotificationAcue: boolean = false;
    isFisicNotificationEstrados: boolean = false;
    isNotificationSala: boolean = false;
    notIsRegisteredAuthority: boolean = false;
    dontHaveTitularsAuth: boolean = false;
    showExtraToFields: boolean;
    resolutionForm: FormGroup;
    filePFXselct: any;
    pkcs7: string;
    extArchivoCargado: string = "";

    expedientForm: FormGroup;
    firmaForm: FormGroup;
    transferForm: FormGroup;
    placeForm: FormGroup;
    placeCitiForm: FormGroup;
    usersForm; FormGroup;
    departments = [];
    departments2 = [];

    us = [];

    allDepartaments = OrganoJurisdiccional.filter(dep => dep.nume < 14);
    users = [];
    autorities = [];
    municipalities: any = [];
    nameList: string[] = [];
    autoritiesList: string[] = [];
    titularsList: string[] = [];
    filteredOptions: Observable<string[]>;
    currentUser = { empe: '' }
    txtErrorNoti = '';
    agreementData: any;
    inSearching = false;
    employeeDepa: any;
    txtTitleModal = 'Nueva notificación'
    txtTitleUser: string = 'USUARIOS A NOTIFICAR POR VÍA ELECTRÓNICA';
    txtDeterminacion = 'A NOTIFICAR';
    txtTitleDocs: string = 'Adjuntar PDF de determinación *';
    txtErrorFile: String = 'Debes adjuntar este documento es obligatorio'
    txtErrorFileadju: String = this.txtErrorFile;
    //files 
    arrayTypeProcess: any[] = TypeProces;
    bloquen: boolean = false;
    typeFile = TypeFile;
    anexosArray = ArrayFiles;
    toUploadOk = false;
    isDepaEspecializada = false;
    currentFile: any;
    showMoreFileBtn = false;
    parteTypes = TipoParte.sort(_compare_item);
    caracterReconocido: any = [];
    tiprecu: any = [];
    promotions: any[];
    siredate: string;
    promotionsDocumentos: any[];

    //date
    minDate: Date;
    maxDate: Date;
    procesop: String = ''
    conutcc: any;
    //pdf
    dialogRef: any;
    page: number = 1;
    pdfSrc: string = '';
    bansente = false;
    selections: any[];
    documentos: any[];
    arregloselec: any[] = [];
    arregloselec2: any[] = [];
    arrayDocumentsVotos:any[]=[];
    fileLaravel: any;
    idag:any;
    displayedColumnsPromotions: string[] = ['promotion', 'name', 'weight', 'symbol', 'date', 'dateS'];

    @ViewChild('adju') adju: ElementRef;
    @ViewChild('stepper') stepper: MatStepper;

    /**
     * Constructor
     *
     * @param {MatDialogRef<MailComposeDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<MailComposeDialogComponent>,
        private _formBuilder: FormBuilder,
        private _formBuilder2: FormBuilder,
        private cd: ChangeDetectorRef,
        private _expedientServices: ExpedientProvider,
        private _usersService: UsersProvider,
        public _matDialog: MatDialog,
        private _resourcesService: ResourcesProvider,
        private _settings: AppConfigService,
        private _sentenceFormService: SentenceFormService,
        private _topicService: TopicsService,
        private _documentService: DocumentProvider,
        private _matSnackBar: MatSnackBar,


        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        //data to dialog
        this.setVariablesModal();
        console.log("todoservice",this._data);
        // Set the defaults
        this.selections = this._data.selec;
        if(this._data.votos!=null){
            if(this._data.votos.length!=0){
                this._documentService.getVotoDocuments2(this._data.acuerdo.id).subscribe(
                    (response)=>{
                        console.log("response de documentos",response);
                        this._data.votos = response;
                    }
                ) ;
            }
        }
        
       
        let promise = new Promise((resolve, reject) => {
            for (let a = 0; a < this._data.selec.length; a++) {
                this._documentService.getDocumentsUploaded2(this._data.selec[a]['nume']).subscribe(
                    (result) => {
                        this.arregloselec.push(result);
                        console.log(" this.arregloselec", this.arregloselec);

                    },
                    (error) => console.log('error get documents', error)
                );
            }


        });
        let promise2 = new Promise((resolve, reject) => {
            for (let a = 0; a < this._data.selec.length; a++) {
                this._documentService.getDocumentsUploaded(this._data.selec[a]['nume']).subscribe(
                    (result) => {
                        this.arregloselec2.push(result['data']);
                        console.log(" this.arregloselec2", this.arregloselec2);

                    },
                    (error) => console.log('error get documents', error)
                );
            }


        });
        this.documentos = this._data.docum;
        console.log("this._data.typeNotification", this._data);
        if (this._data.typeNotification === 'acue-fisis2' || this._data.typeNotification === 'acue-salas' || this._data.typeNotification === 'emplazamientos' || this._data.typeNotification === 'emplazar-particulars' || this._data.typeNotification === 'emplazar-ciudadanos' || this._data.typeNotification === 'emplazar-ciudadanos' || this._data.typeNotification === 'acuerdos2' || this._data.typeNotification === "estrados2" || this._data.typeNotification === "emplazars" || this._data.typeNotification === 'acue-fisis2') {
            this.bansente = true;
            this.expedientForm = this.createExpedientFormsenten();
            console.log("debug resolutionForm constructor 1");

            this.resolutionForm = this.createresolutionForm();

            this.firmaForm = this.createFirmaForm();


        } else {
            this.expedientForm = this.createExpedientForm();
            this.firmaForm = this.createFirmaForm();
            console.log("debug resolutionForm constructor 2");
            this.resolutionForm = this.createresolutionForm();
            if (this._data.typeNotification === 'acue-sala') {
                this.resolutionForm.get('tppa').clearValidators();
                this.resolutionForm.get('tppa').updateValueAndValidity();
            }

        }
        if (this._data.typeNotification == "bloque") {
            console.log("notificacion en bloque");
            this.bloquen = true;
            this.txtTitleModal = 'Nueva notificación en Bloque';

            console.log("this.agreementData.length", this._data);
            this.conutcc = this._data['acuerdo'];
            let b=[];
           for(let i=0;i<this.conutcc.length;i++){
            this._getPromotions(this.conutcc[i].id);
            b=this.promotions;
           }
        }
        this.usersForm = this.createUsersForm();
        this.showExtraToFields = true;
        //set date minimum
        const currentDate = moment();
        this.minDate = new Date(currentDate.year(), currentDate.month(), currentDate.date() + 1);
        this.maxDate = new Date(currentDate.year(), currentDate.month(), currentDate.date() + 4);
    }

    ngOnInit() {
        this.anexosArray = [new FileAnexo('anexoFile0')];
        this.toUploadOk = false;
        this.currentFile = undefined;
        this.showMoreFileBtn = false;
        if (sessionStorage.getItem('usr') !== undefined) {
            this.currentUser = this._settings.getCurrentUser();
        }

        this.employeeDepa = this._settings.getDepaEmployee()
        this._getAllDepartments();
        this._validIfShowFieldsEspecializada();
        if (this.isAcuerdoNotification || this.isFisicNotificationAcue) {
            this.setInfoAgreement();
            if (this.agreementData != null) {
                console.log("datos para buscar ciudadano123213", this.agreementData);
                if (this.bansente == true) {
                    this._getUsersAgreement(this.agreementData.id);

                } else {
                    this._getUsersAgreement(this.agreementData.nume);

                }

            }
        }

        if (this.isFisicNotificationEstrados) {
            this.fepu.setValidators([Validators.required]);
            this.fepu.updateValueAndValidity();
        }
        this._validIfEspecializadaToRemision();
    }

    _validIfShowFieldsEspecializada() {
        const employeeData = this._settings.getTypeEmployee();
        if (employeeData === 'Actuario') {
            this.caracterReconocido = this._resourcesService.getCaracterReconocidoAu();

            this.tiprecu = this._resourcesService.tiporecurso();
            console.log("caracter rautos: ", this.caracterReconocido);
            if ((this.employeeDepa === 8) ||
                (this.employeeDepa === 9) ||
                (this.employeeDepa === 13)) {
                this.isDepaEspecializada = true;
            }
        }
    }

    _validIfEspecializadaToRemision() {
        const employeeData = this._settings.getTypeEmployee();
        if (employeeData === 'Actuario' && this._data.typeNotification === 'remision') {
            //valid salas especializadas
            if ((this.employeeDepa === 8) ||
                (this.employeeDepa === 9)) {
                this.allDepartaments = this.allDepartaments.filter(element => element.nume === 13)
                this.transferForm.patchValue({ depa: '13' });
                this.txtTitleModal = 'Nueva remisión de recurso de apelación';
                //this.transferForm.controls['depa'].disable();
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    setVariablesModal() {
        const _config = this._data;
        console.log('_config.typeNotification', _config);
        if (_config.typeNotification === 'acuerdo' || _config.typeNotification === 'acue-ofic' || _config.typeNotification === 'acuerdos2') {
            this.txtTitleModal = 'Nueva notificación electrónica de acuerdo';
            this.isAcuerdoNotification = true;
            this.agreementData = _config.acuerdo;
        } else if (_config.typeNotification === 'fisica') {
            this.isFisicNotification = true;
            this.txtTitleModal = 'Nueva notificación física';
            this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
            this.txtDeterminacion = 'NOTIFICADA';
            this.txtTitleDocs = 'Adjuntar constancia de notificación *'
        } else if (_config.typeNotification === 'estrados') {
            this.isFisicNotificationEstrados = true;
            this.txtTitleModal = 'Nueva notificación de acuerdo por estrados';
            this.isAcuerdoNotification = true;
            this.txtTitleUser = 'FECHA A NOTIFICAR POR ESTRADOS';
            this.agreementData = _config.acuerdo;
        } else
            if (_config.typeNotification === 'estrados2') {
                this.isFisicNotificationEstrados = true;
                this.txtTitleModal = 'Nueva notificación de acuerdo por estrados';
                this.isAcuerdoNotification = true;
                this.txtTitleUser = 'FECHA A NOTIFICAR POR ESTRADOS';
                this.agreementData = _config.acuerdo;
            } else if (_config.typeNotification === 'acue-fisi') {
                this.txtTitleModal = 'Nueva notificación de acuerdo física';
                this.isFisicNotificationAcue = true;
                this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
                this.txtDeterminacion = 'NOTIFICADA';
                this.txtTitleDocs = 'Adjuntar constancia de notificación *'
                this.agreementData = _config.acuerdo;
            }
            else if (_config.typeNotification === "acue-fisis2") {
                this.txtTitleModal = 'Nueva notificación de acuerdo física';
                this.isFisicNotificationAcue = true;
                this.txtTitleUser = 'USUARIOS NOTIFICADOS DE MANERA FÍSICA';
                this.txtDeterminacion = 'NOTIFICADA';
                this.txtTitleDocs = 'Adjuntar constancia de notificación *'
                this.agreementData = _config.acuerdo;
            } else if (_config.typeNotification === 'remision') {
                this.isNotificationSala = true;
                this.agreementData = _config.acuerdo;
                this.transferForm = this.createTransferForm();
            } if (_config.typeNotification === 'emplazar') {
                this.expedientForm = this.createExpedientForm();
                this.firmaForm = this.createFirmaForm();

                this._getMunicipalities();
                this.txtTitleModal = 'Emplazar autoridad';
                this.isAcuerdoNotification = true;
                this.isEmplazarAutoridad = true;
                this.agreementData = _config.acuerdo;
                this.placeForm = this.createEmplazarForm();

                /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
                setTimeout(() => {
                    this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                        startWith(''),
                        map(value => this._filter(value))
                    )
                }, 0);
            }
        if (_config.typeNotification === 'emplazars') {
            this.expedientForm = this.createExpedientForm();
            this.firmaForm = this.createFirmaForm();

            this._getMunicipalities();
            this.txtTitleModal = 'Emplazar autoridad';
            this.isAcuerdoNotification = true;
            this.isEmplazarAutoridad = true;
            this.agreementData = _config.acuerdo;
            this.placeForm = this.createEmplazarForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
        if (_config.typeNotification === 'emplazar-ciudadanos') {
            this.expedientForm = this.createExpedientForm();
            console.log("debug resolutionForm constructor 3");

            this.resolutionForm = this.createresolutionForm();
            this.firmaForm = this.createFirmaForm();

            this._getMunicipalities();
            this.txtTitleModal = 'Emplazar ciudadano';
            this.isAcuerdoNotification = true;
            //this.isEmplazarAutoridad = true;
            this.isEmplazarCiudadano = true;
            this.agreementData = _config.acuerdo;
            this.placeCitiForm = this.createEmplazarCitiForm();
            this.placeForm = this.createEmplazarParticularForm();

            //this.placeForm = this.createEmplazarForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            /*setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);*/
        }
        if (_config.typeNotification === 'emplazar-ciudadano') {
            this.expedientForm = this.createExpedientForm();
            this.firmaForm = this.createFirmaForm();
            console.log("debug resolutionForm constructor 4");

            this.resolutionForm = this.createresolutionForm();

            this._getMunicipalities();
            this.txtTitleModal = 'Emplazar ciudadano';
            this.isAcuerdoNotification = true;
            //this.isEmplazarAutoridad = true;
            this.isEmplazarCiudadano = true;
            this.agreementData = _config.acuerdo;
            this.placeCitiForm = this.createEmplazarCitiForm();
            this.placeForm = this.createEmplazarParticularForm();

            //this.placeForm = this.createEmplazarForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            /*setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);*/
        }
        if (_config.typeNotification === 'emplazar-particulars') {
            this.txtTitleModal = 'Emplazar particular';
            this.isAcuerdoNotification = true;
            this.isEmplazarParticular = true;
            this.agreementData = _config.acuerdo;
            this._getEntPartList();
            this.placeForm = this.createEmplazarParticularForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
        if (_config.typeNotification === 'emplazar-particular') {
            this.txtTitleModal = 'Emplazar particular';
            this.isAcuerdoNotification = true;
            this.isEmplazarParticular = true;
            this.agreementData = _config.acuerdo;
            this._getEntPartList();
            this.placeForm = this.createEmplazarParticularForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
        if (_config.typeNotification === 'emplazamientos') {
            console.log("emplazamiento");
            this._getMunicipalities();
            this.expedientForm = this.createExpedientForm();
            this.txtTitleModal = 'Emplazamiento';
            this.isAcuerdoNotification = true;
            this.isEmplazarAutoridad = true;
            this.agreementData = _config.acuerdo;
            this.placeForm = this.createEmplazamientoForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
        if (_config.typeNotification === 'emplazamiento') {
            this._getMunicipalities();
            this.expedientForm = this.createExpedientForm();
            this.txtTitleModal = 'Emplazamiento';
            this.isAcuerdoNotification = true;
            this.isEmplazarAutoridad = true;
            this.agreementData = _config.acuerdo;
            this.placeForm = this.createEmplazamientoForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
            ;
        }
        if (_config.typeNotification === 'acue-sala') {
            this._getMunicipalities();
            this.txtTitleModal = 'Notificar a Sala';
            this.expedientForm = this.createEmplazamientoForm();
            this.isAcuerdoNotification = true;
            this.isEmplazarAutoridad = true;
            this.isNotificationSala = true;
            console.log("debug resolutionForm constructor 5");

            this.resolutionForm = this.createresolutionForm();          
            this.agreementData = _config.acuerdo;
            this.placeForm = this.createNotisForm();
            console.log("expedientForm: ", this.expedientForm);

            this.resolutionForm.get('tppa').clearValidators();
            this.resolutionForm.get('tppa').updateValueAndValidity();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
        if (_config.typeNotification === 'acue-salas') {
            console.log("acue-salasd1", _config.typeNotification);
            this._getMunicipalities();
            this.expedientForm = this.createExpedientFormsenten();
            this.txtTitleModal = 'Emplazamiento';
            this.isAcuerdoNotification = true;
            this.isEmplazarAutoridad = true;
            this.agreementData = _config.acuerdo;
            this.placeForm = this.createEmplazamientoForm();
            /*AUTO COMPLETE LISTENER NAME AUTORITIES*/
            setTimeout(() => {
                this.filteredOptions = this.placeForm.get('auto').valueChanges.pipe(
                    startWith(''),
                    map(value => this._filter(value))
                )
            }, 0);
        }
    }
    SeleccionarPfx(filePFX: any) {

        console.log("entra aqui");
        this.filePFXselct = filePFX;
        this.resolutionForm.get('password').enable();

    }
    firmad() {
        console.log(this.resolutionForm.value);
        console.log(this.filePFXselct);


    }
    /**
     * Create compose form
     *
     * @returns {FormGroup}
     */
    createresolutionAcForm(): FormGroup {
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            tipo: ['', Validators.required],
            fecd: ['', Validators.required],
            adju: ['', Validators.required],
            tppa: ['', Validators.required],
            inte: ['', Validators.required],
            password: [],

            time: [''],
            anexos: [false]
        });
    }
    createresolutionForm(): FormGroup {
if(this._data.typeNotification === 'Notificar a Sala'){
console.log("notisala entra");
}else
        if (this._data.typeNotification === 'emplazamiento') {
            console.log("notisala no entra");

            this.anexosArray = [new FileAnexo('anexoFile0')];
            return this._formBuilder.group({
                tipo: ['', Validators.required],
                fecd: ['', Validators.required],
                adju: ['', Validators.required],
                tppa: ['', Validators.required],


                fecn: [''],
                time: [''],
                anexos: [false]
            });
        } else if (this._data.typeNotification === 'emplazar-particular') {
            this.anexosArray = [new FileAnexo('anexoFile0')];
            return this._formBuilder.group({
                tipo: ['', Validators.required],
                fecd: ['', Validators.required],
                adju: ['', Validators.required],
                tppa: ['', Validators.required],
                anexos: [false]
            });
        } else {
            this.anexosArray = [new FileAnexo('anexoFile0')];
            return this._formBuilder.group({
                tipo: ['', Validators.required],
                fecd: ['', Validators.required],
                adju: ['', Validators.required],
                tppa: ['', Validators.required],

                fecn: [''],
                time: [''],
                anexos: [false]
            });
        }
    }

    createTransferForm(): FormGroup {
        let nume = null;
        if (this.agreementData != null) {
            nume = this.agreementData.nume || '';

        }
        this.anexosArray = [new FileAnexo('anexoFile0')];
        return this._formBuilder.group({
            nume: [nume, Validators.required],
            depa: ['', Validators.required],
            anexos: [false]
        });
    }

    createEmplazarForm(): FormGroup {
        return this._formBuilder.group({
            muni: [''],
            tpau: [''],

            auto: [''],
            autoId: [''],
            titu: [''],
            mail: ['', Validators.email]
        });
    }
    previewFileurl(inputFileName){
        const type = 1; //No generar URL
        console.log("Mod PDF", inputFileName);
    
        this.composeDialogurl(inputFileName, type);
    }
    composeDialogurl(file, type): void
{
    this.dialogRef = this._matDialog.open(MailComposeDialogComponentPdf, {
        width: '1000px',
        height:'700px',
        panelClass: 'mail-compose-dialog',
        data      : {
            file: file,
            type: type,
        }
    });
}
    createEmplazamientoForm(): FormGroup {
        if (this.txtTitleModal == 'Notificar a Sala') {
            return this._formBuilder.group({
                //  tr :['', Validators.required],

            });
        } else {
            return this._formBuilder.group({
                muni: [''],
                tpau: [''],
                cru: [''],
                //  tr :[''],
                auto: [''],
                autoId: [''],
                titu: [''],
                mail: ['', Validators.email]
            });
        }


    } createEmplazarParticularForm(): FormGroup {
        return this._formBuilder.group({
            auto: [''],
            apellPatPart: [''],

            apellMatPart: [''],
            curpPart: [''],
            mail: ['', Validators.email]
        });
    } createNotisForm(): FormGroup {
        return this._formBuilder.group({
            muni: [''],
            tpau: [''],
            auto: [''],
            autoId: [''],
            titu: [''],
            mail: ['', Validators.email]

        });
    }
    createEmplazarCitiForm(): FormGroup {
        return this._formBuilder.group({
            name: ['', Validators.required],
        });
    }
    notisa() {
        let une = { value: '', disabled: false };
        if (this.isAcuerdoNotification || this.isFisicNotificationAcue) {
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            une = { value: expe, disabled: true }
        }
        return this._formBuilder.group({

            //   tr :  ['', Validators.required],
        });
    }

    createFirmaForm(): FormGroup {
        if (this._data.typeNotification != 'acue-fisi' && this._data.typeNotification != 'acue-fisis2') {
            return this._formBuilder.group({
                password: ['', Validators.required]
            })
        }

    }

    _getPromotions(agreement_id) {
        console.log("agreement_id", agreement_id);
        this.promotions = [];
        this.siredate == '';

        this._documentService.getPromotionsByAgreement(agreement_id).subscribe(
            (result) => {
                if (result['data'].length > 0) {
                    console.log("this.promotions", result);
                    this.promotions = result['data'];
                    this.siredate = this.promotions[0].siretd;
                    console.log('this.siredate', this.siredate);

                    this.promotions.forEach(promotions => {
                    
                        let promise = new Promise((resolve, reject) => {
                            this._documentService.getDocumentsUploaded(promotions['nume']).subscribe(
                                (result) => {
                                    console.log("debug promos 2",result['data']);
                                    this.promotionsDocumentos = result['data'];
                                    promotions.promoDocs=result['data'];
                                    console.log("this.promotions", promotions);

                                },
                                (error) => console.log('error get documents', error)
                            );
                            
                            resolve(promotions['une']);
                        });

                        
                    });

                    /* let promise = new Promise((resolve, reject) => {
                        this._documentService.getDocumentsUploaded(this.promotions[0]['nume']).subscribe(
                            (result) => {
                                console.log("debug promos 2",result['data']);

                                this.promotionsDocumentos = result['data'];
                            },
                            (error) => console.log('error get documents', error)
                        );
                        this.promotions.forEach(element => {
                            this.notificationFinalized = element.acuedata.data.fina ? true : false;
                            this.notificationActivedText = this.notificationFinalized ? 'Abrir' : 'Cerrar';
                            element.tram = this.setTramName(element.tram)
                        });
                        resolve(this.promotions[0]['une']);
                    }); */

                   

                }
            },
            (error) => console.log('error get documents', error)
        );
    }
    previewFile2(inputFileName) {
        const type = 1; //No generar URL
        console.log("Mod PDF", inputFileName);

        this.composeDialog22(inputFileName, type);
    }

    composeDialog22(file, type): void {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponentPdf, {
            width: '1000px',
            height: '700px',
            panelClass: 'mail-compose-dialog',
            data: {
                file: file,
                type: type,
            }
        });
    }
    createExpedientForm(): FormGroup {
        let une = { value: '', disabled: false };

        let une2 = { value: '', disabled: false };

        let proc = { value: '', disabled: false };
        let procesop = { value: this.procesop, disabled: false }
        if (this.agreementData != null) {

            console.log(this._data);
            if (this._data['acuerdo'] != undefined) {
                this._topicService.getOfficialExpedient2(this._data['acuerdo']['une'], 'une',).subscribe(
                    (response) => {
                        console.log("response de getofficls", response);
                        console.log("response de getofficl2", this.expedientForm);

                        this.expedientForm.get('proce').setValue(response['data']['judg']);
                        this.expedientForm.get('acue').setValue(this._data['acuerdo']['expe']);
                        this.expedientForm.get('expe').setValue(this._data['acuerdo']['une'])

                        this.expedientForm.get('acue').disable();
                        this.expedientForm.get('expe').disable();
                        //this.expedientForm.get('part').disable();
                        let c = 0;
                        const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == response['data']['judg'] || element.nume == response['data']['judg'] || element.name == response['data']['judg']);
                        console.log("typeJudg", typeJudg);
                        this.expedientForm.get('proce').setValue(typeJudg.handle);
                        if (response['data']['us_auth_name'] != null) {
                            while (c < response['data']['us_auth_name']['length']) {

                                this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][c])
                                c++;
                            }
                        }
                        this.expedientForm.get('part').setValue(response['data']['us_act']);

                        let cc = 0;
                        if (response['data']['us_terc_name'] != null) {
                            while (cc < response['data']['us_terc_name']['length']) {

                                this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][cc])
                                cc++;
                            }

                        }
                        this.expedientForm.get('dema').setValue(response['data']['demandado']);
                        this.expedientForm.get('part').setValue(response['data']['actor']);
                        this.expedientForm.get('terc').setValue(response['data']['tercero']);
                    });
            }

        }

        if (this._data.typeNotification === 'emplazamiento') {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: ['', Validators.required],
                //   tr :  ['', Validators.required],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop]
            });
        } if (this.txtTitleModal == "Nueva notificación de acuerdo por estrados") {

            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: ['', Validators.required],
                //  tr :  ['', Validators.required],
                mater: ['', Validators.required],

                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop]


            });
        } if (this.txtTitleModal == 'Notificar a Sala') {
            return this._formBuilder.group({
              //une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                //part: ['', Validators.required],
                //dema: ['', Validators.required],
                //terc: [''],
                //acue: [une],
                //expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                //proce: [procesop],


            });
        } else if (this._data.typeNotification === 'emplazar-particular') {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: [''],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop],
                //   tr :[''],

            });
        } else {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: [''],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop],
                //   tr :[''],


            });
        }
    }
    createExpedientFormsenten(): FormGroup {
        let une = { value: '', disabled: false };

        let une2 = { value: '', disabled: false };

        let proc = { value: '', disabled: false };
        let procesop = { value: this.procesop, disabled: false }
        if (this.agreementData != null) {

            console.log(this._data);
            if (this._data['acuerdo'] != undefined) {
                console.log("de aqui saca");
                this._topicService.getOfficialExpedient2(this._data['acuerdo']['une'], 'expe',).subscribe(
                    (response) => {
                        console.log("response de getofficls", response);
                        console.log("response de getofficl2", this.expedientForm);

                        this.expedientForm.get('proce').setValue(response['data']['judg']);
                        this.expedientForm.get('acue').setValue(this._data['acuerdo']['expe']);
                        console.log("data",this._data);
                        this.expedientForm.get('expe').setValue(this._data['acuerdo']['expe_uni'])

                        this.expedientForm.get('acue').disable();
                        this.expedientForm.get('expe').disable();
                        //this.expedientForm.get('part').disable();
                        let c = 0;
                        const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == response['data']['judg'] || element.nume == response['data']['judg'] || element.name == response['data']['judg']);
                        console.log("typeJudg", typeJudg);
                        this.expedientForm.get('proce').setValue(typeJudg.handle);
                        if (response['data']['us_auth_name'] != null) {
                            while (c < response['data']['us_auth_name']['length']) {

                                this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][c])
                                c++;
                            }
                        }
                        this.expedientForm.get('part').setValue(response['data']['us_act']);

                        let cc = 0;
                        if (response['data']['us_terc_name'] != null) {
                            while (cc < response['data']['us_terc_name']['length']) {

                                this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][cc])
                                cc++;
                            }

                        }
                        this.expedientForm.get('dema').setValue(response['data']['demandado']);
                        this.expedientForm.get('part').setValue(response['data']['actor']);
                        this.expedientForm.get('terc').setValue(response['data']['tercero']);
                    });
            }

        }

        if (this._data.typeNotification === 'emplazamiento') {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: ['', Validators.required],
                //   tr :  ['', Validators.required],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop]
            });
        } if (this.txtTitleModal == "Nueva notificación de acuerdo por estrados") {

            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: ['', Validators.required],
                //  tr :  ['', Validators.required],
                mater: ['', Validators.required],

                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop]


            });
        } if (this.txtTitleModal == 'Notificar a Sala') {
            return this._formBuilder.group({
               // une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
               // part: ['', Validators.required],
                //dema: ['', Validators.required],
                terc: [''],
                //acue: [une],
                //expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop],


            });
        } else if (this._data.typeNotification === 'emplazar-particular') {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: [''],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop],
                //   tr :[''],

            });
        } else {
            return this._formBuilder.group({
                une: [une, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                depa: ['', Validators.required],
                part: ['', Validators.required],
                dema: ['', Validators.required],
                terc: [''],
                acue: [une],
                expe: [une2, [Validators.required, Validators.pattern('([0-9]{4,4}[-][0-9]{1})|([0-9]{1}[/][0-9]{4,4})')]],
                proce: [procesop],
                //   tr :[''],


            });
        }
    }

    createUsersForm(): FormGroup {

        return this._formBuilder.group({
            disp: [''],
            ciud: [[]],
            auth: [[]],
            usua: [[]],
            acueusa: [[]]
        });
    }

    /**
     * Toggle extra to fields
     */
    toggleExtraToFields(): void {
        this.showExtraToFields = !this.showExtraToFields;
    }
    getTypeAgree(data) {
        const TypeJudgSentenceItem = TypeJudgmentNulidad2.find(item => item.nume == data);
        if (TypeJudgSentenceItem) {
            return TypeJudgSentenceItem.name;
        } else {
            return "N/A";
        }
        return;
    }
    getOnlyDate(date) {
        moment.locale('es-Mx');
        return moment(date).format('DD-MM-YYYY');
    }
    _getAllDepartments() {
        this._resourcesService.getDepartments().subscribe(
            (result) => {
                this.departments2=result['data'];
                this.departments = result['data'].filter(element => element.nume === this._settings.getDepaEmployee())
                if (this.isAcuerdoNotification || this.isFisicNotificationAcue) {
                    this.expedientForm.patchValue({ depa: this.employeeDepa.toString() })
                } else {
                    this.expedientForm.patchValue({ depa: this.employeeDepa.toString() })
                }
            },
            (error) => console.log('error get departaments', error)
        );
    }
    getExpedientex(e) {

        e = this.expedientForm.get('une').value;
        console.log("datos para buscar ciudadano", e);
        this._getUsers(e);
        console.log(this.expedientForm.get('une').value);
        this._topicService.getOfficialExpedient2(e, 'expe',).subscribe(
            (response) => {
                console.log("response de getofficl", response);
                console.log("response de getofficl2", this.expedientForm);
                const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == response['data']['judg'] || element.nume == response['data']['judg'] || element.name == response['data']['judg']);
                console.log("typeJudg", typeJudg);
                this.expedientForm.get('proce').setValue(typeJudg.handle);
                this.expedientForm.get('part').setValue(response['data']['actor']);
                this.expedientForm.get('expe').setValue(response['data']['expe_a']);
                this.expedientForm.get('une').setValue(response['data']['expe']);
                                this._getUsersAgreement(response['data']['agid']);
                                this.idag=response['data']['agid'];

                this.expedientForm.get('acue').disable();
                this.expedientForm.get('dema').setValue(response['data']['demandado']);
                this.expedientForm.get('part').setValue(response['data']['actor']);
                this.expedientForm.get('terc').setValue(response['data']['tercero']);
                //this.expedientForm.get('expe').disable();
                //this.expedientForm.get('une').disable();

                //this.expedientForm.get('part').disable();


                // let c = 0;
                // if (response['data']['us_auth_name'] != null) {

                //     while (c < response['data']['us_auth_name']['length']) {

                //         this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][c])
                //         c++;
                //     }
                // }


                let cc = 0;
                if (response['data']['us_terc_name'] != null) {

                    while (cc < response['data']['us_terc_name']['length']) {

                        this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][cc])
                        cc++;
                    }
                }
                this.expedientForm.get('dema').setValue(response['data']['demandado']);
                this.expedientForm.get('part').setValue(response['data']['actor']);
                this.expedientForm.get('terc').setValue(response['data']['tercero']);
            });
    }
    getExpedient(e) {

        e = this.expedientForm.get('expe').value;
        this._getUsers(e);
        console.log(this.expedientForm.get('expe').value);
        this._topicService.getOfficialExpedient3(e, 'une',).subscribe(
            (response) => {
                console.log("response de getofficl", response);
                console.log("response de getofficl2", this.expedientForm);
                const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == response['data']['judg'] || element.nume == response['data']['judg'] || element.name == response['data']['judg']);
                console.log("typeJudg", typeJudg);
                console.log("data", response);
                this.expedientForm.get('proce').setValue(typeJudg.handle);
                this.expedientForm.get('part').setValue(response['data']['us_act']);
                this.expedientForm.get('une').setValue(response['data']['expe']);
                this.expedientForm.get('expe').setValue(response['data']['expe_a']); this.expedientForm.get('acue').disable();

                                this._getUsersAgreement(response['data']['agid']);
                                this.idag=response['data']['agid'];
                // this.expedientForm.get('expe').disable();
                //this.expedientForm.get('une').disable();

                //this.expedientForm.get('part').disable();
                let c = 0;
                while (c < response['data']['us_auth_name']['length']) {

                    this.expedientForm.get('dema').setValue(response['data']['us_auth_name'][c])
                    c++;
                }
                let cc = 0;
                while (cc < response['data']['us_terc_name']['length']) {

                    this.expedientForm.get('terc').setValue(response['data']['us_terc_name'][cc])
                    cc++;
                }
                this.expedientForm.get('dema').setValue(response['data']['demandado']);
                this.expedientForm.get('part').setValue(response['data']['actor']);
                this.expedientForm.get('terc').setValue(response['data']['tercero']);

            });

    }
    _getUsers(e) {
        if (!this.isAcuerdoNotification && this.expedientForm.value.une && this.expedientForm.value.depa) {
            const data = {
                folio: this.expedientForm.value.une,
                depa: this.expedientForm.value.depa
            }
            this.users = [];
            this.autorities = [];
            console.log("data para busqueda de ciudadanos y autori", data);
            this._usersService.getUserByExpediente(data).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        if (user.ciud !== 0) {
                            this.users.push({ ...user.ciuddata.data.usuadata.data, type: user.tipo });
                        } else if (user.auto !== 0) {
                            this.autorities.push({ ...user.autodata.data.usuadata.data, type: user.tipo });
                        }
                        this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );
        }
    }

    _getUsersAgreement(id_agreement) {
        if (this.bansente == true) {
            console.log("aqui busca por sentencia", id_agreement);

            this.users = [];
            this.autorities = [];
            this._usersService.getUserBysentence(id_agreement).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        this.users.push({ ...user.usuadata.data, type: user.tipo });

                        // this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );

            this._usersService.getAutoritiesByAgreements(id_agreement).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        this.autorities.push({ ...user.usuadata.data, type: user.tipo });
                        // this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );
        } else {
            //const id_agreement = idthis.expedientForm.value.acue;
            this.users = [];
            this.autorities = [];
            this._usersService.getUserByAgreements(id_agreement).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        this.users.push({ ...user.usuadata.data, type: user.tipo });

                        // this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );

            this._usersService.getAutoritiesByAgreements(id_agreement).subscribe(
                (result) => {
                    result['data'].forEach(user => {
                        this.autorities.push({ ...user.usuadata.data, type: user.tipo });
                        // this.fillParts(user);
                    });
                },
                (error) => console.log('error get users', error)
            );
        }
    }

    fillParts(reg) {
        this.expedientForm.patchValue({
            part: reg.part,
            dema: reg.dema,
            terc: reg.terc
        });
    }

    setFile(inputFileName) {
        const reader = new FileReader();
        const fileUpload = this[`${inputFileName}`].nativeElement;
        fileUpload.onchange = () => {
            if (fileUpload.files && fileUpload.files.length) {
                const [file1] = fileUpload.files;
                if (!this.validTypeSizeFile(file1, inputFileName)) {
                    return
                }
                reader.readAsDataURL(file1);
                reader.onload = () => {
                    this.resolutionForm.patchValue({
                        [`${inputFileName}`]: file1
                    });
                };

                this.cd.markForCheck();
            }
        };
        fileUpload.click();
    }

    removeFile(inputFileName) {
        this.resolutionForm.patchValue({
            [`${inputFileName}`]: ''
        });
    }

    previewFile(inputFileName) {
        const type = 0; //Generar URL
        const fileUpload = this[`${inputFileName}`].nativeElement;
        const file1 = fileUpload.files[0];
        //console.log(file1);
        let pdfUrl = URL.createObjectURL(file1);

        this.composeDialog(file1, type);
    }
    enviar(e){
        if(!this.validInactiveSendBtn() || this.isLoading==true)
        {
            console.log("aqui se envia el acuerdo o sentencia",e);

            this.sendMail();

        
        }
    }
    previewFiler(indexFile, index) {
        //const fileUpload = document.querySelector(`#${ indexFile }`) as HTMLInputElement;
        const type = 0; //Generar URL
        const reader = new FileReader();
        console.log("indexFile", indexFile);
        let pdfUrl = URL.createObjectURL(indexFile);
        //console.log(pdfUrl);
        this.composeDialog(indexFile, type);
    }

    composeDialog(file, type): void {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponentPdf, {
            width: '1000px',
        height:'700px',
            panelClass: 'mail-compose-dialog',
            data: {
                file: file,
                type: type,
            }
        });
    }
    composeDialog2(typeNotification, acuerdo,): void {

        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog',
            data: {
                typeNotification: typeNotification,
                acuerdo: acuerdo
            }
        });
    }
    validTypeSizeFile(file, inputFileName) {
        if (inputFileName === 'adju') {
            this.txtErrorFileadju = this.txtErrorFile;
        }
        if (file.type !== 'application/pdf') {
            this[`txtErrorFile${inputFileName}`] = 'El archivo debe ser en formato PDF';
            return false;
        }
        if (file.size > 10000000) {
            this[`txtErrorFile${inputFileName}`] = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        return true;
    }

    setUser(user, event) {
        this.us = user;
        if (event.checked) {
            if (user.tipo === 'autoridad') {
                const toAdd = [...this.usersForm.value.auth, user.auto]
                this.usersForm.patchValue({ auth: toAdd })
            } else {
                const toAdd = [...this.usersForm.value.ciud, user.ciud]
                this.usersForm.patchValue({ ciud: toAdd })
            }
            const toAddU = [...this.usersForm.value.acueusa, user.nume]
            this.usersForm.patchValue({ acueusa: toAddU })
        } else {
            if (user.tipo === 'autoridad') {
                const withOutElement = this.usersForm.value.auth.filter(e => e !== user.auto)
                this.usersForm.patchValue({ auth: withOutElement })
            } else {
                const withOutElement = this.usersForm.value.ciud.filter(e => e !== user.ciud)
                this.usersForm.patchValue({ ciud: withOutElement })
            }
            const withOutElementU = this.usersForm.value.acueusa.filter(e => e !== user.nume)
            this.usersForm.patchValue({ acueusa: withOutElementU })

        }
    }
    setdoc2(result) {
        console.log(result);
        let tempFile;
        let promise = new Promise((resolve, reject) => {
            this._expedientServices.sendNotificationAgreement4(result).subscribe(
                (result2) => {
                    console.log("result", result2);
                    let a=0;
                    
                    while(a<result2[0]['length']){
                        tempFile = result2[0][a]['pivot']['razon'].split("/");
                        console.log("tempFile", tempFile);
                        this._documentService.getPublicFiles(tempFile[1], tempFile[2]).subscribe(
                            response => {
                                //let blob = new Blob([response], {type: 'application/pdf'} 
                                console.log("entra aqu");
                                this.fileLaravel = new File([response], "prueba.pdf", { type: 'application/pdf' });
                                SelectFileSign(this.fileLaravel).then((isValidFile) => {
                                    if (isValidFile) {
                                        this.extArchivoCargado = extFileUpload;
                                        this.validaf2(result);
                                    }
                                })
    
                            }, error => {
                            }
                        ); 
a++;
                    }
                     a=0;
                    while(a<result2[1]['length']){
                        tempFile = result2[1][a]['pivot']['razon'].split("/");
                        console.log("tempFile", tempFile);
                        this._documentService.getPublicFiles(tempFile[1], tempFile[2]).subscribe(
                            response => {
                                //let blob = new Blob([response], {type: 'application/pdf'} 
                                console.log("entra aqu");
                                this.fileLaravel = new File([response], "prueba.pdf", { type: 'application/pdf' });
                                SelectFileSign(this.fileLaravel).then((isValidFile) => {
                                    if (isValidFile) {
                                        this.extArchivoCargado = extFileUpload;
                                        this.validaf2(result);
                                    }
                                })
    
                            }, error => {
                            }
                        ); 
a++;
                    }
                    
                },
                (error) => {
                    this._requestError(error)
                }
            );


        })
    }
    setdoc(result) {

        console.log(result);
        let tempFile;
        let promise = new Promise((resolve, reject) => {
            this._expedientServices.sendNotificationAgreement3(result['data'].nume).subscribe(
                (result2) => {
                    console.log("result", result2);

                    if (result2['razonemplazar'] == null) {
                        this.setdoc2(result['data'].nume);
                    }else{
                        console.log("result", result2);
                        tempFile = result2['razonemplazar'].split("/");
                        console.log("tempFile", tempFile);
                        this._documentService.getPublicFiles(tempFile[0], tempFile[1]).subscribe(
                            response => {
                                //let blob = new Blob([response], {type: 'application/pdf'} )
                                this.fileLaravel = new File([response], "prueba.pdf", { type: 'application/pdf' });
                                SelectFileSign(this.fileLaravel).then((isValidFile) => {
                                    if (isValidFile) {
                                        this.extArchivoCargado = extFileUpload;
                                        this.validaf(result);
                                    }
                                })
    
                            }, error => {
                                
                            }
                        );
                    }
                   
                },
                (error) => {
                    this._requestError(error)
                }
            );


        })

    }

    validaf(response) {

        OpenPfx(this.filePFXselct, this.firmaForm.get('password').value).then((isCreatePKCS7) => {
            if (isCreatePKCS7) {
                this.pkcs7 = PKCS7creado;
                var fecha = new Date();
                let textToWrite = this.pkcs7;
                let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
                let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "." + this.extArchivoCargado;
                let blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
                console.log("blobAsFile", blobAsFile);
                let a = [
                    blobAsFile,
                    response
                ]
                const notificationForm = new FormData();
                notificationForm.append('docu', blobAsFile);
                notificationForm.append("respo", response['data'].nume);
                this._expedientServices.sendNotificationAgreement2(notificationForm).subscribe(
                    (result) => {
                        this._requestSuccess(result);
                    },
                    (error) => {
                        this._requestError(error)
                    }
                );
            }
        }, (error) => {
            console.log("error de firma", error);
            this.isLoading = false;
            this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                duration: 3000
            });
        });
    }
    validaf2(response) {

        OpenPfx(this.filePFXselct, this.firmaForm.get('password').value).then((isCreatePKCS7) => {
            if (isCreatePKCS7) {
                this.pkcs7 = PKCS7creado;
                var fecha = new Date();
                let textToWrite = this.pkcs7;
                let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
                let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "." + this.extArchivoCargado;
                let blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
                console.log("blobAsFile", blobAsFile);
                let a = [
                    blobAsFile,
                    response
                ]
                const notificationForm = new FormData();
                                notificationForm.append('docu', blobAsFile);
                notificationForm.append("respo", response);
                this._expedientServices.notificationsb(notificationForm).subscribe(
                    (result) => {
                        this._requestSuccess(result);
                    },
                    (error) => {
                        this._requestError(error)
                    }
                );
            }
        }, (error) => {
            console.log("error de firma", error);
            this.isLoading = false;
            this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                duration: 3000
            });
        });
    }
    sendMail() {
        this.isLoading = true;
        if (this._data.typeNotification != 'acue-fisi' && this._data.typeNotification != 'acue-fisis2') {
            OpenPfx(this.filePFXselct, this.firmaForm.get('password').value).then((isCreatePKCS7) => {
                if (isCreatePKCS7) {
                    this.pkcs7 = PKCS7creado;
                    var fecha = new Date();
                    let textToWrite = this.pkcs7;
                    let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
                    let fileNameToSaveAs = "pkcs7_" + fecha.valueOf() + "." + this.extArchivoCargado;
                    let blobAsFile = new File([textFileAsBlob], fileNameToSaveAs);
                    console.log("blobAsFile", blobAsFile);
                    if (this.bansente == true) {
                        this.isLoading = true;
                        this.txtErrorNoti = '';
                        console.log("notisala por sentencia");

                        if (this.txtTitleModal == 'Notificar a Sala') {
                            const notificationForm = this._getFormDatans();
                            notificationForm.append('docu', blobAsFile);

                            console.log("vaaa: ", notificationForm);

                            // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                            this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                                (result) => {
                                    this.setdoc(result);

                                    this._requestSuccess(result);
                                },
                                (error) => {
                                    this._requestError(error)
                                }
                            );
                        } else {
                            if (this.isAcuerdoNotification || this.isFisicNotificationAcue) { //notificaciones de acuerdos, emplazar autoridad
                                console.log("notisala no", this.agreementData);

                                const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
                                notificationForm.append('docu', blobAsFile);

                                console.log("emplazamiento console: ", notificationForm);
                                notificationForm.forEach((x, i) => console.log(i, '->', x))
                                this._expedientServices.sendNotificationSentence(notificationForm, this.agreementData.id).subscribe(
                                    (result) => {
                                        this.setdoc(result);

                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error)
                                    }
                                );
                            } else if (this.isNotificationSala) { //notificacion a sala, remision
                                const notificationForm = this._getFormDataSala();
                                notificationForm.append('docu', blobAsFile);

                                console.log("notificacion");
                                // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                                // notificationForm.forEach((x, i) => console.log(i ,'->', x));
                                console.log(this.agreementData);
                                this._expedientServices.sendNotificationSala(notificationForm, this.agreementData.nume).subscribe(
                                    (result) => {
                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error);
                                    }
                                );
                            } else { //notificacion normal o fisica
                                console.log("notificacion 4");

                                const notificationForm = this._getFormDataAgreement();
                                notificationForm.append('docu', blobAsFile);

                                //notificationForm.forEach((x, i) => console.log(i ,'->', x))
                                this._expedientServices.sendNotification(notificationForm).subscribe(
                                    (result) => {
                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error);
                                    }
                                );
                            }
                        }
                    } else {
                        this.isLoading = true;
                        this.txtErrorNoti = '';
                        if (this.txtTitleModal == 'Notificar a Sala') {
                            console.log("notisala");
                            const notificationForm = this._getFormDatans();
                            console.log("vaaa: ", notificationForm);
                            notificationForm.append('dcanEncode', blobAsFile);
                            // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                            this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                                (result) => {
                                    console.log("resultado de la peticion es =", result);
                                    this.setdoc(result);
                                    this._requestSuccess(result);
                                },
                                (error) => {
                                    this._requestError(error)
                                }
                            );
                        } else {
                            if (this.isAcuerdoNotification || this.isFisicNotificationAcue) { //notificaciones de acuerdos, emplazar autoridad
                                console.log("notisala no");

                                const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
                                notificationForm.append('docu', blobAsFile);

                                console.log("emplazamiento console: ", notificationForm);
                                notificationForm.append('dcanEncode', blobAsFile);

                                notificationForm.forEach((x, i) => console.log(i, '->', x))
                                this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                                    (result) => {
                                        console.log("resultado de la peticion es =", result);
                                        this.setdoc(result);
                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error)
                                    }
                                );
                            } else if (this.isNotificationSala) { //notificacion a sala, remision
                                const notificationForm = this._getFormDataSala();
                                notificationForm.append('docu', blobAsFile);

                                console.log("notificacion");
                                // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                                // notificationForm.forEach((x, i) => console.log(i ,'->', x));
                                console.log(this.agreementData);
                                notificationForm.append('dcanEncode', blobAsFile);

                                this._expedientServices.sendNotificationSala(notificationForm, this.agreementData.nume).subscribe(
                                    (result) => {
                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error);
                                    }
                                );
                            } else { //notificacion normal o fisica
                                console.log("notificacion 3");
                                console.log(this.idag);
                                let id;
if(this.resolutionForm.get('tipo').value=='sentencia'){
    this._topicService.getOfficialsentencia(this.expedientForm.get('une').value, 'une',).subscribe(
        (response) => {
            console.log("response de getofficl", response);
         id=response;
         const notificationForm = this._getFormDataAgreement2();
                                notificationForm.append('docu', blobAsFile);

                                //notificationForm.forEach((x, i) => console.log(i ,'->', x))
                                notificationForm.append('dcanEncode', blobAsFile);

                                this._expedientServices.sendNotificationSentence(notificationForm,id).subscribe(
                                    (result) => {
                                        this.setdoc(result);

                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error)
                                    }
                                );
        });}else{
            const notificationForm = this._getFormDataAgreement2();
                                notificationForm.append('docu', blobAsFile);

                                //notificationForm.forEach((x, i) => console.log(i ,'->', x))
                                notificationForm.append('dcanEncode', blobAsFile);

                                this._expedientServices.sendNotificationAgreement(notificationForm, this.idag).subscribe(
                                    (result) => {
                                        this.setdoc(result);

                                        this._requestSuccess(result);
                                    },
                                    (error) => {
                                        this._requestError(error)
                                    }
                                );
        }
                                
                            }
                        }

                    }


                }
            }, (error) => {
                console.log("error de firma", error);
                this.isLoading = false;
                this._matSnackBar.open('La contraseña no coincide con la firma electronica', 'OK', {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center',
                    duration: 3000
                });
            });
        } else {
            if (this.bansente == true) {
                this.isLoading = true;
                this.txtErrorNoti = '';
                console.log("notisala por sentencia");

                if (this.txtTitleModal == 'Notificar a Sala') {
                    const notificationForm = this._getFormDatans();
                    console.log("vaaa: ", notificationForm);
                    // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                    this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                        (result) => {
                            this._requestSuccess(result);
                        },
                        (error) => {
                            this._requestError(error)
                        }
                    );
                } else {
                    if (this.isAcuerdoNotification || this.isFisicNotificationAcue) { //notificaciones de acuerdos, emplazar autoridad
                        console.log("notisala no", this.agreementData);

                        const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
                        console.log("emplazamiento console: ", notificationForm);
                        notificationForm.forEach((x, i) => console.log(i, '->', x))
                        this._expedientServices.sendNotificationSentence(notificationForm, this.agreementData.id).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error)
                            }
                        );
                    } else if (this.isNotificationSala) { //notificacion a sala, remision
                        const notificationForm = this._getFormDataSala();
                        console.log("notificacion");
                        // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                        // notificationForm.forEach((x, i) => console.log(i ,'->', x));
                        console.log(this.agreementData);
                        this._expedientServices.sendNotificationSala(notificationForm, this.agreementData.nume).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error);
                            }
                        );
                    } else { //notificacion normal o fisica
                        console.log("notificacion 2");

                        const notificationForm = this._getFormDataAgreement();
                        //notificationForm.forEach((x, i) => console.log(i ,'->', x))
                        this._expedientServices.sendNotification(notificationForm).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error);
                            }
                        );
                    }
                }
            } else {
                this.isLoading = true;
                this.txtErrorNoti = '';
                if (this.txtTitleModal == 'Notificar a Sala') {
                    console.log("notisala");
                    const notificationForm = this._getFormDatans();
                    console.log("vaaa: ", notificationForm);
                    // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                    this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                        (result) => {
                            this._requestSuccess(result);
                        },
                        (error) => {
                            this._requestError(error)
                        }
                    );
                } else {
                    if (this.isAcuerdoNotification || this.isFisicNotificationAcue) { //notificaciones de acuerdos, emplazar autoridad
                        console.log("notisala no");

                        const notificationForm = this.isFisicNotificationAcue ? this._getFormData() : this._getFormDataAgreement();
                        console.log("emplazamiento console: ", notificationForm);

                        notificationForm.forEach((x, i) => console.log(i, '->', x))
                        this._expedientServices.sendNotificationAgreement(notificationForm, this.agreementData.nume).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error)
                            }
                        );
                    } else if (this.isNotificationSala) { //notificacion a sala, remision
                        const notificationForm = this._getFormDataSala();
                        console.log("notificacion");
                        // notificationForm.forEach((x, i) => console.log(i ,'->', x))
                        // notificationForm.forEach((x, i) => console.log(i ,'->', x));
                        console.log(this.agreementData);

                        this._expedientServices.sendNotificationSala(notificationForm, this.agreementData.nume).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error);
                            }
                        );
                    } else { //notificacion normal o fisica
                        console.log("notificacion 1");
                        const notificationForm = this._getFormDataAgreement();
                        //notificationForm.forEach((x, i) => console.log(i ,'->', x))

                        this._expedientServices.sendNotification(notificationForm).subscribe(
                            (result) => {
                                this._requestSuccess(result);
                            },
                            (error) => {
                                this._requestError(error);
                            }
                        );
                    }
                }

            }
        }

    

    }

    _requestSuccess(result) {
        
        this._matSnackBar.open('Notificacón realizada', '✔', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration        : 3000
        });


        this.txtErrorFileadju = this.txtErrorFile;
        this.isLoading = false;
        this.matDialogRef.close(['send', 'Revisa tu historial de notificaciones para consultar las constancias'])
    }

    _requestError(error) {

        this.isLoading = false;
        this.txtErrorNoti = this._settings._getMsjError(error);
    }

    setInfoAgreement() {
        if (this.agreementData != null) {
            const expe = this.agreementData.expe !== '' ? this.agreementData.expe : this.agreementData.une;
            this.expedientForm.patchValue({
                une: expe,
                acue: expe,
            });
            if (!this.isFisicNotificationAcue) {
                this.adjun.setValidators(null);
                this.adjun.updateValueAndValidity();
            }
            if (this.bansente == true) {
                console.log("this.agreementData", this.agreementData);
                this.resolutionForm.patchValue({
                    tipo: 'sentencia',
                    fecd: this.agreementData.crtd,
                    adju: this.agreementData.doctestlink,
                });
            } else {
                this.resolutionForm.patchValue({
                    tipo: 'acuerdo',
                    fecd: this.agreementData.date,
                    adju: this.agreementData.docu,
                });
            }

        }




    }

    _getFormData() {
        const notificationForm = new FormData();
        console.log("datos par aenviar del expediente: ", this.expedientForm);
        console.log("datos par aenviar del resolutionForm: ", this.resolutionForm);
        notificationForm.append('tr', this.expedientForm.value.tr);

        notificationForm.append('depa', this.expedientForm.value.depa);
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
        notificationForm.append('adju', this.resolutionForm.value.adju) //=> Documento emitido,
        notificationForm.append('empl', this.currentUser.empe) //=> 'employee_id' Obtener id de empleado,
        notificationForm.append(`fisi`, (this.isFisicNotification || this.isFisicNotificationAcue) ? '1' : '0');
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('fecd', this.resolutionForm.value.fecd) //=> fecha en que fue dictado,
        notificationForm.append('tppa', this.resolutionForm.value.tppa) //=> tipo actor only is especializada,
        notificationForm.append('inte', this.resolutionForm.value.inte) //=> numero interno only is especializada,}


        let une = this.expedientForm.value.une;
        let kien = 'ambos';
        if (this.isFisicNotificationAcue) {
            kien = 'ninguno';
            une = this.agreementData.expe;
        }
        notificationForm.append('une', une) //=> 'folio',
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if (item.file !== undefined && item.desc !== '') {
                notificationForm.append(`dcan[${index}]`, item.file);
                notificationForm.append(`dsdc[${index}]`, item.desc);
                notificationForm.append(`tpdc[${index}]`, 'file');
                notificationForm.append(`dcpa[${index}]`, item.numPages);
            }
        });
        let c=0;

        if (this.arregloselec.length != 0) {
            console.log("this.arregloselec", this.arregloselec);
            for (let a = 0; a < this.arregloselec.length; a++) {
                for (let b = 0; b < this.arregloselec[a].length; b++) {
                    notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                    notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                    notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                    c++;

                }

            }
        }
        if(this._data.votos!=null){

        if(this._data.votos.length!=0){
            let docu;
            console.log("this.arregloselec", this._data);
            
                        for (let a = 0; a < this._data.votos.length; a++) {
                           
                                notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                c++;
            
                            
            
                        }
        }
    }
        if (this.isFisicNotification || this.isFisicNotificationAcue) {
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
            notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            let timeStr = this.resolutionForm.value.time.split(' ')[0],
                time = moment(timeStr, 'hh:mm');

            const date = this.resolutionForm.value.fecn.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });

            notificationForm.append('feno', date.format("YYYY-MM-DD HH:mm:ss"));
        } else {
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;

            if (ciudadanos.length > 0) {
                kien = 'ciudadano'
                for (const key in ciudadanos) {
                    if (ciudadanos.hasOwnProperty(key)) {
                        notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                    }
                }
            }
            if (autoridades.length > 0) {
                kien = 'autoridad'
                for (const key in autoridades) {
                    if (autoridades.hasOwnProperty(key)) {
                        notificationForm.append(`auth[${key}]`, autoridades[key])
                    }
                }
            }
            if (ciudadanos.length > 0 && autoridades.length > 0) {
                kien = 'ambos'
            }
        }
        console.log("datos par aenviar del placeForm: ");

        //ESTRADOS
        let como = 'electronica';
        if (this.isFisicNotificationAcue) {
            como = 'fisica';
        }
        if (this._data.typeNotification === 'fisica') {
            console.log("datos par aenviar del placeForm: ", this.placeForm);
            notificationForm.append('mail', this.placeForm.value.mail);

        }
        notificationForm.append('como', como);
        notificationForm.append('kien', kien);

        return notificationForm;
    }
    _getFormDatans() {
        const notificationForm = new FormData();
        notificationForm.append('depa', this.placeForm.value.depa);
        notificationForm.append('tr', this.expedientForm.value.tr);
        notificationForm.append('fecd', this.resolutionForm.value.fecd) //=> fecha en que fue dictado,
        notificationForm.append('terc', this.expedientForm.value.terc);
        notificationForm.append('tipo', this.resolutionForm.value.tr) //=> elegir texto: acuerdo o resolución,
        notificationForm.append('adju', this.resolutionForm.value.adju) //=> Documento emitido,
        notificationForm.append('empl', this.currentUser.empe) //=> 'employee_id' Obtener id de empleado,
        notificationForm.append(`fisi`, (this.isFisicNotification || this.isFisicNotificationAcue) ? '1' : '0');
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('tppa', this.resolutionForm.value.tppa) //=> tipo actor only is especializada,
        notificationForm.append('inte', this.resolutionForm.value.inte) //=> numero interno only is especializada,}
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
        notificationForm.append(`rmsn`, this._data.typeNotification === 'remision' ? '1' : '0');

        notificationForm.append('mail', this.placeForm.value.mail);


        let une = this.expedientForm.value.une;
        let kien = 'ambos';
        if (this.isFisicNotificationAcue) {
            kien = 'ninguno';
            une = this.agreementData.expe;
        }
        notificationForm.append('une', une) //=> 'folio',
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if (item.file !== undefined && item.desc !== '') {
                notificationForm.append(`dcan[${index}]`, item.file);
                notificationForm.append(`dsdc[${index}]`, item.desc);
                notificationForm.append(`tpdc[${index}]`, 'file');
                notificationForm.append(`dcpa[${index}]`, item.numPages);
            }
        });
        let c=0;

        if (this.arregloselec.length != 0) {
            console.log("this.arregloselec", this.arregloselec);
            for (let a = 0; a < this.arregloselec.length; a++) {
                for (let b = 0; b < this.arregloselec[a].length; b++) {
                    notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                    notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                    notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                    c++;

                }

            }
        }
        if(this._data.votos!=null){

        if(this._data.votos.length!=0){
            let docu;
            console.log("this.arregloselec", this._data);
            
                        for (let a = 0; a < this._data.votos.length; a++) {
                           
                                notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                c++;
            
                            
            
                        }
        }
    }
        if (this.isFisicNotification || this.isFisicNotificationAcue) {
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
            notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            let timeStr = this.resolutionForm.value.time.split(' ')[0],
                time = moment(timeStr, 'hh:mm');

            const date = this.resolutionForm.value.fecn.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });

            notificationForm.append('feno', date.format("YYYY-MM-DD HH:mm:ss"));
        } else {
        }

        //ESTRADOS
        let como = 'sala';

        notificationForm.append('como', como);
        notificationForm.append('kien', kien);

        return notificationForm;
    }

    _getFormDataSala() {
        //console.log('form data sala');
        const notificationForm = new FormData();
        notificationForm.append('depa', this.transferForm.value.depa);
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append(`rmsn`, this._data.typeNotification === 'remision' ? '1' : '0');

        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if (item.file !== undefined && item.desc !== '') {
                notificationForm.append(`dcan[${index}]`, item.file);
                notificationForm.append(`dsdc[${index}]`, item.desc);
                notificationForm.append(`tpdc[${index}]`, 'file');
                notificationForm.append(`dcpa[${index}]`, item.numPages);
            }
        })
        let c=0;

        if (this.arregloselec.length != 0) {
            console.log("this.arregloselec", this.arregloselec);
            for (let a = 0; a < this.arregloselec.length; a++) {
                for (let b = 0; b < this.arregloselec[a].length; b++) {
                    notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                    notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                    notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                    c++;

                }

            }
        }
        if(this._data.votos!=null){

        if(this._data.votos.length!=0){
            let docu;
            console.log("this.arregloselec", this._data);
            
                        for (let a = 0; a < this._data.votos.length; a++) {
                           
                                notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                c++;
            
                            
            
                        }
        }
    }
        return notificationForm;
    }
    _convertFile(file){
        const aaaa = new File([file], "prueba.pdf", { type: 'application/pdf' });

        return aaaa;
      
    }
    _getFormDataAgreement() {
        //console.log('form data agreemement')
        console.log("aquiii");

        const notificationForm = new FormData();
        console.log("this._data.typeNotification", this.expedientForm.value.proce);

        if (this.txtTitleModal === 'Notificar a Sala') {
            console.log("simon");
            notificationForm.append('part', this.expedientForm.value.part);
            notificationForm.append('dema', this.expedientForm.value.dema);
            notificationForm.append('terc', this.expedientForm.value.terc);
            notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
            notificationForm.append('empl', this.currentUser.empe);
            notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
            notificationForm.append('tppa', this.resolutionForm.value.tppa) //=> tipo actor only is especializada,
            notificationForm.append('inte', this.resolutionForm.value.inte) //=> numero interno only is especializada,
            const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == this.expedientForm.value.proce || element.nume == this.expedientForm.value.proce || element.name == this.expedientForm.value.proce);

            notificationForm.append('tr', typeJudg.name);
            console.log("usuarios", this.us);
            notificationForm.append('mail', this.us['mail']);
            notificationForm.append('auth', this.us['nomb'] + " " + this.us['pate'] + ' ' + this.us['mate']);
            //set files in form data
            this.anexosArray.forEach((item, index) => {
                if (item.file !== undefined && item.desc !== '') {
                    notificationForm.append(`dcan[${index}]`, item.file);
                    notificationForm.append(`dsdc[${index}]`, item.desc);
                    notificationForm.append(`tpdc[${index}]`, 'file');
                    notificationForm.append(`dcpa[${index}]`, item.numPages);
                }
            })
            let c=0;

        if (this.arregloselec.length != 0) {
            console.log("this.arregloselec", this.arregloselec);
            for (let a = 0; a < this.arregloselec.length; a++) {
                for (let b = 0; b < this.arregloselec[a].length; b++) {
                    notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                    notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                    notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                    c++;

                }

            }
        }
        if(this._data.votos!=null){

        if(this._data.votos.length!=0){
            let docu;
            console.log("this.arregloselec", this._data);
            
                        for (let a = 0; a < this._data.votos.length; a++) {
                           
                                notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                c++;
            
                            
            
                        }
        }
    }
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;
            let kien = 'ninguno';
            if (this.isEmplazarAutoridad) {
                const autoridad = this.notIsRegisteredAuthority || this.dontHaveTitularsAuth ? 'auto' : 'autoId'
                console.log("emplazamiento12333");
                if (this._data.typeNotification === 'emplazamiento') {
                    notificationForm.append('cru', this.placeForm.value.cru);
                    notificationForm.append('tr', this.expedientForm.value.proce);
                }


                kien = 'autoridad'
            } else {
                if (ciudadanos.length > 0) {
                    kien = 'ciudadano'
                    for (const key in ciudadanos) {
                        if (ciudadanos.hasOwnProperty(key)) {
                            notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                        }
                    }
                }

                if (ciudadanos.length > 0 && autoridades.length > 0) {
                    kien = 'ambos'
                }
            }

            notificationForm.append('kien', kien)
            console.log("datos par aenviar del placeForm");
            //ESTRADOS
            let como = 'electronica';
            if (this._data.typeNotification === 'estrados') {
                como = 'estrados';
                notificationForm.append('publ', this.usersForm.value.disp);
                console.log("estrados");

                notificationForm.append('tr', this.expedientForm.value.tr);
                notificationForm.append('mater', this.expedientForm.value.mater);
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa");
                // notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            } else if (this.isEmplazarAutoridad) {
                como = 'emplazar';
            }
            notificationForm.append('como', como)

            return notificationForm;
        } else {

            notificationForm.append('part', this.expedientForm.value.part);
            notificationForm.append('dema', this.expedientForm.value.dema);
            notificationForm.append('terc', this.expedientForm.value.terc);
            notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
            notificationForm.append('empl', this.currentUser.empe);
            notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
            notificationForm.append('tppa', this.resolutionForm.value.tppa) //=> tipo actor only is especializada,
            notificationForm.append('inte', this.resolutionForm.value.inte) //=> numero interno only is especializada,
            console.log("datos par aenviar del expediente: ", this.expedientForm);
            console.log("datos par aenviar del resolutionForm: ", this.usersForm);
            //valida tipo de juicio
            const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == this.expedientForm.value.proce || element.nume == this.expedientForm.value.proce || element.name == this.expedientForm.value.proce);
//ezpecializada

            notificationForm.append('tr', typeJudg.name);
            notificationForm.append('tr2', typeJudg.name);

            console.log("usuarios", this.us);
            notificationForm.append('mail', this.us['mail']);
            notificationForm.append('auth', this.us['nomb'] + " " + this.us['pate'] + ' ' + this.us['mate']);

            //set files in form data
            this.anexosArray.forEach((item, index) => {
                if (item.file !== undefined && item.desc !== '') {
                    notificationForm.append(`dcan[${index}]`, item.file);
                    notificationForm.append(`dsdc[${index}]`, item.desc);
                    notificationForm.append(`tpdc[${index}]`, 'file');
                    notificationForm.append(`dcpa[${index}]`, item.numPages);
                }
            })
            let c=0;

            console.log("this.arregloselec", this.arregloselec);
            if (this.arregloselec.length != 0) {
                console.log("this.arregloselec", this.arregloselec);
                for (let a = 0; a < this.arregloselec.length; a++) {
                    for (let b = 0; b < this.arregloselec[a].length; b++) {
                        notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                        notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                        notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                        c++;
    
                    }
    
                }
            }
            if(this._data.votos!=null){

            if(this._data.votos.length!=0){
                let docu;
                console.log("this.arregloselec", this._data);
                
                            for (let a = 0; a < this._data.votos.length; a++) {
                               
                                    notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                    notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                    notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                    c++;
                
                                
                
                            }
            }
        }
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;
            let kien = 'ninguno';
            if (this.isEmplazarAutoridad) {
                const autoridad = this.notIsRegisteredAuthority || this.dontHaveTitularsAuth ? 'auto' : 'autoId'
                notificationForm.append('auth', this.placeForm.value[autoridad]);
                notificationForm.append('titu', this.placeForm.value.titu);
                notificationForm.append('mail', this.placeForm.value.mail);
                notificationForm.append('tpau', this.placeForm.value.tpau);
                notificationForm.append('muni', this.placeForm.value.muni);

                if (this._data.typeNotification === 'emplazamiento') {
                    notificationForm.append('cru', this.placeForm.value.cru);
                    notificationForm.append('tr', this.expedientForm.value.tr);
                }

                kien = 'autoridad'
            } else if (this.isEmplazarParticular) {
                console.log("isEmpPart");
                const titular = this.placeForm.value.auto + " " + this.placeForm.value.apellPatPart + " " + this.placeForm.value.apellMatPart;
                const tpau = "particular";
                const autoridad = this.notIsRegisteredAuthority || this.dontHaveTitularsAuth ? 'auto' : 'autoId'
                notificationForm.append('auth', this.placeForm.value[autoridad]);
                notificationForm.append('titu', titular);
                notificationForm.append('auth', titular);
                notificationForm.append('mail', this.placeForm.value.mail);
                notificationForm.append('tpau', tpau);
                console.log(" this.usersForm", this.usersForm.value.acueusa);


                notificationForm.append('muni', '15');

                if (this._data.typeNotification === 'emplazar-particular') {

                }

                kien = 'autoridad'
            } else {
                if (ciudadanos.length > 0) {
                    kien = 'ciudadano'
                    for (const key in ciudadanos) {
                        if (ciudadanos.hasOwnProperty(key)) {
                            notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                        }
                    }
                }
                if (autoridades.length > 0) {
                    kien = 'autoridad'
                    for (const key in autoridades) {
                        if (autoridades.hasOwnProperty(key)) {
                            notificationForm.append(`auth[${key}]`, autoridades[key])
                        }
                    }
                }
                if (ciudadanos.length > 0 && autoridades.length > 0) {
                    kien = 'ambos'
                }
            }

            notificationForm.append('kien', kien)
            console.log("datos par aenviar del placeForm: ");

            //ESTRADOS
            let como = 'electronica';
            if (this._data.typeNotification === 'estrados') {
                como = 'estrados';
                notificationForm.append('publ', this.usersForm.value.disp);
                console.log("estrados");

                notificationForm.append('tr', this.expedientForm.value.tr);
                notificationForm.append('mater', this.expedientForm.value.mater);
                //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa");
                // notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            } else if (this.isEmplazarAutoridad || this.isEmplazarParticular) {
                como = 'emplazar';
            }
            notificationForm.append('como', como)
            if (this._data.typeNotification === 'emplazamiento') {
                notificationForm.append('cru', this.placeForm.value.cru);
                const typeJudg = TypeJudgmentNulidad2.find(element => element.handle == this.expedientForm.value.proce || element.nume == this.expedientForm.value.proce || element.name == this.expedientForm.value.proce);
                console.log("typeJudg", typeJudg);
                notificationForm.append('tr', typeJudg.name);
            }
            return notificationForm;
        }

    }
    _getFormDataAgreement2() {
        const notificationForm = new FormData();
        console.log("datos par aenviar del expediente: ", this.expedientForm);
        console.log("datos par aenviar del resolutionForm: ", this.resolutionForm);
        notificationForm.append('tr', this.expedientForm.value.tr);

        notificationForm.append('depa', this.expedientForm.value.depa);
        notificationForm.append('part', this.expedientForm.value.part);
        notificationForm.append('dema', this.expedientForm.value.dema);
        notificationForm.append('terc', this.expedientForm.value.terc);
        notificationForm.append('tipo', this.resolutionForm.value.tipo) //=> elegir texto: acuerdo o resolución,
        notificationForm.append('adju', this.resolutionForm.value.adju) //=> Documento emitido,
        notificationForm.append('empl', this.currentUser.empe) //=> 'employee_id' Obtener id de empleado,
        notificationForm.append(`fisi`, (this.isFisicNotification || this.isFisicNotificationAcue) ? '1' : '0');
        notificationForm.append(`file`, this.toUploadOk ? '1' : '0');
        notificationForm.append('fecd', this.resolutionForm.value.fecd) //=> fecha en que fue dictado,
        notificationForm.append('tppa', this.resolutionForm.value.tppa) //=> tipo actor only is especializada,
        notificationForm.append('inte', this.resolutionForm.value.inte) //=> numero interno only is especializada,}


        let une = this.expedientForm.value.une;
        let kien = 'ambos';
        if (this.isFisicNotificationAcue) {
            kien = 'ninguno';
            une = this.agreementData.expe;
        }
        notificationForm.append('une', une) //=> 'folio',
        //set files in form data
        this.anexosArray.forEach((item, index) => {
            if (item.file !== undefined && item.desc !== '') {
                notificationForm.append(`dcan[${index}]`, item.file);
                notificationForm.append(`dsdc[${index}]`, item.desc);
                notificationForm.append(`tpdc[${index}]`, 'file');
                notificationForm.append(`dcpa[${index}]`, item.numPages);
            }
        });
        let c=0;

        if (this.arregloselec.length != 0) {
            console.log("this.arregloselec", this.arregloselec);
            for (let a = 0; a < this.arregloselec.length; a++) {
                for (let b = 0; b < this.arregloselec[a].length; b++) {
                    notificationForm.append(`documentos[${c}]`, this.arregloselec[a][b].documento);
                    notificationForm.append(`documentosdesc[${c}]`, this.arregloselec[a][b].descripcion);
                    notificationForm.append(`documentosdcpa[${c}]`, this.arregloselec[a][b].paginas); 
                    c++;

                }

            }
        }
        if(this._data.votos!=null){

        if(this._data.votos.length!=0){
            let docu;
            console.log("this.arregloselec", this._data);
            
                        for (let a = 0; a < this._data.votos.length; a++) {
                           
                                notificationForm.append(`documentos[${c}]`, this._data.votos[a].documento);
                                notificationForm.append(`documentosdesc[${c}]`, 'Voto Particular');
                                notificationForm.append(`documentosdcpa[${c}]`, this._data.votos[a].paginas); 
                                c++;
            
                            
            
                        }
        }
    }
        if (this.isFisicNotification || this.isFisicNotificationAcue) {
            notificationForm.append(`doc`, this.usersForm.value.usua.join(','));
            notificationForm.append(`usua`, this.usersForm.value.acueusa.join(','));
            let timeStr = this.resolutionForm.value.time.split(' ')[0],
                time = moment(timeStr, 'hh:mm');

            const date = this.resolutionForm.value.fecn.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second')
            });

            notificationForm.append('feno', date.format("YYYY-MM-DD HH:mm:ss"));
        } else {
            const ciudadanos = this.usersForm.value.ciud;
            const autoridades = this.usersForm.value.auth;

            if (ciudadanos.length > 0) {
                kien = 'ciudadano'
                for (const key in ciudadanos) {
                    if (ciudadanos.hasOwnProperty(key)) {
                        notificationForm.append(`ciud[${key}]`, ciudadanos[key])
                    }
                }
            }
            if (autoridades.length > 0) {
                kien = 'autoridad'
                for (const key in autoridades) {
                    if (autoridades.hasOwnProperty(key)) {
                        notificationForm.append(`auth[${key}]`, autoridades[key])
                    }
                }
            }
            if (ciudadanos.length > 0 && autoridades.length > 0) {
                kien = 'ambos'
            }
        }
        console.log("datos par aenviar del placeForm: ");

        //ESTRADOS
        let como = 'electronica';
        if (this.isFisicNotificationAcue) {
            como = 'fisica';
        }
        if (this._data.typeNotification === 'fisica') {
            console.log("datos par aenviar del placeForm: ", this.placeForm);
            como = 'fisica';

        }
        notificationForm.append('como', como);
        notificationForm.append('kien', kien);

        return notificationForm;

    }

    /** Method Emplazar Autoridad */
    _getMunicipalities() {
        this._resourcesService.getMunicipalitiesRegister().subscribe(
            (result) => this.municipalities = result['data'],
            (error) => console.log('error get municipalities')
        );
    }

    _getNamesList() {
        console.log('debug muni', this.placeForm.value, this.municipalities);
        const muni = this.placeForm.value.muni,
            type = this.placeForm.value.tpau
        if (muni !== '' && type !== '') {
            this.autoritiesList = [];
            this._resourcesService.getNamesRegister(muni, type).subscribe(
                (result) => {
                    this.autoritiesList = result['data'];
                    this.nameList = result['data'].map(autority => autority.nomb)
                    this.placeForm.patchValue({ auto: '', mail: '', titu: '', autoId: '', cru: '' });
                    this.notIsRegisteredAuthority = false;
                },
                (error) => console.log('error get names')
            );
        }
    }

    _getEntPartList() {
        const type = "particular"

        this.autoritiesList = [];
        this._resourcesService.getEntitiesPart(type).subscribe(
            (result) => {
                this.autoritiesList = result['data'];
                this.nameList = result['data'].map(autority => autority.nomb)
                this.placeForm.patchValue({ auto: '', mail: '', titu: '', autoId: '', cru: '' });
                this.notIsRegisteredAuthority = false;
            },
            (error) => console.log('error get names')
        );

    }

    _getTitularsByAutoridad() {
        setTimeout(() => {
            console.log("Lista de autoridades",this.autoritiesList);
            const name_autoridad = this.placeForm.value.auto;
            const autoridad_selected = this.autoritiesList.find(auth => auth['nomb'] === name_autoridad);
            if (this.isEmplazarParticular) {
                console.log('autoridad_selected',autoridad_selected);
                this.municipalities = autoridad_selected;
            }
            console.log(autoridad_selected)
            this.titularsList = [];
            this.dontHaveTitularsAuth = false;
            this.placeForm.patchValue({ autoId: autoridad_selected['nume'] })
            this._resourcesService.getTitularAutoridad(autoridad_selected['nume']).subscribe(
                (result) => {
                    this.titularsList = this._getUserTitulars(result['data']);
                    this.dontHaveTitularsAuth = this.titularsList.length === 0;
                },
                (error) => console.log('error get names')
            );
        }, 5);
    }

    private _getUserTitulars(result_data) {
        const list = [];
        for (let user of result_data) {
            if (user.hasOwnProperty('usuadata')) {
                list.push(user.usuadata.data);
            }
        }
        return list;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const names = this.nameList;
        this.notIsRegisteredAuthority = names.length === 0;
        return names;
    }

    /** Methods Files */
    setFiler(inputFileName, index) {
        const reader = new FileReader();
        const fileUpload = document.querySelector(`#${inputFileName}`) as HTMLInputElement;
        fileUpload.onchange = () => {
            if (fileUpload.files && fileUpload.files.length) {
                const file = fileUpload.files[0];
                if (!this.validTypeSizeFiler(file, inputFileName)) {
                    return
                }
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.currentFile = file;
                    this.addFile(inputFileName, index);
                };
            }
        };
        fileUpload.click();
    }

    validTypeSizeFiler(file, inputFileName) {
        const input = this.anexosArray.find(anexo => anexo.name === inputFileName);
        input.error = true;
        input.txtError = '';
        if (file.type !== 'application/pdf') {
            input.txtError = 'El archivo debe ser en formato PDF';
            return false;
        }
        if (file.size > 10000000) {
            input.txtError = 'El archivo debe ser de un tamaño no mayor a 10mb';
            return false;
        }
        input.txtError = 'Debes ingresar una descripción de documento';
        return true;
    }

    addFile(inputFileName, index) {
        const typeFile = document.querySelector(`#${inputFileName}desc`) as HTMLInputElement;
        let file = this.anexosArray.find(file => file.name === inputFileName)
        file.file = this.currentFile,
            file.desc = typeFile.value,
            file.txtBtnUp = this.currentFile.name.substr(0, 15),
            file.size = this._bytesToSize(this.currentFile.size)
        this._getNumPages(this.currentFile, file);
        this.validFieldsUpload();
        this.resetInputFile(inputFileName)
    }

    addDescFile(inputFileName, index) {
        const typeFile = document.querySelector(`#${inputFileName}desc`) as HTMLInputElement;
        if (typeFile.value !== '') {
            this.anexosArray[index].desc = typeFile.value;
            this.anexosArray[index].upload = true;
        } else {
            this.resetInputFile(inputFileName);
            // this.removeFile(index);
            this.anexosArray[index].upload = false;
        }
        //this.validFieldsUpload();
    }

    setTypeFile(valueSelected, index) {
        this.anexosArray[index].type = valueSelected.value;
    }

    removeFiler(indexFile) {
        if (this.anexosArray.length > 1) {
            this.anexosArray.splice(indexFile, 1);
            this.validFieldsUpload();
        } else {
            this.anexosArray[indexFile] = new FileAnexo(`anexoFile${indexFile}`);
            this.showMoreFileBtn = false;
            this.resolutionForm.patchValue({ anexos: false });
        }
    }

    validFieldsUpload() {
        const anexosValid = this.anexosArray.filter(f => f.file && f.desc !== '');
        const anexosNotValid = this.anexosArray.filter(f => f.file && f.desc === '');
        if (anexosValid.length > 0 && anexosNotValid.length === 0) {
            this.resolutionForm.patchValue({ anexos: true });
            this.toUploadOk = true;
            anexosValid.forEach(anexo => { anexo.error = false })
            this.showMoreFileBtn = true;
        } else {
            this.toUploadOk = false;
            this.resolutionForm.patchValue({ anexos: false });
            anexosNotValid.forEach(anexo => { anexo.error = true })
        }
    }

    addOther() {
        this.showMoreFileBtn = false;
        const id = this.anexosArray.length;
        if (id < 5) {
            this.anexosArray.push(new FileAnexo(`anexoFile${id}`));
        }
    }

    resetInputFile(inputFileName) {
        const file = document.querySelector(`#${inputFileName}`) as HTMLInputElement;
        this.currentFile = undefined;
        file.value = '';
    }

    _bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    _getNumPages(file, fileObject) {
        //read num pages pdf
        let count = '';
        var read = new FileReader();
        read.readAsBinaryString(file);
        read.onload = function () {
            count = read.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length.toString();
            fileObject.numPages = count;
        }
    }
    /**END FILES */

    /**
 * Add user
 *
 * @param {MatChipInputEvent} event
 */
    addUser(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add user
        if (value) {
            this.usersForm.value.usua.push(value);
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    /**
     *  Filer Datepicker
     * @param date 
     */
    //datepicker filter
    myFilterDatePicker = (date: moment.Moment | null): boolean => {
        const day = date !== null ? date.day() : new Date().getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    };

    validAddMoreDays(date, tipo) {
        const day = date.day();
        let addDays = 0;
        if (tipo == 'min') {
            if (day === 5) {
                addDays = 3
            }
            if (day === 6) {
                addDays = 2
            }
            addDays = 1
        } else {
            if (day > 1 && day < 6) {
                addDays = 6
            }
            if (day === 6) {
                addDays = 5
            }
            addDays = 4
        }
        //[matDatepickerFilter]="myFilterDatePicker"
        return date.date() + addDays;
    }

    validUsersForm() {
        if (!this.isFisicNotificationEstrados && (this.usersForm.value.ciud.length === 0 && this.usersForm.value.auth.length === 0 && this.usersForm.value.usua.length === 0)) {
            return true;
        } else if (this.isFisicNotificationEstrados && this.fepu.invalid) {
            return true;
        }
        return false;
    }

    validInactiveSendBtn() {
        if (this._data.typeNotification != 'acue-fisi' && this._data.typeNotification != 'acue-fisis2') {
            if (!this.isNotificationSala && !this.isEmplazarAutoridad) {
                if (this.isEmplazarParticular) { return (this.resolutionForm.invalid || this.expedientForm.invalid || this.placeForm.invalid || this.firmaForm.invalid); }
                return (this.resolutionForm.invalid || this.expedientForm.invalid || this.validUsersForm() || this.firmaForm.invalid);
            } else if (this.isEmplazarAutoridad) {
                return (this.resolutionForm.invalid || this.expedientForm.invalid || this.placeForm.invalid || this.firmaForm.invalid);
            } else { // notificacion a sala o remision
                return (this.transferForm.invalid);
            }
        }
        if (!this.isNotificationSala && !this.isEmplazarAutoridad) {
            if (this.isEmplazarParticular) { return (this.resolutionForm.invalid || this.expedientForm.invalid || this.placeForm.invalid || this.firmaForm.invalid); }
            return (this.resolutionForm.invalid || this.expedientForm.invalid || this.validUsersForm());
        } else if (this.isEmplazarAutoridad) {
            return (this.resolutionForm.invalid || this.expedientForm.invalid || this.placeForm.invalid || this.firmaForm.invalid);
        } else { // notificacion a sala o remision
            return (this.transferForm.invalid);
        }
    }

    /**
     * Remove user
     *
     * @param user
     */
    removeUser(user): void {
        const index = this.usersForm.value.usua.indexOf(user);

        if (index >= 0) {
            this.usersForm.value.usua.splice(index, 1);
        }
    }

    get adjun() {
        return this.resolutionForm.get('adju') as FormControl;
    }

    get fepu() {
        return this.usersForm.get('disp') as FormControl;
    }

    public debugForm(){
    
        console.log("debug form firmaForm",this.findInvalidControlsRecursive( this.firmaForm));
        console.log("debug form resolutionForm",this.findInvalidControlsRecursive( this.resolutionForm));

    }

    public findInvalidControlsRecursive(formToInvestigate:FormGroup|FormArray):string[] {
        var invalidControls:string[] = [];
        let recursiveFunc = (form:FormGroup|FormArray) => {
          Object.keys(form.controls).forEach(field => { 
            const control = form.get(field);
            if (control.invalid) invalidControls.push(field);
            if (control instanceof FormGroup) {
              recursiveFunc(control);
            } else if (control instanceof FormArray) {
              recursiveFunc(control);
            }        
          });
        }
        recursiveFunc(formToInvestigate);
        return invalidControls;
      }
}

const ArrayFiles = [
    new FileAnexo('anexoFile0'),
];

function _compare_item(a, b) {
    // a should come before b in the sorted order
    if (a.name < b.name) {
        return -1;
        // a should come after b in the sorted order
    } else if (a.name > b.name) {
        return 1;
        // and and b are the same
    } else {
        return 0;
    }
}