<div id="login" fxLayout="column" fxLayout.gt-sm="row" fusePerfectScrollbar>
    <section class="titles">
        <!-- <img class="Logo_wed_Class" src="/assets/images/login/LOGOBIT.png" srcset="/assets/images/login/LOGOBIT.png 1x, assets/images/login/LOGOBIT.png 2x" style="width: 61%;"> -->
        <h1 class="Tribunal_Electrnico_para_la_Ju_Class">
            <span>Bienvenid@</span>
        </h1>
        <!-- <h3 class="Iniciamos_una_nueva_era_en_la__Class tw">
            <span>Iniciamos una nueva era en la impartición de Justicia Administrativa, una era de innovación tecnológica, con la implementación del Tribunal Electrónico para la Justicia Administrativa.</span>
        </h3> -->
        <!-- <h3 class="Iniciamos_una_nueva_era_en_la__Class">
            <span>Iniciamos una nueva era en la impartición de Justicia Administrativa, una era de innovación tecnológica, con la implementación del Tribunal Electrónico para la Justicia Administrativa.</span>
        </h3> -->

        <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class" fxLayout="column">
            <a class="link autoridad"  title="INICIAR SESIÓN" (click)="toSignup()" style="border-radius: 17px;padding: 0 30px 0 30px; font-size: 20px;">Inicia sesión</a>
        </h2>
        <h2 title="CREAR UNA CUENTA" style="font-weight: 900;color: aliceblue;font-size: 12px;">CREAR UNA CUENTA</h2>
        <div fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="button-padding">
                <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                    <a class="link autoridad"  [routerLink]="'/pages/auth/register'" style="border-radius: 17px;padding: 5px 30px 5px 30px; font-size: 20px;">Soy ciudadano</a>
                </h2>
                <!-- <div class="register" class="mb-12" fxLayout="column" fxLayoutAlign="center center">
                    <span class="text" style="font-weight: 900;color: aliceblue;">Ver tutorial
                    <a class="link text-capitalized"  (click)="abrirmodal2(tutorialCitizen.url)" target="_blank" style="color: #0c92f2;">{{ tutorialCitizen.name}}</a>
                    </span>
                </div> -->
            </div>

            <div>
                <h2 class="Inicia_Sesin_o_crea_una_cuenta_Class">
                    <a class="link autoridad"  [routerLink]="'/pages/auth/register/authority'" style="border-radius: 17px; padding: 5px 30px 5px 30px; font-size: 20px;">Soy autoridad</a>
                </h2>
        
                <!-- <div class="register" class="mb-12" fxLayout="column" fxLayoutAlign="center center">
                    <span class="text" style="font-weight: 900;color: aliceblue;">Ver tutorial
                    <a class="link text-capitalized" (click)="abrirmodal(tutorialAuthority.url)" target="_blank" style="color: #0c92f2;">{{ tutorialAuthority.name}}</a>
                    </span>
                </div> -->
            </div>
        </div>

    </section>

    <section class="diagram">
        <div class="row" fxFlex fxHide fxShow.gt-xs>
            <!--<img src="/assets/images/login/horizontal.png" srcset="/assets/images/login/horizontal.png">-->
        </div>
        <div class="row" fxHide.gt-xs>
            <section>
                <!--<img src="/assets/images/login/vertical.png" srcset="/assets/images/login/vertical.png">-->
            </section>
        </div>
    </section>
</div>
