import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/providers/data/app-config.service';

@Injectable({
  providedIn: 'root'
})

export class SentenceFormService {
  private urlSentence: string;
  private urlEmployee: string;
  
  constructor(private http: HttpClient, private settings: AppConfigService) { 
    this.urlSentence = this.settings.getUrlApi() + 'sentences';
    this.urlEmployee = this.settings.getUrlApi() + 'employees';
  }

  registerSentence(data){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.urlSentence, data, optionHeaders);
  }

  getEmployee(){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.get(this.urlEmployee, optionHeaders);
  }

  updateSentence(data, id_sentence, id_user){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.urlEmployee+'/'+id_user+'/sentences/'+id_sentence, data, optionHeaders);
  }

  createSentenceMag(data){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.settings.getUrlApi() + 'sentence-mag', data, optionHeaders);
  }

  createSentenceSecre(data){
    
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.settings.getUrlApi() + 'sentence-secre', data, optionHeaders);
  }

  revSentence(data, id_sentence, id_user){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.urlEmployee+'/'+id_user+'/sentences/'+id_sentence+'/review', data, optionHeaders);
  }

  turnarSecretario(nume){
    let optionHeaders = this.settings.getHeadersMultipart();
    const data = new FormData();
    return this.http.put(this.urlSentence+'/'+nume+'/turnar-secre', data,optionHeaders);
  }

  signSentence(data, id_sentence, id_user){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.urlEmployee+'/'+id_user+'/sentences/'+id_sentence+'/sign',data, optionHeaders);
  }

  asignSentence(data, id){
    const optionHeaders = this.settings.getHeadersJson();
    return this.http.put(this.urlSentence+'/'+id,data, optionHeaders);
  }

  getSentence(id){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.get(this.urlSentence+'/'+id, optionHeaders);
  }

  updateSentenceByProyectista(data, empe, nume){
    let optionHeaders = this.settings.getHeadersMultipart();
    return this.http.post(this.urlSentence+'/'+empe+'/'+nume+'/update', data, optionHeaders);
  }
}
