import { Orientation } from 'ngx-guided-tour';

export const tourCitizen = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el Tribunal Electrónico para la Justicia Administrativa.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Crear solicitudes de notificación electrónica',
        content: 'Aquí puedes solicitar la recepción de notificaciones electrónicas.',
        selector: '.create-expediente',
        orientation: [Orientation.Right, Orientation.Top],
        scrollAdjustment: 560
    },
    {
        title: 'Historial de solicitud de notificaciones',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.list-expediente',
        orientation: Orientation.Right
    },
    {
        title: 'Buzón de notificaciones',
        content: 'Ésta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Top
    },
    {
        title: 'Registro de demanda / recurso / procedimiento inicial',
        content: 'Aquí puedes registrar distintas demandas de tipo inicial.',
        selector: '.create-demand',
        orientation: Orientation.Left
    },
    {
        title: 'Registro de promociones',
        content: 'Podrás continuar con el proceso jurisdiccional ingresando una promoción relativa a un asunto que ya se está atendiendo en el Tribunal de Justicia Administraiva del Estado de México.',
        selector: '.create-promotion',
        orientation: [Orientation.Top, Orientation.Left]
    },
    {
        title: 'Historial de promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.list-promotion',
        orientation: Orientation.Top
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
];

export const tourSecretary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el Tribunal Electrónico para la Justicia Administrativa.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Administrar solicitudes de notificación electrónica',
        content: 'Consulta la lista de todas tus solicitudes de notificación electrónica, así como el estatus de cada una de ellas.',
        selector: '.manager-expediente',
        orientation: Orientation.Bottom
    },
    {
        title: 'Administrar promociones',
        content: 'Consulta la lista de promociones que has registrado, así como la información de cada una de ellas.',
        selector: '.manager-promotion',
        orientation: Orientation.Bottom
    },
    {
        title: 'Administrar acuerdos',
        content: 'Consulta la lista de acuerdos que has registrado, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Bottom
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]

export const tourActuary = [
    {
        title: '¡Bienvenido!',
        content: 'A continuación te mostraremos las operaciones que puedes realizar en el Tribunal Electrónico para la Justicia Administrativa.',
        selector: '.header',
        orientation: Orientation.Bottom
    },
    {
        title: 'Historial de notificaciones',
        content: 'Ésta es la bandeja de entrada donde puedes ver las notificaciones electrónicas recibidas.',
        selector: '.admin-notication',
        orientation: Orientation.Bottom
    },
    {
        title: 'Gestor de acuerdos',
        content: 'Consulta la lista de acuerdos que has registrado, así como la información de cada uno de ellos.',
        selector: '.manager-agreement',
        orientation: Orientation.Bottom
    },
    {
        title: 'Registro de notificación electrónica',
        content: 'Crear una nueva noticifación electrónica',
        selector: '.electronica',
        orientation: Orientation.Bottom
    },
    {
        title: 'Registro de notificación física',
        content: 'Registrar una notificación física',
        selector: '.fisica',
        orientation: Orientation.Bottom
    },
    {
        title: 'Menú',
        selector: '.group-items',
        content: 'Si lo prefieres, puedes realizar todas las acciones descritas a través de esta sección.',
        orientation: Orientation.Right
    }
]