export class AppConfig {
    // ######### LOCAL ##########
	// url: string = 'http://localhost/notificaciones/public/api/';
	//url: string = 'https://trijaem.nuevas-soluciones.net/api/api/';
    // ######### PROD  ##########
    //url: string = 'http://enlinea.test/api/';
    url: string = 'https://apiteja.bitgob.com/api/';
    //url: string = 'http://127.0.0.1:8000/api/';
    //url: string = 'https://trijaem.gob.mx/api/api/';
    //test
    //url: string = 'https://api.apilaravel.ml/api/';
    // ######### url tutoriales de registro ##############
    tutorialCitizen: string  = 'https://www.youtube.com/watch?v=dHQf817PH8Q';
    tutorialAutority: string = 'https://www.youtube.com/watch?v=TeDY3gq-bXc&t=1s';
    //############ flag si hay guardia (en dias festivos, vacaciones etc.) #############
    haveGuardia = false;
    urlFirel='https://www.firel.pjf.gob.mx/';
    informationGuard = `<h1>¡Aviso!</h1>
                        <p>
                        Entraremos en periodo de vacaciones del 14 de julio al 02 de agosto de 2021. Ponemos a su disposición el <a target="_blank" href="https://trijaem.gob.mx/wp-content/uploads/2021/07/GACETA-primer-periodo-vacacional.pdf"> ACUERDO DE LA JUNTA DE GOBIERNO Y ADMINISTRACIÓN DEL TRIBUNAL DE JUSTICIA ADMINISTRATIVA DEL ESTADO DE MÉXICO, POR EL QUE SE DESIGNA A LA MAGISTRADA, ASÍ COMO AL PERSONAL DE ACTUACIÓN, QUE CUBRIRÁ LA GUARDIA CORRESPONDIENTE AL PRIMER PERÍODO VACACIONAL DEL AÑO DOS MIL VEINTIUNO</a>. 
                        </p>
                       `;
}
