import { FuseNavigation } from '@fuse/types';

const resetFlags = () => {
    sessionStorage.setItem('promotions', 'false');
    sessionStorage.setItem('agreements', 'false');
    sessionStorage.setItem('notifications', 'false');
    sessionStorage.setItem('expedients', 'false');
    sessionStorage.setItem('users', 'false');
    sessionStorage.setItem('sentences', 'false');
    console.log("usuario magistrado",  );
}

const dashboardMenu: FuseNavigation = {
    id       : 'sample',
    title    : 'Inicio',
    type     : 'item',
    icon     : 'home',
    url      : '/app/inicio',
    function : resetFlags
    /*badge    : {
        title    : '25',
        translate: 'NAV.SAMPLE.BADGE',
        bg       : '#F44336',
        fg       : '#FFFFFF'
    }*/
};

const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/promotors-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/ciudadano'
        }
    ]
};
const estadistica: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Estadística',
    type    : 'item',
    icon    : 'bar_chart',
    url     : '/estadistic/main',
    function : resetFlags
}

const firmaDocumentos: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Firma de Documentos',
    type    : 'item',
    icon    : 'create',
    url     : '/sign-doc/main',
    function : resetFlags
}

const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de funcionarios',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/users/list/empleado',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/officers-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/empleado'
        }
    ]
};

const userAuthorities: FuseNavigation = {
    id      : 'authorities',
    title   : 'Gestión de autoridades',
    type    : 'item',
    icon    : 'business',
    url  : '/users/list/autoridad',
};
const Calendario: FuseNavigation = {
    id      : 'authorities',
    title   : 'Calendario',
    type    : 'item',
    icon    : 'calendar_today',
    url  : 'calendario'
};
const userAuthorized: FuseNavigation = {
    id      : 'authorized',
    title   : 'Gestión de autorizados',
    type    : 'item',
    icon    : 'business',
    url  : '/users/list/autorizados',
};


const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Usuarios',
    type    : 'group',
    icon    : 'emoji_people',
    children: [
        usersPromoventes,
        userOfficers,
        userAuthorities,
        Calendario,
        
        
        //userAuthorized
    ]
}

const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Mis notificaciones electrónicas',
    type    : 'collapsable',
    icon    : 'assignment',
    children: [
        {
            id   : 'create-expediente',
            title: 'Solicitar notificación electrónica',
            type : 'item',
            icon : 'notifications_none',
            url  : '/management/expedient-create',
            function : resetFlags
        },
        {
            id   : 'list-expediente',
            title: 'Historial de solicitud de notificaciones',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients',
            function : resetFlags
        }
    ]
}

const expedientAuthorityMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Mis notificaciones electrónicas',
    type    : 'collapsable',
    icon    : 'assignment',
    children: [
        {
            id   : 'create-expediente',
            title: 'Solicitar notificación electrónica',
            type : 'item',
            icon : 'notifications_none',
            url  : '/management/expedient-create',
            function : resetFlags
        },
        {
            id   : 'create-expediente-inblock',
            title: 'Solicitar notificación electrónica en bloque',
            type : 'item',
            icon : 'notifications_none',
            url  : '/management/expedient-create-in-block',
            function : resetFlags
        },
        {
            id   : 'list-expediente',
            title: 'Historial de solicitud de notificaciones',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients',
            function : resetFlags
        }
    ]
        
}

const expedientAdminMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Administrar solicitudes de notificación electrónica',
    type    : 'item',
    icon    : 'room_service',
    url  : '/management/expedients',
    function : resetFlags
    /*children: [
        {
            id   : 'list-expediente',
            title: 'Listar expedientes',
            type : 'item',
            url  : '/management/expedients'
        }
    ]*/
}

const promotionsMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Mis promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-demand',
            title: 'Registro de demanda / recurso / procedimiento inicial',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-demand',
            function : resetFlags
        },
        {
            id   : 'create-promotion',
            title: 'Registro de promociones',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-create',
            function : resetFlags
        },
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}

const promotionsAutorityMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Mis promociones',
    type    : 'collapsable',
    icon    : 'chat',
    children: [ 
        {
            id   : 'create-demand',
            title: 'Registro de demanda / recurso / procedimiento inicial',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-demand',
            function : resetFlags
        }, {
            id   : 'create-promotion',
            title: 'Registro de promociones',
            type : 'item',
            icon : 'edit',
            url  : '/promotion/promotion-create',
            function : resetFlags
        },
        
        {
            id   : 'list-promotion',
            title: 'Historial de promociones',
            type : 'item',
            icon : 'description',
            url  : '/promotion/all',
            function : resetFlags
        }
    ]
}

const promotionsOfficersMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Administrar promociones',
    type    : 'item',
    icon  : 'description',
    url   : '/promotion/all',
    function : resetFlags
}

const promotionsAuxiliarMenu: FuseNavigation = {
    id      : 'manager-promotion',
    title   : 'Asuntos recibidos',
    type    : 'item',
    icon  : 'description',
    url   : '/agreements/all',
    function : resetFlags
}

const agreementsOfficersMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Administrar acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const agreementsSignMenu: FuseNavigation = {
    id: 'sign-agreement',
    title: 'Acuerdos para firma',
    type: 'item',
    icon: 'import_contacts',
    url: '/agreements-sign/all',
    function: resetFlags
}

const expedientSecretaryMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const sentenceAuthorityMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Gestor de sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const expedientMagMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Bandeja de proyectos y sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const expedientProyecMenu: FuseNavigation = {
    id      : 'manager-sentences',
    title   : 'Bandeja de proyectos y sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/sentences/all',
	function : resetFlags       
}

const historyProyecMenu: FuseNavigation = {
    id      : 'history-sentences',
    title   : 'Historial de sentencias',
    type    : 'item',
    icon    : 'gavel',
	url  : '/history-sentences/all',
	function : resetFlags 
}

const agreementsActuaryMenu: FuseNavigation = {
    id      : 'manager-agreement',
    title   : 'Gestor de acuerdos',
    type    : 'item',
    icon    : 'import_contacts',
    url  : '/agreements/all',
    function : resetFlags
}

const notificationMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Buzón de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}

const notificationActuaryMenu: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Historial de notificaciones',
    type    : 'item',
    icon    : 'mail',
    url     : '/notifications/mail',
    function : resetFlags
}

const actionsActuaryMenu: FuseNavigation[] = [
    {
        id      : 'electronica',
        title   : 'Registro de notificación electrónica',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    },
    {
        id      : 'fisica',
        title   : 'Registro de notificación física',
        type    : 'item',
        function : resetFlags,
        icon    : 'mail',
    }
]

const visorMagMenu: FuseNavigation = {
    id: 'visor-mag',
    title: 'Visor',
    type: 'collapsable',
    icon: 'admin',
    children: [
        {
            id: 'visor-promotions',
            title: 'Promociones',
            type: 'item',
            icon: 'edit',
            url: '/visor/promotions/all',
            function: resetFlags
        },
        {
            id: 'visor-agreements',
            title: 'Acuerdos',
            type: 'item',
            icon: 'edit',
            url: '/visor/agreements/all',
            function: resetFlags
        },
        {
            id: 'visor-sentences',
            title: 'Proyectos',
            type: 'item',
            icon: 'edit',
            url: '/visor/sentences/all',
            function: resetFlags
        },
        {
            id: 'visor-notifications',
            title: 'Notificaciones',
            type: 'item',
            icon: 'edit',
            url: '/visor/notifications/all',
            function: resetFlags
        }
    ]
}

const visorMagMain: FuseNavigation = {
    id      : 'admin-notication',
    title   : 'Visor de actividades',
    type    : 'item',
    icon    : 'mail',
    url     : '/visor/main',
    function : resetFlags
}

export const adminMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        //expedientAdminMenu,
        //notificationMenu,
        usersMenu,
    ]
}];

export const actuaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        notificationActuaryMenu,
        agreementsActuaryMenu,
        sentenceAuthorityMenu,estadistica,firmaDocumentos,
        ...actionsActuaryMenu
    ]
}];

export const actuaryMenuDashboard: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        notificationActuaryMenu,
        agreementsActuaryMenu,estadistica,firmaDocumentos,
        ...actionsActuaryMenu
    ]
}];

export const secretaryMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        expedientAdminMenu,
        promotionsOfficersMenu,
        agreementsOfficersMenu,
        agreementsSignMenu,
        expedientSecretaryMenu,
        estadistica,firmaDocumentos
    ]
}];

export const streamMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        expedientMenu,
        notificationMenu,
        promotionsMenu
    ]
}];

export const authorityMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        expedientAuthorityMenu,
        notificationMenu,
        promotionsAutorityMenu
    ]
}];

export const authorityAdminMenu: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        expedientAuthorityMenu,
        notificationMenu,
        promotionsAutorityMenu,
        userAuthorized
    ]
}];

/**
 * Set menu magistrado
 */
export const streamMag: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        //expedientAdminMenu,
        //notificationMenu,
        agreementsOfficersMenu,
        expedientMagMenu,visorMagMain,
        estadistica,firmaDocumentos
    ]
}]

export const streamMagSup: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        //expedientAdminMenu,
        //notificationMenu,
        expedientMagMenu,
        estadistica,firmaDocumentos
    ]
}]

export const streamMagPre: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        //expedientAdminMenu,
        //notificationMenu,
        expedientMagMenu,
        visorMagMain,
        estadistica,firmaDocumentos
    ]
}]
/**
 * Set menu magistrado superior
 */

/**
 * Set menu proyectista regional
 */

export const streamProyec: FuseNavigation[] =[{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        expedientProyecMenu,
        historyProyecMenu,
        estadistica
    ]
}]

/**
 * Set menu auxiliar
 */

export const streamAux: FuseNavigation[] = [{
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        dashboardMenu,
        promotionsAuxiliarMenu
    ]
}]