<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ txtTitleModal }}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <!--div class="vertical-stepper-wrapper"-->
        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper"
            *ngIf="!isNotificationSala && txtTitleModal!='Emplazamiento' && txtTitleModal!='Emplazar particular'  && bloquen==false">
            <mat-step [stepControl]="placeForm"
                *ngIf="placeForm && isEmplazarAutoridad && txtTitleModal!='Notificar a Sala'">
                <form fxLayout="column" [formGroup]="placeForm">
                    <ng-template matStepLabel>DATOS DE AUTORIDAD</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="muni" (selectionChange)="_getNamesList()" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of municipalities" value="{{item.nume}}">{{item.nomb}}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Municipio
                            </mat-label>
                            <mat-error>Debes seleccionar un municipio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="tpau" (selectionChange)="_getNamesList()" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="estatal">Estatal</mat-option>
                                <mat-option value="municipal">Municipal</mat-option>
                                <mat-option value="particular">Particular</mat-option>
                            </mat-select>
                            <mat-label>
                                Tipo Autoridad
                            </mat-label>
                            <mat-error>Debes seleccionar un tipo de autoridad</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Nombre Autoridad</mat-label>
                            <input type="text" aria-label="Number" matInput formControlName="auto"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #autocompl #auto="matAutocomplete">
                                <mat-option (onSelectionChange)="_getTitularsByAutoridad()"
                                    *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="!notIsRegisteredAuthority && !dontHaveTitularsAuth">
                            <mat-select formControlName="titu" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of titularsList" value="{{item.auto}}">
                                    {{item.nomb}} {{ item.pate }} {{ item.mate }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Nombre Titular
                            </mat-label>
                            <mat-error>Debes seleccionar nombre de titular</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="notIsRegisteredAuthority || dontHaveTitularsAuth">
                            <mat-label>Nombre completo de titular</mat-label>
                            <input matInput formControlName="titu" [disabled]="true" required>
                            <mat-error>Debes ingresar un nombre de titular</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="notIsRegisteredAuthority || dontHaveTitularsAuth">
                            <mat-label>Correo electronico titular</mat-label>
                            <input matInput formControlName="mail" [disabled]="true">
                            <mat-error>Debes ingresar un correo electronico valido de titular</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!placeForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <!--STEP EMPLAZAR A CIUDADANO-->
            <mat-step [stepControl]="placeCitiForm"
                *ngIf="placeCitiForm && isEmplazarCiudadano && txtTitleModal!='Notificar a Sala'">
                <form fxLayout="column" [formGroup]="placeCitiForm">

                    <ng-template matStepLabel>DATOS DEL CIUDADANO</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div fxFlex="1 0 auto" fxLayout="column">

                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input type="text" aria-label="Number" matInput formControlName="auto"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #autocompl #auto="matAutocomplete">
                                    <mat-option (onSelectionChange)="_getTitularsByAutoridad()"
                                        *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Apellido paterno</mat-label>
                                <input matInput formControlName="apellPatPart" required>
                                <mat-error>Debes ingresar un apellido paterno</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Apellido materno</mat-label>
                                <input matInput formControlName="apellMatPart" required>
                                <mat-error>Debes ingresar un apellido materno</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>CURP (opcional)</mat-label>
                                <input matInput formControlName="curpPart">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Correo electrónico</mat-label>
                                <input matInput formControlName="mail">
                                <mat-error>Debes ingresar un correo electronico valido de titular</mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button mat-raised-button matStepperNext type="button" color="accent"
                                [disabled]="!placeForm.valid">
                                Continuar
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>



            <mat-step [stepControl]="expedientForm" *ngIf="txtTitleModal!='Notificar a Sala'">
                <form fxLayout="column" [formGroup]="expedientForm">
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="txtTitleModal == 'Nueva notificación de acuerdo por estrados'">
                            <mat-select formControlName="mater" required>

                                <mat-option value="Ordinaria">Ordinaria</mat-option>
                                <mat-option value="Especializada">Especializada</mat-option>
                            </mat-select>
                            <mat-label>
                                Materia
                            </mat-label>
                            <mat-error>Debes seleccionar una Materia</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">Número de expediente de Órgano Jurisdiccional
                            </mat-label>
                            <input matInput formControlName="acue" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE)</mat-label>
                            <input matInput formControlName="expe" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-select formControlName="proce" required>
                                <mat-option *ngFor="let item of tiprecu" value="{{ item.handle  }}">
                                    {{item.name}}
                                </mat-option>

                            </mat-select>
                            <mat-label>
                                Tipo De Juicio
                            </mat-label>
                            <mat-error>Debes seleccionar un Tipo de juicio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">Número de expediente de Órgano Jurisdiccional
                            </mat-label>
                            <input matInput formControlName="une" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedientex($event)" required>
                            <mat-error *ngIf="expedientForm.get('une').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('une').hasError('required') &&
                                            expedientForm.get('une').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">(UNE)</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE)</mat-label>
                            <input matInput formControlName="expe" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedient(expedientForm.get('expe'))" required>
                            <mat-error *ngIf="expedientForm.get('expe').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('expe').hasError('required') &&
                                            expedientForm.get('expe').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue && bloquen===false">
                            <mat-select formControlName="proce" required>
                                <mat-option *ngFor="let item of tiprecu" value="{{ item.handle  }}">
                                    {{item.name}}
                                </mat-option>

                            </mat-select>
                            <mat-label>
                                Tipo De Juicio
                            </mat-label>
                            <mat-error>Debes seleccionar un Tipo de juicio</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments" value="{{ dep.nume }}">{{ dep.depa }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>



                        <h3><em>Partes Procesales</em></h3>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Actor</mat-label>
                            <input matInput formControlName="part" required>
                            <mat-error>Debes ingresar un nombre de actor</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Demandado</mat-label>
                            <input matInput formControlName="dema" required>
                            <mat-error>Debes ingresar un nombre de demandado</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Tercero Interesado</mat-label>
                            <input matInput formControlName="terc">
                            <mat-error>Debes ingresar un nombre de tercero interesado</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="expedientForm" *ngIf="txtTitleModal=='Notificar a Sala'">
                <form fxLayout="column" [formGroup]="expedientForm">
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                    
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments2" value="{{ dep.nume }}">{{ dep.depa }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>



                        
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step *ngIf="!isEmplazarAutoridad">
                <form fxLayout="column" [formGroup]="usersForm">
                    <ng-template matStepLabel>{{ txtTitleUser }}</ng-template>
                    <div fxLayout="column" fxLayoutAlign="start start" style="overflow-x: auto;"
                        *ngIf="!isFisicNotification && !isFisicNotificationAcue && !isFisicNotificationEstrados">

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="users.length > 0">
                            <h3>Ciudadanos</h3>
                            <section>
                                <mat-checkbox *ngFor="let user of users" style="margin-right: 15px;"
                                    (change)="setUser(user, $event)">
                                    {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray"
                                        *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                    <p class="labels-gray m-0">{{ user.mail }}</p>
                                </mat-checkbox>
                            </section>
                        </div>

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="autorities.length > 0">
                            <h3>Autoridades</h3>
                            <mat-checkbox *ngFor="let user of autorities" style="margin-right: 15px;"
                                (change)="setUser(user, $event)">
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray"
                                    *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                <p class="labels-gray m-0">{{ user.mail }}</p>
                            </mat-checkbox>
                        </div>

                        <div class="py-24" *ngIf="users.length == 0">
                            <mat-hint>
                                No se encontraron ciudadanos suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p"
                        *ngIf="isFisicNotification || isFisicNotificationAcue">

                        <mat-label>Usuarios</mat-label>

                        <mat-chip-list #categoryList name="usua" formControlName="usua">

                            <mat-chip *ngFor="let user of usersForm.value.usua" [removable]="true"
                                (removed)="removeUser(user)">
                                {{user}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>

                            <input [matChipInputFor]="categoryList" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addUser($event)" />

                        </mat-chip-list>
                        <mat-hint>Pulsa ENTER para agregar </mat-hint>
                    </mat-form-field>

                    <div fxFlex="1 0 auto" fxLayout="column" style="overflow-x: auto;"
                        *ngIf="isFisicNotificationEstrados">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePickerP"
                                formControlName="disp" (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>
                    </div>

                </form>
                <div fxLayout="row" fxLayoutAlign="space-evenly center">
                    <button mat-raised-button matStepperNext type="button" color="accent" class="mt-40"
                        [disabled]="validUsersForm()">Continuar</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="resolutionForm" *ngIf="txtTitleModal!='Notificar a Sala'">
                <form fxLayout="column" [formGroup]="resolutionForm">
                    <ng-template matStepLabel>DETERMINACIÓN {{ txtDeterminacion }}</ng-template>

                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-select formControlName="tipo" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="acuerdo">Acuerdo</mat-option>
                                <mat-option value="resolución">Resolución</mat-option>
                                <mat-option value="sentencia">Sentencia</mat-option>
                            </mat-select>
                            <mat-label>
                                Determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-label>
                            <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFill>
                            <mat-select formControlName="tppa">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of parteTypes" value="{{ item.nume }}">{{ item.name }}
                                </mat-option>
                            </mat-select>

                            <mat-label>
                                Partes a quien va dirigido
                            </mat-label>
                            <mat-error>Debes seleccionar una opción</mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha en que fue dictado</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="fecd"
                                (focus)="startDatePicker.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex
                            *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <mat-label>Fecha de notificación</mat-label>
                            <input matInput [matDatepicker]="startDatePicker2" formControlName="fecn"
                                (focus)="startDatePicker2.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker2></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-label *ngIf="isFisicNotification">Hora de notificación</mat-label>
                        <mat-form-field appearance="outline" *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <input matInput placeholder="Simple placeholder" style="display: none;">
                            <ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="time">
                            </ngx-timepicker-field>
                        </mat-form-field>

                        <div *ngIf="isAcuerdoNotification || isFisicNotificationAcue && agreementData!=null">
                            
                            <a *ngIf="agreementData!=null && agreementData.docu" mat-stroked-button color="accent"
                            (click)="previewFileurl(agreementData.docu)" target="_blank">
                            <!--href="{{agreementData.docu }}" -->
                            Ver acuerdo
                        </a>
                        <a *ngIf="agreementData!=null && agreementData.link" mat-stroked-button color="accent"
                            (click)="previewFileurl(agreementData.link)" target="_blank">
                            <!--href="{{agreementData.docu }}" -->
                            Ver sentencia
                        </a>
                        </div>


                        <div class="file-uploader" fxFlex="1 0 auto"
                            *ngIf="!isAcuerdoNotification || isFisicNotificationAcue" fxLayout="column"
                            fxLayoutAlign="space-envenly start">
                            <mat-label>{{ txtTitleDocs }}</mat-label>

                            <input type="file" #adju id="adju" name="adju" style="display:none;"
                                accept="application/pdf" required />
                            <button mat-raised-button color="info" (click)="setFile('adju')">
                                <mat-icon>backup</mat-icon>
                                <span> Elegir archivo</span>
                            </button>
                            <div *ngIf="resolutionForm.value.adju">
                                <div class="attachment-list"
                                    *ngIf="resolutionForm.value.adju !== null && resolutionForm.value.adju.name !== undefined">
                                    <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div>
                                            <span class="filename">{{ resolutionForm.value.adju.name }}</span>
                                            <!--span class="size ml-4">(12 Kb)</spandaut-->
                                        </div>
                                        <button mat-icon-button aria-label="Delete attachment">
                                            <mat-icon class="s-16" (click)="removeFile('adju')"
                                                tooltip="Quitar archivo">close</mat-icon>
                                        </button>
                                        <button mat-icon-button aria-label="Preview attachment">
                                            <mat-icon class="s-16" (click)="previewFile('adju')" tooltip="Ver archivo">
                                                visibility</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <mat-error *ngIf="resolutionForm.get('adju').hasError('required')" class="error">
                                {{ txtErrorFileadju }}</mat-error>
                        </div>

                        <mat-label class="mt-16 mb-2">Anexando al efecto los archivos </mat-label>
                        <div *ngIf="arregloselec2.length!=null">

                            <div *ngFor="let item of arregloselec2">
                                <br>

                                <div *ngFor="let item2 of item"> <br> <a mat-stroked-button color="accent"
                                        (click)="previewFileurl(item2.docu)" target="_blank">
                                        Ver Anexo {{item2.dscr}}
                                    </a> <br>
                                </div>
                            </div>

                        </div>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" id="{{file.name}}desc" name="{{file.name}}desc"
                                                (keyup)="addDescFile(file.name, in)" maxlength="100" required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;"
                                    accept="application/pdf" required />
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)"
                                    [disabled]="!file.upload">
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFiler(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="previewFiler(file.file, in)" tooltip="Ver archivo">visibility
                                    </mat-icon>
                                    <!--span>Ver archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex
                            *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()"
                                [disabled]="anexosArray.length === 5">
                                <mat-icon>add_circle_outline</mat-icon>
                                <span> Subir otro archivo</span>
                            </button>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!resolutionForm.valid" *ngIf="_data.typeNotification != 'acue-fisi'">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="firmaForm"
                *ngIf="_data.typeNotification != 'acue-fisi' && _data.typeNotification != 'acue-fisis2'">
                <form fxLayout="column" [formGroup]="firmaForm">
                    <ng-template matStepLabel>FIRMA</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div class="col-sm-3">
                            Cargar archivo FIREL <i class="icono fas fa-upload"></i>
                            <input type="file" id="pfx" accept=".pfx" class="upload-input"
                                (change)="SeleccionarPfx($event.target)" />
                            <div id="divArchivo" class="textoArchivo"></div>
                            <div id="divCargaFirel" class="cargaFirel">
                            </div>
                            <br>

                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>Escriba su contraseña:</mat-label>
                                <!--<input matInput name="keyp" type="password" id="keyp"    [(ngModel)]="password"/><br>-->
                                <input matInput type="password" formControlName="password"
                                    (keyup.enter)="enviar(event)" />
                            </mat-form-field>

                            <!--<button class="btn btn-primary"  [disabled]=!activarBoton id="btnSign"
                            (click)="firmar()">
                                Descargar docs
                            </button>-->
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>

        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper"
            *ngIf="!isNotificationSala && txtTitleModal=='Emplazamiento' && bloquen==false">
            <mat-step [stepControl]="placeForm" *ngIf="placeForm && isEmplazarAutoridad">
                <form fxLayout="column" [formGroup]="placeForm">
                    <ng-template matStepLabel>DATOS DE AUTORIDAD</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="muni" (selectionChange)="_getNamesList()" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of municipalities" value="{{item.nume}}">{{item.nomb}}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Municipio
                            </mat-label>
                            <mat-error>Debes seleccionar un municipio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="tpau" (selectionChange)="_getNamesList()" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="estatal">Estatal</mat-option>
                                <mat-option value="municipal">Municipal</mat-option>
                                <mat-option value="particular">Particular</mat-option>
                            </mat-select>
                            <mat-label>
                                Tipo Autoridad
                            </mat-label>
                            <mat-error>Debes seleccionar un tipo de autoridad</mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline">
                            <mat-label>Nombre Autoridad</mat-label>
                            <input type="text" aria-label="Number" matInput formControlName="auto"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #autocompl #auto="matAutocomplete">
                                <mat-option (onSelectionChange)="_getTitularsByAutoridad()"
                                    *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="!notIsRegisteredAuthority && !dontHaveTitularsAuth">
                            <mat-select formControlName="titu" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of titularsList" value="{{item.auto}}">
                                    {{item.nomb}} {{ item.pate }} {{ item.mate }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Nombre Titular
                            </mat-label>
                            <mat-error>Debes seleccionar nombre de titular</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="notIsRegisteredAuthority || dontHaveTitularsAuth">
                            <mat-label>Nombre completo de titular</mat-label>
                            <input matInput formControlName="titu" [disabled]="true" required>
                            <mat-error>Debes ingresar un nombre de titular</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="notIsRegisteredAuthority || dontHaveTitularsAuth">
                            <mat-label>Correo electronico titular</mat-label>
                            <input matInput formControlName="mail" [disabled]="true">
                            <mat-error>Debes ingresar un correo electronico valido de titular</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-select formControlName="cru" required>
                                <mat-option *ngFor="let item of caracterReconocido" value="{{ item.value  }}">
                                    {{item.label}}
                                </mat-option>

                            </mat-select>
                            <mat-label>
                                Carácter Reconocido en autos
                            </mat-label>
                            <mat-error>Debes seleccionar un caracter</mat-error>
                        </mat-form-field>


                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!placeForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <!--STEP EMPLAZAR A CIUDADANO-->
            <mat-step [stepControl]="placeCitiForm" *ngIf="placeCitiForm && isEmplazarCiudadano">
                <form fxLayout="column" [formGroup]="placeCitiForm">

                    <ng-template matStepLabel>DATOS DEL CIUDADANO</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div fxFlex="1 0 auto" fxLayout="column">

                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input type="text" aria-label="Number" matInput formControlName="auto"
                                    [matAutocomplete]="auto">
                                <mat-autocomplete #autocompl #auto="matAutocomplete">
                                    <mat-option (onSelectionChange)="_getTitularsByAutoridad()"
                                        *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Apellido paterno</mat-label>
                                <input matInput formControlName="apellPatPart" required>
                                <mat-error>Debes ingresar un apellido paterno</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Apellido materno</mat-label>
                                <input matInput formControlName="apellMatPart" required>
                                <mat-error>Debes ingresar un apellido materno</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>CURP (opcional)</mat-label>
                                <input matInput formControlName="curpPart">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50">
                                <mat-label>Correo electrónico</mat-label>
                                <input matInput formControlName="mail">
                                <mat-error>Debes ingresar un correo electronico valido de titular</mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button mat-raised-button matStepperNext type="button" color="accent"
                                [disabled]="!placeForm.valid">
                                Continuar
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="expedientForm">
                <form fxLayout="column" [formGroup]="expedientForm">
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE) o Número de expediente de Órgano
                                Jurisdiccional</mat-label>
                            <input matInput formControlName="acue" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE) o Número de expediente de Órgano
                                Jurisdiccional</mat-label>
                            <input matInput formControlName="une" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedientex($event)" required>
                            <mat-error *ngIf="expedientForm.get('une').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('une').hasError('required') &&
                                            expedientForm.get('une').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE)</mat-label>
                            <input matInput formControlName="expe" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedient(expedientForm.get('expe'))" required>
                            <mat-error *ngIf="expedientForm.get('expe').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('expe').hasError('required') &&
                                            expedientForm.get('expe').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-select formControlName="proce" required>
                                <mat-option *ngFor="let item of tiprecu" value="{{ item.handle  }}">
                                    {{item.name}}
                                </mat-option>

                            </mat-select>
                            <mat-label>
                                Tipo De Juicio
                            </mat-label>
                            <mat-error>Debes seleccionar un Tipo de juicio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments" value="{{ dep.nume }}">{{ dep.depa }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>

                        <h3><em>Partes Procesales</em></h3>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Actor</mat-label>
                            <input matInput formControlName="part" required>
                            <mat-error>Debes ingresar un nombre de actor</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Demandado</mat-label>
                            <input matInput formControlName="dema" required>
                            <mat-error>Debes ingresar un nombre de demandado</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Tercero Interesado</mat-label>
                            <input matInput formControlName="terc">
                            <mat-error>Debes ingresar un nombre de tercero interesado</mat-error>
                        </mat-form-field>



                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step *ngIf="!isEmplazarAutoridad">
                <form fxLayout="column" [formGroup]="usersForm">
                    <ng-template matStepLabel>{{ txtTitleUser }}</ng-template>
                    <div fxLayout="column" fxLayoutAlign="start start" style="overflow-x: auto;"
                        *ngIf="!isFisicNotification && !isFisicNotificationAcue && !isFisicNotificationEstrados">

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="users.length > 0">
                            <h3>Ciudadanos</h3>
                            <section>
                                <mat-checkbox *ngFor="let user of users" style="margin-right: 15px;"
                                    (change)="setUser(user, $event)">
                                    {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray"
                                        *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                    <p class="labels-gray m-0">{{ user.mail }}</p>
                                </mat-checkbox>
                            </section>
                        </div>

                        <div appearance="outline" class="pr-4" fxFlex *ngIf="autorities.length > 0">
                            <h3>Autoridades</h3>
                            <mat-checkbox *ngFor="let user of autorities" style="margin-right: 15px;"
                                (change)="setUser(user, $event)">
                                {{user.nomb}} {{user.pate}} {{user.mate}} <span class="labels-gray"
                                    *ngIf="!isAcuerdoNotification">({{user.type}})</span>
                                <p class="labels-gray m-0">{{ user.mail }}</p>
                            </mat-checkbox>
                        </div>

                        <div class="py-24" *ngIf="users.length == 0">
                            <mat-hint>
                                No se encontraron ciudadanos suscritos a este expediente, verifique los datos
                            </mat-hint>
                        </div>
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p"
                        *ngIf="isFisicNotification || isFisicNotificationAcue">

                        <mat-label>Usuarios</mat-label>

                        <mat-chip-list #categoryList name="usua" formControlName="usua">

                            <mat-chip *ngFor="let user of usersForm.value.usua" [removable]="true"
                                (removed)="removeUser(user)">
                                {{user}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>

                            <input [matChipInputFor]="categoryList" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addUser($event)" />

                        </mat-chip-list>
                        <mat-hint>Pulsa ENTER para agregar </mat-hint>
                    </mat-form-field>

                    <div fxFlex="1 0 auto" fxLayout="column" style="overflow-x: auto;"
                        *ngIf="isFisicNotificationEstrados">
                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha de publicación</mat-label>
                            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePickerP"
                                formControlName="disp" (focus)="startDatePickerP.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerP"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerP></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>
                    </div>

                </form>
                <div fxLayout="row" fxLayoutAlign="space-evenly center">
                    <button mat-raised-button matStepperNext type="button" color="accent" class="mt-40"
                        [disabled]="validUsersForm()">Continuar</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="resolutionForm">
                <form fxLayout="column" [formGroup]="resolutionForm">
                    <ng-template matStepLabel>DETERMINACIÓN {{ txtDeterminacion }}</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-select formControlName="tipo" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="acuerdo">Acuerdo</mat-option>
                                <mat-option value="resolución">Resolución</mat-option>
                                <mat-option value="sentencia">Sentencia</mat-option>
                            </mat-select>
                            <mat-label>
                                Determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-label>
                            <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFill>
                            <mat-select formControlName="tppa">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of parteTypes" value="{{ item.nume }}">{{ item.name }}
                                </mat-option>
                            </mat-select>

                            <mat-label>
                                Partes a quien va dirigido
                            </mat-label>
                            <mat-error>Debes seleccionar una opción</mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha en que fue dictado</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="fecd"
                                (focus)="startDatePicker.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex
                            *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <mat-label>Fecha de notificación</mat-label>
                            <input matInput [matDatepicker]="startDatePicker2" formControlName="fecn"
                                (focus)="startDatePicker2.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker2></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-label *ngIf="isFisicNotification">Hora de notificación</mat-label>
                        <mat-form-field appearance="outline" *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <input matInput placeholder="Simple placeholder" style="display: none;">
                            <ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="time">
                            </ngx-timepicker-field>
                        </mat-form-field>

                        <div *ngIf="isAcuerdoNotification || isFisicNotificationAcue && agreementData!=null">
                            <a *ngIf="agreementData!=null && agreementData.docu" mat-stroked-button color="accent"
                                (click)="previewFileurl(agreementData.docu)" target="_blank">
                                <!--href="{{agreementData.docu }}"-->
                                Ver acuerdo
                            </a>
                            <a *ngIf="agreementData!=null && agreementData.doctestlink" mat-stroked-button
                                color="accent" href="{{agreementData.doctestlink }}" target="_blank">
                                Ver acuerdo
                            </a>
                        </div>

                        <div class="file-uploader" fxFlex="1 0 auto"
                            *ngIf="!isAcuerdoNotification || isFisicNotificationAcue" fxLayout="column"
                            fxLayoutAlign="space-envenly start">
                            <mat-label>{{ txtTitleDocs }}</mat-label>

                            <input type="file" #adju id="adju" name="adju" style="display:none;"
                                accept="application/pdf" required />
                            <button mat-raised-button color="info" (click)="setFile('adju')">
                                <mat-icon>backup</mat-icon>
                                <span> Elegir archivo</span>
                            </button>
                            <div *ngIf="resolutionForm.value.adju">

                                <div class="attachment-list"
                                    *ngIf="resolutionForm.value.adju !== null && resolutionForm.value.adju.name !== undefined">
                                    <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div>
                                            <span class="filename">{{ resolutionForm.value.adju.name }}</span>
                                            <!--span class="size ml-4">(12 Kb)</spandaut-->
                                        </div>
                                        <button mat-icon-button aria-label="Delete attachment">
                                            <mat-icon class="s-16" (click)="removeFile('adju')"
                                                tooltip="Quitar archivo">close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <mat-error *ngIf="resolutionForm.get('adju').hasError('required')" class="error">
                                {{ txtErrorFileadju }}</mat-error>
                        </div>

                        <mat-label class="mt-16 mb-2">Anexando al efecto los archivos </mat-label>
                        <div *ngIf="arregloselec2.length!=null">

                            <div *ngFor="let item of arregloselec2">
                                <br>

                                <div *ngFor="let item2 of item"> <br> <a mat-stroked-button color="accent"
                                        (click)="previewFileurl(item2.docu)" target="_blank">
                                        Ver Anexo {{item2.dscr}}
                                    </a> <br>
                                </div>
                            </div>

                        </div>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">

                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" id="{{file.name}}desc" name="{{file.name}}desc"
                                                (keyup)="addDescFile(file.name, in)" maxlength="100" required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;"
                                    accept="application/pdf" required />
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)"
                                    [disabled]="!file.upload">
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFiler(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="previewFiler(file.file, in)" tooltip="Ver archivo">visibility
                                    </mat-icon>
                                    <!--span>Ver archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex
                            *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()"
                                [disabled]="anexosArray.length === 5">
                                <mat-icon>add_circle_outline</mat-icon>
                                <span> Subir otro archivo</span>
                            </button>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!resolutionForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="firmaForm"
                *ngIf="!isFisicNotification && !isFisicNotificationAcue && !isFisicNotificationEstrados">
                <form fxLayout="column" [formGroup]="firmaForm">
                    <ng-template matStepLabel>FIRMA</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div class="col-sm-3">
                            Cargar archivo FIREL <i class="icono fas fa-upload"></i>
                            <input type="file" id="pfx" accept=".pfx" class="upload-input"
                                (change)="SeleccionarPfx($event.target)" />
                            <div id="divArchivo" class="textoArchivo"></div>
                            <div id="divCargaFirel" class="cargaFirel">
                            </div>
                            <br>

                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>Escriba su contraseña:</mat-label>
                                <!--<input matInput name="keyp" type="password" id="keyp"    [(ngModel)]="password"/><br>-->
                                <input matInput type="password" formControlName="password"
                                    (keyup.enter)="enviar(event)" />
                            </mat-form-field>

                            <!--<button class="btn btn-primary"  [disabled]=!activarBoton id="btnSign"
                            (click)="firmar()">
                                Descargar docs
                            </button>-->
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>

        <!-- EMPLAZAR PARTICULAR-->
        <mat-horizontal-stepper linear="true" #stepper id="stepper" name="stepper" *ngIf="bloquen===true">
            <mat-step [stepControl]="placeForm" *ngFor="let step of conutcc; let i = index">
                <ng-template matStepLabel>{{step.une}}</ng-template>

                <div fxLayout="row" fxLayoutAlign="center center">

                    <!-- SIDEBAR CONTENT -->
                    <div class="content p-24">

                        <div class="file-details" [@animate]="{value:'*',params:{delay:'200ms'}}">
                            <table>
                                <tr *ngIf="step.stat.handle == 'solicitud_correccion'">
                                    <th>Justificación:</th>
                                    <td>{{step.reason}}</td>
                                </tr>
                                <tr *ngIf="step.stat.handle == 'solicitud_correccion'">
                                    <th>Acuerdo corregido</th>
                                    <td>
                                        <a mat-button color="accent" href="{{step.docucorre }}" target="_blank">
                                            Ver acuerdo
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Número de expediente:</th>
                                    <td>{{step.expe}}</td>
                                </tr>
                                <tr>
                                    <th>Único Número de Expediente (UNE):</th>
                                    <td>{{ step.une }}</td>
                                </tr>
                                <tr class="size">
                                    <th>Fecha de acuerdo:</th>
                                    <td>{{getOnlyDate(step.date)}}</td>
                                </tr>

                                <tr class="size">
                                    <th>Estatus:</th>
                                    <td>
                                        <button mat-icon-button *ngIf="step.stat.name==''">
                                            <mat-icon style="color:#e29d36">access_time</mat-icon>
                                            En Revisión
                                        </button>
                                        <button mat-icon-button *ngIf="step.stat.name!=''">
                                            <mat-icon [class]="step.stat.class">history</mat-icon>
                                            {{step.stat.name}}
                                        </button>
                                    </td>
                                </tr>
                                <!--<tr class="type">
                                <th>Folio electrónico:</th>
                                <td>{{ step.ref }}</td>
                            </tr>-->
                                <tr class="type" *ngIf="step.stat == 'rechazado'">
                                    <th>Expediente:</th>
                                    <td>{{step.expe}}</td>
                                </tr>

                                <tr *ngIf="step.cons">
                                    <th>Número de promoción</th>
                                    <td>{{step.cons}}</td>
                                </tr>

                                <tr class="location">
                                    <th>Órgano Jurisdiccional:</th>
                                    <td>{{ step.empldata.data.depadata.data.depa }}</td>
                                </tr>

                                <tr class="">
                                    <th>Tipo de juicio:</th>
                                    <td>{{getTypeAgree(step.tpju)}}</td>
                                </tr>

                                <!--<tr class="type" *ngIf="step.stat.handle == 'firmado_magistrado' || step.stat.handle == 'firmado_secretario' || step.stat.handle == 'reemplazado_secretario' || step.stat.handle == 'creado' || step.stat.handle == 'adjuntado_auxiliar' || step.stat.handle == 'solicitud_correccion' || step.stat.handle == 'reemplazado'">-->
                                <tr class="type">
                                    <th *ngIf="step.stat.name!='Pendiente'">Acuerdo: </th>
                                    <td *ngIf="step.stat.name!='Pendiente'">
                                        <a mat-button color="accent" (click)="previewFile2(step.docu )"target="_blank"><!--href="{{step.docu }}" -->
                                            Ver acuerdo
                                        </a>
                                    </td>
                                </tr>
                                <tr class="no-print" [ngStyle]="{'vertical-align': 'top'}"
                                    *ngIf="step.stat.name=='Pendiente'">
                                    <th>Documentos</th>

                                </tr>
                                <tr class="owner" *ngIf="step.empldata != undefined">
                                    <th>Servidor público TRIJAEM: </th>
                                    <td>{{ step.empldata.data.usuadata.data.nomb }}
                                        {{ step.empldata.data.usuadata.data.pate }}
                                        {{ step.empldata.data.usuadata.data.mate }}</td>
                                </tr>

                                <tr *ngIf="step.auxdata">
                                    <th>Nombre del auxiliar:</th>
                                    <td>{{ step.auxdata.data.usuadata.data.nomb }}
                                        {{ step.auxdata.data.usuadata.data.pate }}
                                        {{ step.auxdata.data.usuadata.data.mate }}</td>
                                </tr>
                                
                            </table>
                            <div class="field" *ngIf="step.tipo === 'normal'  && promotions.length > 0">
                                <span class="label-gray m-24"> Promociones: </span>
                            </div>
                            <!--TABLE PROMOTIONS-->
                            <table mat-table [dataSource]="promotions" *ngIf="step.tipo === 'normal' && promotions.length > 0">
                    
                                <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->
                            
                                <!-- Position Column -->
                                <ng-container matColumnDef="promotion">
                                    
                                    <th mat-header-cell *matHeaderCellDef> No. Promoción </th>
                                    <td mat-cell *matCellDef="let element"><mat-checkbox (click)="seleccionar(element)" [value]="element.nume"
                                        *ngIf="typeUser === 'Actuario'"
                                        [indeterminate]="isIndeterminate"></mat-checkbox> {{element.cons}} </td>
                                </ng-container>
                                <!-- Position Column -->
                                <!--<ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef> Referencia </th>
                                    <td mat-cell *matCellDef="let element"> {{element.ref}} </td>
                                </ng-container>-->
                            
                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef style="padding-right: 17px;"> Tipo </th>
                                    <td mat-cell *matCellDef="let element" style="padding-right: 17px;"> {{element.tram}} </td>
                                </ng-container>
                            
                                <!-- Weight Column -->
                                <ng-container matColumnDef="weight">
                                    <th mat-header-cell *matHeaderCellDef> Estatus </th>
                                    <td mat-cell *matCellDef="let element"> {{element.stat}} </td>
                                </ng-container>
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="symbol">
                                    <th mat-header-cell *matHeaderCellDef> Documento </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <a href="{{element.strz}}" target="_blank">
                                            <mat-icon class="type-icon">file_copy</mat-icon>
                                            acuse
                                        </a> 
                                        <div *ngFor="let item of element.promoDocs">
                                            <a  (click)="previewFile2(item.docu)" target="_blank"><!--href="{{item.docu}}"-->
                                                <mat-icon class="type-icon">file_copy</mat-icon>
                                                ver enlace de promoción {{item.dscr}}
                                            </a> 
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef> Fecha<br>TEJA</th>
                                    <td mat-cell *matCellDef="let element"> {{element.crtd | date: 'dd-MM-yyyy hh:mm:ss'}} </td>
                                </ng-container>
                                <ng-container matColumnDef="dateS">
                                    <th mat-header-cell *matHeaderCellDef> Fecha<br>SIREPROC</th>
                                    <td mat-cell *matCellDef="let element"> {{element.siretd | date: 'dd-MM-yyyy hh:mm:ss'}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsPromotions"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsPromotions;"></tr>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="file-details m-24" fxLayout="column" fxLayoutAlign="space-between center" fxFlex>

                    <button mat-raised-button color="accent" class="mt-16 mr-12"
                        (click)="composeDialog2('acuerdo',step)">
                        <span>Notificación electrónica de acuerdo</span>
                    </button>

                    <button mat-raised-button color="accent" class="mt-16 mr-12"
                        (click)="composeDialog2('estrados',step)">
                        <span>Notificar por estrados digitales</span>
                    </button>

                    <button mat-raised-button color="accent" class="mt-16 mr-12"
                        (click)="composeDialog2('acue-fisi',step)">
                        <span>Carga de notificación física de acuerdo</span>
                    </button>

                    <button mat-raised-button color="accent" class="mt-16" (click)="composeDialog2('acue-sala',step)">
                        <span>Notificar a sala</span>
                    </button>

                    <button mat-raised-button color="accent" class="mt-16" (click)="composeDialog2('emplazar',step)">
                        <span>Notificar a autoridad</span>
                    </button>

                    <!--   <button mat-raised-button color="accent" class="mt-16" *ngIf="typeUser === 'Actuario'" (click)="composeDialog('emplazar-ciudadano')">
        <span>Emplazar a ciudadano</span>
    </button>-->

                    <button mat-raised-button color="accent" class="mt-16"
                        (click)="composeDialog2('emplazar-particular',step)">
                        <span>Notificar a particular</span>
                    </button>

                    <button mat-raised-button color="accent" class="mt-16"
                        (click)="composeDialog2('emplazamiento',step)">
                        <span>Emplazamiento</span>
                    </button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper"
            *ngIf="!isNotificationSala && txtTitleModal=='Emplazar particular'  && bloquen==false">
            <mat-step [stepControl]="placeForm" *ngIf="placeForm && isEmplazarParticular">
                <form fxLayout="column" [formGroup]="placeForm">
                    <ng-template matStepLabel>DATOS DEL CIUDADANO</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input type="text" aria-label="Number" matInput formControlName="auto"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #autocompl #auto="matAutocomplete">
                                <mat-option (onSelectionChange)="_getTitularsByAutoridad()"
                                    *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Apellido paterno</mat-label>
                            <input matInput formControlName="apellPatPart" [disabled]="true" required>
                            <mat-error>Debes ingresar un apellido paterno</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Apellido materno</mat-label>
                            <input matInput formControlName="apellMatPart" [disabled]="true" required>
                            <mat-error>Debes ingresar un apellido materno</mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput formControlName="mail" [disabled]="true">
                            <mat-error>Debes ingresar un correo electronico valido de titular</mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!placeForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="expedientForm">
                <form fxLayout="column" [formGroup]="expedientForm">
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE) o Número de expediente de Órgano
                                Jurisdiccional</mat-label>
                            <input matInput formControlName="acue" [disabled]="true" required>
                            <mat-error>Este campo es obligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification && !isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE) o Número de expediente de Órgano
                                Jurisdiccional</mat-label>
                            <input matInput formControlName="une" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedientex($event)" required>
                            <mat-error *ngIf="expedientForm.get('une').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('une').hasError('required') &&
                                            expedientForm.get('une').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-label *ngIf="isDepaEspecializada">UNE / Expediente / Procedimiento</mat-label>
                            <mat-label *ngIf="!isDepaEspecializada">(UNE)</mat-label>
                            <input matInput formControlName="expe" onkeypress="return (event.charCode < 65)"
                                (blur)="getExpedient('expe')" required>
                            <mat-error *ngIf="expedientForm.get('expe').hasError('required')">
                                Este campo es obligatorio</mat-error>
                            <mat-error *ngIf="!expedientForm.get('expe').hasError('required') &&
                                            expedientForm.get('expe').hasError('pattern')">
                                Debes ingresar un formato de UNE o Número de expediente correcto y sin
                                espacios,ejemplos: 2012-123 ó 123/2012
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="!isAcuerdoNotification || !isFisicNotificationAcue">
                            <mat-select formControlName="depa" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of allDepartaments" value="{{ dep.nume }}">{{ dep.depa }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50"
                            *ngIf="isAcuerdoNotification || isFisicNotificationAcue">
                            <mat-select formControlName="proce" required>
                                <mat-option *ngFor="let item of tiprecu" value="{{ item.handle  }}">
                                    {{item.name}}
                                </mat-option>

                            </mat-select>
                            <mat-label>
                                Tipo De Juicio
                            </mat-label>
                            <mat-error>Debes seleccionar un Tipo de juicio</mat-error>
                        </mat-form-field>
                        <!--<mat-form-field  appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required (selectionChange)="_getUsers($event)">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of departments" value="{{ dep.nume }}">{{ dep.depa }}</mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>-->





                        <h3><em>Partes Procesales</em></h3>
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Actor</mat-label>
                            <input matInput formControlName="part" required>
                            <mat-error>Debes ingresar un nombre de actor</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Demandado</mat-label>
                            <input matInput formControlName="dema" required>
                            <mat-error>Debes ingresar un nombre de demandado</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-label>Nombre Tercero Interesado</mat-label>
                            <input matInput formControlName="terc">
                            <mat-error>Debes ingresar un nombre de tercero interesado</mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!expedientForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="resolutionForm">
                <form fxLayout="column" [formGroup]="resolutionForm">
                    <ng-template matStepLabel>DETERMINACIÓN {{ txtDeterminacion }}</ng-template>

                    <div fxFlex="1 0 auto" fxLayout="column">

                        <mat-form-field appearance="outline" fxFlex>
                            <mat-select formControlName="tipo" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option value="acuerdo">Acuerdo</mat-option>
                                <mat-option value="resolución">Resolución</mat-option>
                                <mat-option value="sentencia">Sentencia</mat-option>
                            </mat-select>
                            <mat-label>
                                Determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-label>
                            <mat-error>Debes seleccionar una determinación {{ txtDeterminacion.toLowerCase() }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFill>
                            <mat-select formControlName="tppa">
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let item of parteTypes" value="{{ item.nume }}">{{ item.name }}
                                </mat-option>
                            </mat-select>

                            <mat-label>
                                Partes a quien va dirigido
                            </mat-label>
                            <mat-error>Debes seleccionar una opción</mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
                            <mat-label>Fecha en que fue dictado</mat-label>
                            <input matInput [matDatepicker]="startDatePicker" formControlName="fecd"
                                (focus)="startDatePicker.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="mr-sm-12" fxFlex
                            *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <mat-label>Fecha de notificación</mat-label>
                            <input matInput [matDatepicker]="startDatePicker2" formControlName="fecn"
                                (focus)="startDatePicker2.open()" required>
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker2></mat-datepicker>
                            <mat-error>Debes ingresar una fecha</mat-error>
                        </mat-form-field>

                        <mat-label *ngIf="isFisicNotification">Hora de notificación</mat-label>
                        <mat-form-field appearance="outline" *ngIf="isFisicNotification || isFisicNotificationAcue">
                            <input matInput placeholder="Simple placeholder" style="display: none;">
                            <ngx-timepicker-field [controlOnly]="true" [format]="24" formControlName="time">
                            </ngx-timepicker-field>
                        </mat-form-field>

                        <div *ngIf="isAcuerdoNotification || isFisicNotificationAcue && agreementData!=null">
                            <a *ngIf="agreementData!=null && agreementData.docu" mat-stroked-button color="accent"
                                (click)="previewFileurl(agreementData.docu)" target="_blank">
                                <!--href="{{agreementData.docu }}" -->
                                Ver acuerdo
                            </a>
                            <a *ngIf="agreementData!=null && agreementData.link" mat-stroked-button color="accent"
                                (click)="previewFileurl(agreementData.link)" target="_blank">
                                <!--href="{{agreementData.docu }}" -->
                                Ver sentencia
                            </a>
                        </div>

                        <div class="file-uploader" fxFlex="1 0 auto"
                            *ngIf="!isAcuerdoNotification || isFisicNotificationAcue" fxLayout="column"
                            fxLayoutAlign="space-envenly start">
                            <mat-label>{{ txtTitleDocs }}</mat-label>


                            <input type="file" #adju id="adju" name="adju" style="display:none;"
                                accept="application/pdf" required />
                            <button mat-raised-button color="info" (click)="setFile('adju')">
                                <mat-icon>backup</mat-icon>
                                <span> Elegir archivo</span>
                            </button>
                            <div *ngIf="resolutionForm.value.adju">

                                <div class="attachment-list"
                                    *ngIf="resolutionForm.value.adju !== null && resolutionForm.value.adju.name !== undefined">
                                    <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div>
                                            <span class="filename">{{ resolutionForm.value.adju.name }}</span>
                                            <!--span class="size ml-4">(12 Kb)</spandaut-->
                                        </div>
                                        <button mat-icon-button aria-label="Delete attachment">
                                            <mat-icon class="s-16" (click)="removeFile('adju')"
                                                tooltip="Quitar archivo">close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <mat-error *ngIf="resolutionForm.get('adju').hasError('required')" class="error">
                                {{ txtErrorFileadju }}</mat-error>
                        </div>

                        <mat-label class="mt-16 mb-2">Anexando al efecto los archivos </mat-label>
                        <div *ngIf="arregloselec2.length!=null">

                            <div *ngFor="let item of arregloselec2">
                                <br>

                                <div *ngFor="let item2 of item"> <br> <a mat-stroked-button color="accent"
                                        (click)="previewFileurl(item2.docu)" target="_blank">
                                        Ver Anexo {{item2.dscr}}
                                    </a> <br>
                                </div>
                            </div>

                        </div>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">

                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" id="{{file.name}}desc" name="{{file.name}}desc"
                                                (keyup)="addDescFile(file.name, in)" maxlength="100" required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;"
                                    accept="application/pdf" required />
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)"
                                    [disabled]="!file.upload">
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFiler(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="previewFiler(file.file, in)" tooltip="Ver archivo">visibility
                                    </mat-icon>
                                    <!--span>Ver archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex
                            *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()"
                                [disabled]="anexosArray.length === 5">
                                <mat-icon>add_circle_outline</mat-icon>
                                <span> Subir otro archivo</span>
                            </button>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!resolutionForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="firmaForm">
                <form fxLayout="column" [formGroup]="firmaForm">
                    <ng-template matStepLabel>FIRMA</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div class="col-sm-3">
                            Cargar archivo FIREL <i class="icono fas fa-upload"></i>
                            <input type="file" id="pfx" accept=".pfx" class="upload-input"
                                (change)="SeleccionarPfx($event.target)" />
                            <div id="divArchivo" class="textoArchivo"></div>
                            <div id="divCargaFirel" class="cargaFirel">
                            </div>
                            <br>

                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>Escriba su contraseña:</mat-label>
                                <!--<input matInput name="keyp" type="password" id="keyp"    [(ngModel)]="password"/><br>-->
                                <input matInput type="password" formControlName="password"
                                    (keyup.enter)="enviar(event)" />
                            </mat-form-field>

                            <!--<button class="btn btn-primary"  [disabled]=!activarBoton id="btnSign"
                            (click)="firmar()">
                                Descargar docs
                            </button>-->
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>

        <mat-vertical-stepper linear="false" #stepper id="stepper" name="stepper"
            *ngIf="isNotificationSala  && bloquen==false">
            <mat-step [stepControl]="resolutionForm">
                <form fxLayout="column" [formGroup]="resolutionForm">
                    <ng-template matStepLabel>EXPEDIENTE</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <mat-form-field appearance="outline" fxFlex="50">
                            <mat-select formControlName="depa" required>
                                <mat-option>-- Selecciona uno --</mat-option>
                                <mat-option *ngFor="let dep of allDepartaments" value="{{ dep.nume }}">{{ dep.depa }}
                                </mat-option>
                            </mat-select>
                            <mat-label>
                                Órgano Jurisdiccional
                            </mat-label>
                            <mat-error>Debes seleccionar un órgano jurisdiccional</mat-error>
                        </mat-form-field>

                        <mat-label class="mt-16 mb-2">Anexos </mat-label>
                        <div class="file-uploader my-16" *ngFor="let file of anexosArray; let in=index;">
                            <div class="columna1">
                                <div class="file-uploader my-16">
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-form-field appearance="outline" fxFlex class="pr-4">
                                            <input matInput type="text" id="{{file.name}}desc" name="{{file.name}}desc"
                                                (keyup)="addDescFile(file.name, in)" maxlength="100" required />
                                            <mat-label>
                                                Descripción del documento
                                            </mat-label>
                                            <mat-hint align="start">Número de fojas: {{ file.numPages }}</mat-hint>
                                            <mat-hint align="end">{{ file.desc.length}} / 100</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxFlex="100">
                                        <mat-error *ngIf="file.error" class="error">{{file.txtError}}</mat-error>
                                    </div>
                                </div>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <input type="file" id="{{file.name}}" name="{{file.name}}" style="display:none;"
                                    accept="application/pdf" required />
                                <button mat-stroked-button color="info" (click)="setFiler(file.name, in)"
                                    [disabled]="!file.upload">
                                    <mat-icon>backup</mat-icon>
                                    <span> {{ file.txtBtnUp }}</span>
                                </button>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" class="mt-20" fxFlex>
                                <button mat-mini-fab aria-label="Delete attachment" *ngIf="file.file">
                                    <mat-icon (click)="removeFile(in)" tooltip="Quitar archivo">close</mat-icon>
                                    <!--span>Quitar archivo</span-->
                                </button>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex
                            *ngIf="showMoreFileBtn && anexosArray.length < 5">
                            <button mat-stroked-button color="accent" (click)="addOther()"
                                [disabled]="anexosArray.length === 5">
                                <mat-icon>add_circle_outline</mat-icon>
                                <span> Subir otro archivo</span>
                            </button>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button matStepperNext type="button" color="accent"
                            [disabled]="!resolutionForm.valid">
                            Continuar
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="firmaForm">
                <form fxLayout="column" [formGroup]="firmaForm">
                    <ng-template matStepLabel>FIRMA</ng-template>
                    <div fxFlex="1 0 auto" fxLayout="column">
                        <div class="col-sm-3">
                            Cargar archivo FIREL <i class="icono fas fa-upload"></i>
                            <input type="file" id="pfx" accept=".pfx" class="upload-input"
                                (change)="SeleccionarPfx($event.target)" />
                            <div id="divArchivo" class="textoArchivo"></div>
                            <div id="divCargaFirel" class="cargaFirel">
                            </div>
                            <br>

                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>Escriba su contraseña:</mat-label>
                                <!--<input matInput name="keyp" type="password" id="keyp"    [(ngModel)]="password"/><br>-->
                                <input matInput type="password" formControlName="password"
                                    (keyup.enter)="enviar(event)" />
                            </mat-form-field>

                            <!--<button class="btn btn-primary"  [disabled]=!activarBoton id="btnSign"
                            (click)="firmar()">
                                Descargar docs
                            </button>-->
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>
        <!--/div-->
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div *ngIf="!isNotificationSala">
            <button mat-raised-button color="accent" (click)="sendMail()" class="save-button"
                [disabled]="validInactiveSendBtn() || isLoading" aria-label="SAVE">
                Enviar
                <div fxLayout="column" fxLayoutAlign="center center " *ngIf="isLoading">
                    <mat-progress-spinner diameter="25" mode="indeterminate" [value]="valueLoading">
                    </mat-progress-spinner>
                </div>
            </button>
            <mat-error *ngIf="txtErrorNoti != ''">{{txtErrorNoti}}</mat-error>
        </div>
        <div *ngIf="isNotificationSala">
            <button mat-raised-button color="accent" (click)="sendMail()" class="save-button"
                [disabled]="validInactiveSendBtn() || isLoading" aria-label="SAVE">
                Enviar
                <div fxLayout="column" fxLayoutAlign="center center " *ngIf="isLoading">
                    <mat-progress-spinner diameter="25" mode="indeterminate" [value]="valueLoading">
                    </mat-progress-spinner>
                </div>
            </button>
            <mat-error *ngIf="txtErrorNoti != ''">{{txtErrorNoti}}</mat-error>
        </div>
        <button mat-icon-button (click)="matDialogRef.close(['delete',[expedientForm, resolutionForm, usersForm]])"
            aria-label="Delete" matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>