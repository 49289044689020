export let message = "";
export let PKCS7creado="";
export let extFileUpload="";

//Librerias JS terceros
//declare const forge:any;
//declare const KJUR:any;
declare const forge:any;
declare const KJUR:any;

export function resetFlagsSignature(){
  message = "";
  PKCS7creado = "";
  extFileUpload = "";
}

export function SelectFileSign (ctrlFile:any):Promise<boolean> {
        
  try{        
      var file = ctrlFile;
      //var file = ctrlFile.files[0];
      if (file != null) {
          let archivo = file.name;
          extFileUpload= archivo.split('.')[1];   
      }
      //console.log("file cliente", file);
      var fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      //console.log("fileReader", fileReader);
      return new Promise((resolve,reject)=>{

      fileReader.onload = (e) => {
          var bufferFile = e.target.result;       
          //var bufferFile = 2021;
          message = ConvertToHex(bufferFile);
          //console.log("message hex", message);
          //message = '1963';
       
         if (message!='') {
          resolve(true);
        }
        resolve(false);
      };

  });

  }
  catch (error) {
      console.error(error);
  }
}
/*export function SelectFileSign (ctrlFile:any):Promise<boolean> {
        
    try{        
        var file = ctrlFile.files[0];

        if (file != null) {
            let archivo = file.name;
            extFileUpload= archivo.split('.')[1];   
        }

        var fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        return new Promise((resolve,reject)=>{

        fileReader.onload = (e) => {
            var bufferFile = e.target.result;       
            message= ConvertToHex(bufferFile);
         
           if (message!='') {
            resolve(true);
          }
          resolve(false);
        };

    });

    }
    catch (error) {
        console.error(error);
    }
}*/

export function SelectPfx(pfxFile:any):boolean {
      if (pfxFile.files[0]!= null) {  
        var archivo = pfxFile.files[0].name;
        var ext = archivo.split('.')[1];    
        if (ext != 'pfx') {
            console.info('Seleccione un archivo FIREL válido (.pfx)');
            return false;      
        }            
        
        if(message != ""){    
          //console.log("message", message);
          return true;
        }
        
        
    } else {
      return false;
    }
}

export function OpenPfx(ctrlFile:any, password?:any):Promise<boolean> {

    try {


      //Se Lee el archivo pfx
      var file = ctrlFile.files[0];
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
  
      return new Promise((resolve,reject)=>{
      reader.onload = (e) => {
        try{
        var bufferFile = e.target.result;
        var pkcs12Der = forge.util.binary.raw.encode(new Uint8Array(<ArrayBuffer>bufferFile));
        var p12Asn1 = forge.asn1.fromDer(pkcs12Der);
        let _contentFileP12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);
  
        //Se Obtienen La llave Privada y el Certificado del Archivo pfx
        var certBags = _contentFileP12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = _contentFileP12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
        var certBag = certBags[forge.pki.oids.certBag][0];
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];

        var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        var certPEM = forge.pki.certificateToPem(certBag.cert);
     

        privateKeyPem = privateKeyPem.replace(/\r\n/g, '\n');
        certPEM = certPEM.replace(/\r\n/g, '\n');
        
        PKCS7creado=CreatePKCS7(certPEM, privateKeyPem, message);
        //console.log("pkcs7 creado", PKCS7creado);
        if (PKCS7creado!='') {
            resolve(true);
          }
          reject(false);
        }catch(error){
          console.log("error catch signature service", error);
          reject(true);
        }
      };
    });
      
    }
    catch (error) {
      console.error(error);
    }
  }

  
function CreatePKCS7(certPEM:any, privateKeyPEM?:any, message?:any):string {
    
    var param = {
      content: { hex: message },
      certs: [certPEM],
      signerInfos: [{
        hashAlg: 'sha256',
        sAttr: {
            SigningTime: {}
        },
        signerCert: certPEM,
        sigAlg: 'SHA256withRSA',
        signerPrvKey: privateKeyPEM
      }]
    };  
 
    try {
     
      var signedData = KJUR.asn1.cms.CMSUtil.newSignedData(param);
      //console.log(signedData);
      var pkcs7  = RemoveCMSHeader(signedData.getPEM()); 
   
     return pkcs7;
  
    } catch (error) {
        console.error(error);
    }
  }

  function RemoveCMSHeader(cms:any):string{
    var pkcs7 = ""
    try{
        pkcs7 = cms.replace("-----END CMS-----", "").replace("-----BEGIN CMS-----", "").replace(/(\r\n|\n|\r)/gm, "");
    }
    catch (error) {
        console.error(error);
    }    
    return pkcs7;
}

function ConvertToHex(arrayBuffer:any):string
{
    const buff = new Uint8Array(arrayBuffer);
    const hexOctets = []; 

    for (let i = 0; i < buff.length; ++i)
        hexOctets.push(byteToHex[buff[i]]);

    return hexOctets.join("");
}

const byteToHex = [];

for (let n = 0; n <= 0xff; ++n)
{
    const hexOctet = n.toString(16).padStart(2, "0");
    byteToHex.push(hexOctet);
}