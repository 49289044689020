import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { fuseAnimations } from '@fuse/animations';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Router } from '@angular/router';
import { adminMenu, actuaryMenuDashboard, streamMenu, secretaryMenu, authorityMenu, authorityAdminMenu } from "../../providers/data/menu/menu-data";
import { GuidedTour, Orientation, GuidedTourService } from 'ngx-guided-tour';
import { UsersProvider } from 'app/providers/users.provider';
import { tourSecretary, tourCitizen, tourActuary } from './tour-data';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailComposeDialogComponent } from '../notifications/mail/dialogs/compose/compose.component';
import { InformationModalComponent } from './information-modal/information-modal.component'
import * as CryptoJS from 'crypto-js';
import { MenuService } from 'app/providers/data/menu/menu.service';
import { DocumentProvider } from 'app/providers/documents.register.provider';

@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations : fuseAnimations

})
export class SampleComponent implements OnInit
{
    boards: any[] = [];

    currentUser:any;
    typeEmployee:any;
    dialogRef: any;

    title = 'ngx-guided-tour-demo';

    public dashboardTour: GuidedTour = {
        tourId: 'purchases-tour',
        skipCallback: (stepSkippedOn: number) => this.tutorialComplete(),
        completeCallback: () => this.tutorialComplete(),
        steps: []
    }
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _usersService: UsersProvider,
        private _settings: AppConfigService,
        private guidedTourService: GuidedTourService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        public _matDialog: MatDialog,
        private _menuService: MenuService,
        private _documentService: DocumentProvider,

    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.currentUser = this._settings.getCurrentUser();
        this.typeEmployee  = this.currentUser ? this.currentUser.tipo : undefined;
    }

    ngOnInit(){
        if(this.typeEmployee !== undefined) {
            if(this.typeEmployee === 'empleado') {
                this.typeEmployee = this._settings.getTypeEmployee();
            }
            this.getBoardCurrent();
            this.getTutorialData(this.typeEmployee);
            if(!this.currentUser.tuto) {
                setTimeout(() => {
                    this.guidedTourService.startTour(this.dashboardTour);
                }, 800);
            }else {
                this.openModalInformation();
            }
        }else {
            this._router.navigate(['/']);
        }
        
    }

    async getBoardCurrent () {
        const result: any = await this.getBoard();
        console.log("result board", result);
        this.boards = result || [];
    }

    getBoard(){
        var promise = new Promise((resolve, reject) => {

            let options = []
            setTimeout(() => {
                let menu: any = this._menuService.getMenu();

                if (menu.length === 0){ return [] }
                options = menu[0].children.reduce((acc, currenItem, index) => {
                    
                    if(index > 0 && acc){
                        if(currenItem.hasOwnProperty('children')){
                            const moreOptions = currenItem.children.map(option => {
                                return {
                                    id:   option.id,
                                    name: option.title, 
                                    path: option.url, 
                                    icon: option.icon
                                }
                            })
                            return [...acc, ...moreOptions]
                        }else {
                            return [...acc,{
                                id:   currenItem.id,
                                name: currenItem.title, 
                                path: currenItem.url, 
                                icon: currenItem.icon
                            }] 
                        }
                    }
                    return [];
                }, []);
                resolve(options);
            }, 1);
        });
        return promise;
    }
    
    getTutorialData(typeUser){
        let tour: any = [];
        switch (typeUser) {
            case 'colaborador':
            case 'superadmin':
                tour = (tourSecretary);
                break;
            case 'Actuario':
                tour = (tourActuary);
                break;
            case 'Secretario de acuerdos':
                tour = (tourSecretary);
                break;
            case 'ciudadano':
                tour = (tourCitizen);
                break;
            case 'autoridad':
                tour = (tourCitizen);
                break;
        }
        this.dashboardTour.steps = tour;
    }

    tutorialComplete() {
        this.openModalInformation();
        this._usersService.setTutorialComplete(this.currentUser.nume).subscribe(
            (result) => {
                const save_user = this._settings.toEncrypt(JSON.stringify(result['data']));
                sessionStorage.setItem('usr', save_user.toString());
            },
            (error) => console.error(error)
        );
    }

    navigateTo(board){
        if(board.id === 'electronica') {
            this.composeDialog('');
        }else if ( board.id === 'fisica' ){
            this.composeDialog(board.id);
        }else {
            this._router.navigate([board.path])
        }

    }

    composeDialog(typeNotification): void
    {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog',
            data      : {
                typeNotification: typeNotification
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const messageApi: string = response[1];
                switch ( actionType )
                {
                    /**
                     * Send
                     */
                    case 'send':
                        // Show the success message
                        this._matSnackBar.open(messageApi, 'OK', {
                            verticalPosition: 'top',
                            horizontalPosition: 'right',
                            duration        : 3000
                        });
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        break;
                }
            });
    }

    openModalInformation(){
      //aqui va consulta back para guardia
      this._documentService.getcalendarioguard().subscribe(
        (response)=>{
            if(response !=null){
                this.dialogRef = this._matDialog.open(InformationModalComponent, {panelClass: ''});
            }

        }
      )
      if(this._settings.getHaveGuard()){
            this.dialogRef = this._matDialog.open(InformationModalComponent, {panelClass: ''});
        }
    }
}
