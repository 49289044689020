import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './data/app-config.service';
import { TypeFile } from "../main/promotions/demanda-form/options.data.form";
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DocumentProvider {
    private urlPromotions : string;
    private urlAgreements : string;
    private urlAsignAgreement: string;
    private urlEmployee: string;
    private urlPublicFiles: string;
    private urlConvertFiles: string;
    private urlConvertFilesArray: string;
    private urlConvertFilesPromo: string;
    constructor(private http: HttpClient, private settings: AppConfigService) {
        this.urlPromotions  = this.settings.getUrlApi() + 'promotions';
        this.urlAgreements  = this.settings.getUrlApi() + 'agreements';
        this.urlAsignAgreement = this.settings.getUrlApi() + 'asign-agreement';
        this.urlPublicFiles = this.settings.getUrlApi() + 'files_sign';
        this.urlConvertFiles = this.settings.getUrlApi() + 'convert-file';
        this.urlConvertFilesArray = this.settings.getUrlApi() + 'convert-array-file';
        this.urlConvertFilesPromo = this.settings.getUrlApi() + 'convert-file-promotion';
        this.urlEmployee = this.settings.getUrlApi() + 'employees/';
    }
    
    registers(credentials) {
        const  haciendoalgo = (resolve, reject) => {
            if (credentials.une !== '') {
                setTimeout(() =>{
                    resolve("Stuff worked!");
                    console.log('terminado!!')
                }, 5000);
            }
            else {
                reject(Error("It broke"));
            }
        }
        return new Promise( haciendoalgo );
    }

    asigAgreementToAuxiliar(data){
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.post(this.urlAsignAgreement , data, optionHeaders);
    }

    deniedAgreementAux(nume, empenume){
        let optionHeaders = this.settings.getHeadersJson();
        let data = null;
        return this.http.post(this.urlAgreements+'/'+nume+'/'+empenume+'/denied-aux', data, optionHeaders);
    }

    updatePromotionData(empe, nume, data){
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.put(this.urlEmployee+empe+'/promotions/'+nume , data, optionHeaders);
    }

    registerDocument(dataDocument) {
        let optionHeaders   = this.settings.getHeadersMultipart();
        return this.http.post(this.urlPromotions , dataDocument, optionHeaders);
    }
    
    getDocumentsUploaded(promotion_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlPromotions + `/${ promotion_id }/promotion-documents`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    getDocumentsUploaded2(promotion_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlPromotions + `/${ promotion_id }/promotion-documents2`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    setAgreements(dataPromotion) {
        let optionHeaders   = this.settings.getHeadersMultipart();
        const url = this.settings.getUrlApi() + 'agreements';
        return this.http.post(this.urlAgreements , dataPromotion, optionHeaders);
    }
    getVotoDocuments2(nume){
        let optionHeaders = this.settings.getHeadersMultipart();
        let urlApi = this.settings.getUrlApi() + 'sentences/';
        return this.http.get(urlApi+nume+'/documents2', optionHeaders);
    }
    updateAgreement(dataPromotion, id){
        let optionHeaders = this.settings.getHeadersMultipart();
        const url = this.settings.getUrlApi() + 'employees/';
        return this.http.post(url+id+'/agreement-update', dataPromotion, optionHeaders);
    }
    updateAgreement2(dataPromotion, id){
        let optionHeaders = this.settings.getHeadersMultipart();
        const url = this.settings.getUrlApi() + 'employees/';
        return this.http.post(url+id+'/agreement-update2', dataPromotion, optionHeaders);
    }
    getPromotionsByAgreement(agreement_id) {
        const optionHeaders   = this.settings.getHeadersJson();
        const documentsUrl = this.urlAgreements + `/${ agreement_id }/promotions`;

        return this.http.get(documentsUrl , optionHeaders);
    }
    getPromotionsByAgreementpromotions(agreement_id) {
        let optionHeaders = this.settings.getHeadersMultipart();
        let urlApi = this.settings.getUrlApi() + 'sentences/';
        return this.http.get(urlApi+agreement_id+'/getsentencia', optionHeaders);
    }
    getcalendarioguard() {
        let optionHeaders = this.settings.getHeadersMultipart();
        let urlApi = this.settings.getUrlApi();
        return this.http.get(urlApi+'getcalendario', optionHeaders);
    }
    getUrlDoc(event) {
        let optionHeaders = this.settings.getHeadersMultipart();
        let urlApi = this.settings.getUrlApi();
        return this.http.post(urlApi+'geturldoc', event,optionHeaders);
    }
    getTypeDocument(uid_doc: string) {
        const tipo = TypeFile.find(type => type.uid === uid_doc);
        if(tipo !== undefined) {
            return tipo.name;
        } else {
            return uid_doc;
        }
    }

    //get file to sign with electronic signature
    getDocumentBlob(link): Observable<any> {
        let headers = new HttpHeaders()
        headers = headers.set("Accept", "application/pdf")
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        return this.http.get(link, { headers: headers, responseType: "blob" })
    }
    
    //get files to sign with electronic signature
    getPublicFiles(folder, file){
        let optionHeaders = this.settings.getHeadersBlob();
        return this.http.get(this.urlPublicFiles+'/'+folder+'/'+file, {headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')), responseType: 'arraybuffer'},);
    }

    getFileConverted(folder, file){
        return this.http.get(this.urlConvertFiles+'/'+folder+'/'+file, {headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')), responseType: 'arraybuffer'},);
    }

    converFile(data){  
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlConvertFiles, data, optionHeaders);
    }

    converArrayFile(data){
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post(this.urlConvertFilesArray, data, optionHeaders);
    }

    converFilePromo(data){  
        let optionHeaders = this.settings.getHeadersMultipart();
        return this.http.post<Blob>(this.urlConvertFilesPromo, data, {headers:optionHeaders.headers, observe: 'response', responseType: 'blob' as 'json' });

       
    }

}