import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.model';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
//@Injectable()
@Injectable({providedIn: 'root'})
export class AppConfigService {
    private appConfig: AppConfig = new AppConfig();

    getUrlApi() {
        return this.appConfig.url;
    }

    getUrlTutorialCitizen() {
        return this.appConfig.tutorialCitizen;
    }

    getUrlTutorialAuthority() {
        return this.appConfig.tutorialAutority;
    }
    
    getHaveGuard() {
        return this.appConfig.haveGuardia;
    }

    getUrlFirel(){
        return this.appConfig.urlFirel
    }
    
    getInformationGuard() {
        return this.appConfig.informationGuard;
    }

    getHeadersJson() {
        return {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        };
    }

    getHeadersBlob() {
        return {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')),
            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',
           // have to explicitly give as 'blob' or 'json'
            responseType: 'blob' as 'blob'  
        };
    }

    getHeadersMultipart() {
        return {
            headers: new HttpHeaders({                       
                            'Authorization': 'Bearer ' + sessionStorage.getItem('tk')
                        })
        };
    }

    getTokenString() {
        return 'Bearer ' + sessionStorage.getItem('tk');
    }
    
    getCurrentUser(){
        if(sessionStorage.getItem('usr') !== null) {
            return this.getInfoEncrypt(sessionStorage.getItem('usr'));
        }
    }

    getInfoUserSystem(){
        const user = sessionStorage.getItem('tyus');
        if(user !== null) {
            return this.getInfoEncrypt(user);
        }
    }

    validIsEmployeAdmin() {
        const tipo = this.getCurrentUser().tipo;
        if(tipo === 'superadmin') {
            return true
        } else if (tipo === 'empleado') {
            const dataEmployee = this.getTypeEmployee();
            if (dataEmployee === 'Asesor ciudadano') {
                return false;
            }
            return true;
        }
        
        return false;
    }

    capitalize_Words(str){
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0) == 'd' ? txt : txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    getNameTypeUser(){
        if(sessionStorage.getItem('usr') !== null) {
            const currentUser = this.getInfoEncrypt(sessionStorage.getItem('usr'));
            const depaUser = this.getDepaNameEmployee();
            if(currentUser.tipo === 'ciudadano' || currentUser.tipo === 'autoridad') {
                return  currentUser.tipo;
            }else {
                return  this.getTypeEmployee();
            }
        }
        return 'typeUser';
    }

    getTypeEmployee(){
        if(sessionStorage.getItem('tyem') !== null) {
            return sessionStorage.getItem('tyem');
        }
        return '';
    }

    getDepaEmployee(){
        if(sessionStorage.getItem('em') !== null) {
            const employee =  this.getInfoEncrypt(sessionStorage.getItem('em'));
            return employee.nume;
        }
    }

    getDepaNameEmployee(){
        if(sessionStorage.getItem('em') !== null) {
            const employee =  this.getInfoEncrypt(sessionStorage.getItem('em'));
            return employee.depa;
        }
        return '';
    }

    getDateFormat(date){
        moment.locale('es-Mx');
        return moment(date).format('DD-MM-YYYY h:mm:ss') //'MMMM Do YYYY, h:mm:ss a'
    }

    getInfoEncrypt(data){
        //const decr = CryptoJS.AES.decrypt(data,'aS35LwFU?}{0').toString(CryptoJS.enc.Utf8);
        //return JSON.parse(decr);
        return JSON.parse(data)
    }

    toEncrypt(data) {
        //return CryptoJS.AES.encrypt(data,'aS35LwFU?}{0');
        return data;
    }

    formatUneExpediente(identification) {
        let char_split = '';
        if(identification.includes('-')) {
            char_split = '-'
        }else if(identification.includes('/')) {
            char_split = '/'
        }else {
            return 'false'
        }

        const elements = identification.split(char_split);
        
        return elements[0].trim() + char_split + elements[1].trim(); 
    }

    _getMsjError(error){
        error = error.error.error;

        if(typeof error === 'object'){
            for (const field in error) {
                if (Object.prototype.hasOwnProperty.call(error, field)) {
                    return error[field]; 
                }
            }
        }
        
        return error
    }
}
