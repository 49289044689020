import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { AppConfigService } from './data/app-config.service';

@Injectable({providedIn: 'root'})
export class ResourcesProvider {
    private urlApi = '';
    constructor(private httpClient: HttpClient, private settings: AppConfigService) {
        this.urlApi = this.settings.getUrlApi();
    }

    getCurpData(data){
        const httpOptions = this.settings.getHeadersJson();
        this.urlApi = this.settings.getUrlApi();
        return this.httpClient.get(this.urlApi+'curp?curp='+ data, httpOptions);
    }

    getJobs(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'jobs', httpOptions);
    }

    getEntities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'entities', httpOptions);
    }

    getDirections(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'directions', httpOptions);
    }
    
    getDepartments(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'departments', httpOptions);
    }

    getMunicipalities(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'municipalities', httpOptions);
    }

    getMunicipalitiesRegister(){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'municipalities', httpOptions);
    }

    getNamesRegister(id_municipio, tipo){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `entities?muni=${id_municipio}&tipo=${tipo}`, httpOptions);
    }

    getEntitiesPart(tipo){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `entities?tipo=${tipo}`, httpOptions);
    }
    
    getTitularAutoridad(id_authoritie){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `entities/${id_authoritie}/users`, httpOptions);
    }
    
    getUsersAutoridad(id_authoritie){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `authorities/${id_authoritie}/users`, httpOptions);
    }

    getAuthoritieById(id_authoritie){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + 'authorities/' + id_authoritie, httpOptions);
    }

    getUserByExpediente(params){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi + `registrations?stat=aceptado&folio=${params.folio}&depa=${params.depa}`)
    }

    getDocByUrl(url){
        const httpOptions = this.settings.getHeadersBlob(); 
        return this.httpClient.get(url, httpOptions)
    }

    getCaracterReconocido(type) {
        const types = [
                {value:"parte", label: "Actor"},
                {value:"tercero", label: "Tercer interesado"},
                {value:"apoderado legal", label: "Apoderado legal"},
                {value:"autorizado", label: "Autorizado"},
                {value:"representante legal", label: "Representante legal"}, 
                {value:"autoridad", label: "Autoridad demandada"}        
            ]
        const typeFound = types.find(t => t.value === type);
        if(typeFound !== undefined) {
            return  typeFound.label;
        }

        return type;
    }
    tiporecurso(){
        let types = [
    //nulidad nuevos
    {nume:18 ,name:'Acción popular', handle:'accion_popular', type:'nulidad', depa:'regional'},
    //nulidad nuevos
    //{nume:1, name: 'Conflicto de Competencia entre Salas Regionales', handle:'conflicto', type: 'nulidad', depa:'superior'},
    //{nume:14,name:'Cuadernillo de Amparo Sala Regional', handle:'cuadernillo_regional', type:'nulidad', depa:'regional'},
    {nume:15,name:'Conflicto de competencia', handle:'conflicto_competencia', type:'nulidad', depa:'superior'},
    {nume:2,name:'Cuadernillo de Amparo en Sala Superior', handle:'cuadernillo_superior', type:'nulidad', depa:'superior'},
    {nume:17 ,name:'Cuadernillo de antecedentes de expediente', handle:'cuadernillo_antecedentes_expediente', type:'nulidad', depa:'regional'},

    {nume:3,name:'Cuaderno auxiliar', handle:'cuaderno', type:'nulidad', depa:'ambos'},
    {nume:4,name:'Cumplimiento de Sentencia en Sala Superior', handle:'cumplimiento', type:'nulidad', depa:'superior'},
    {nume:5,name:'Excitativa de Justicia', handle:'excitativas', type:'nulidad', depa:'superior'},
    {nume:16,name:'Excusa por impedimento', handle:'excusa_impedimento', type:'nulidad', depa:'superior'},

    {nume:6,name:'Expediente de varios', handle:'expediente', type:'nulidad', depa:'ambos'},
    {nume:8,name:'Juicio Administrativo', handle:'juicio_administrativo', type:'nulidad', depa:'regional'},
    {nume:7,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
    //{nume:9,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
    {nume:10,name:'Juicio Sumario', handle:'juicio_sumario', type:'nulidad', depa:'regional'},
    
    {nume:11,name:'Procedimiento de Responsabilidad Administrativa por Faltas Graves', handle:'responsabilidad_administrativa_falta_grave', type:'nulidad', depa:'regional'},
    {nume:12,name:'Recurso de Apelación', handle:'recurso_apelacion', type:'nulidad', depa:'superior'},
    {nume:14,name:'Recurso de Inconformidad', handle:'recurso_inconformidad', type:'nulidad', depa:'regional'},
    {nume:13,name:'Recurso de Reclamación ', handle:'recurso_reclamacion', type:'nulidad', depa:'regional'},
    {nume:19,name:'Recurso de Revisión', handle:'recurso_revision', type:'nulidad', depa:'superior'},
     ];
        return types;    
    }
    getCaracterReconocidoAu() {
        let types = [
            {value:"autoridad", label: "Autoridad demandada"},       
            {value:"representante legal", label: "Representante legal"}, 
            {value:"apoderado legal", label: "Apoderado legal"},
            {value:"tercero", label: "Tercer interesado"},
            {value:"parte", label: "Actor"},

            ];
        return types;
    }
    getCaracterReconocidoByType(to = 'promotions') {
        let caracter =  [
                {value:"parte", label: "Actor"},
                {value:"tercero", label: "Tercer interesado"},
                {value:"apoderado legal", label: "Apoderado legal"},
                {value:"representante legal", label: "Representante legal"},
            ]
        if(this.settings.getCurrentUser().tipo === 'autoridad') {
            //caracter.splice(1,1);
            caracter = [...caracter, {value:"autoridad", label: "Autoridad demandada"} ].reverse()
        }else if(to === 'solicitud'){
            caracter = [...caracter, {value:"autorizado", label: "Autorizado"} ]
        }
        return caracter;
    }

    capitalize_Words(str){
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    getDesignersByDepartment(depa){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi+'employees/'+depa+'/designers', httpOptions);
    }

    getAuxiliarByDepartment(depa){
        const httpOptions = this.settings.getHeadersJson();
        return this.httpClient.get(this.urlApi+'employees/'+depa+'/auxiliars', httpOptions);
    }

    /**
     * Serivio correccion sentencia
     */
     correctionAgreement(nume, data){
        let optionHeaders = this.settings.getHeadersMultipart();
        let urlApi = this.settings.getUrlApi() + 'sentences/'+nume+'/denied';
        return this.httpClient.post(urlApi, data, optionHeaders);
    }

    /**
     * Obtener promocion por nume
     */
    promotionByNume(nume){
        const httpOptions = this.settings.getHeadersJson();
        let urlApi = this.settings.getUrlApi()+'promotions/'+nume;
        return this.httpClient.get(urlApi, httpOptions);
    }
    promotionByUne(Une){
        console.log("busqueda",Une);
        var body:any = {
            "une":Une,
            };
        const httpOptions = this.settings.getHeadersJson();
        let urlApi = this.settings.getUrlApi()+'promotionsUne/'+Une;

        return this.httpClient.post(urlApi, body,httpOptions);
        
    }
}


//type procedures sentence
export const TypeChildProceduresSentence = [
    
    {nume: 1, name: 'Juicio de nulidad/lesividad'},
    {nume: 2, name: 'Juicio de responsabilidad administrativa'},
    {nume: 3, name: 'Recursos'},
    {nume: 4, name: 'Incidentes'},
    
]

//type child resource sentence
export const TypeChildResourceSentence = [
    {nume: 1, name: 'Recurso de queja'},
    {nume: 2, name: 'Recurso de reclamación'},
    {nume: 3, name: 'Recurso de revisión'},
    {nume: 4, name: 'Recurso de apelación'},
]

//type incident sentence
export const TypeChildIncidentSentence = [
    {nume: 1, name: 'Incidente de acumulación de autos'},
    {nume: 2, name: 'Incidente de nulidad de notificaciones'},
    {nume: 3, name: 'Incidente de interrupción del procedimiento por muerte o por disolución en el caso de las personas morales'},
    {nume: 4, name: 'Incidente de incompetencia'},
    {nume: 5, name: 'Incidente de aclaracion de sentencia'},
    {nume: 6, name: 'Incidente de liquidación'},
    {nume: 7, name: 'Incidente de cumplimiento sustituo'},
    {nume: 8, name: 'Incidente de falsedad de documentos'},
]

//type judg sentence
export const TypeJudgSentence = [
    {nume:1, handle: 'nulidad', name: 'Nulidad/lesividad'},
    {nume:2, handle: 'grave', name:'Responsabilidad administrativa grave'}
]

//Type sentences
export const TypeSentence = [
    {handle: 'resolucion', name: 'Resolución'},
    {handle: 'sentencia', name: 'Sentencia'},
    {handle: 'convenio', name: 'Convenio'}
]

//Type sentido sentences
export const TypeSentidoSentence = [
    {nume: 1, name: 'Validez'},
    {nume: 2, name: 'Invalidez'},
    {nume: 3, name: 'Sobreseimiento'},
    {nume: 4, name: 'Desechamiento'},
    {nume: 5, name: 'Confirma'},
    {nume: 6, name: 'Revoca'},
    {nume: 7, name: 'Modifica'},
    {nume: 8, name: 'Confirma el sentido sobre nuevas argumentaciones'},
    {nume: 9, name: 'Sin materia'},
    {nume: 10, name: 'Fundado (a)'},
    {nume: 11, name: 'Por cumplida'},
    {nume: 12, name: 'No se tiene por cumplida'},
    {nume: 13, name: 'Infundado (a)'},
    {nume: 14, name: 'Procedente'},
    {nume: 15, name: 'Improcedente'},
    /* tipo de subsentidos antes
    //grave
    {nume: 1, to:'grave', type:'def' , name: 'Devolución falta administrativa no grave'},
    //end grave
        //interlocutorias
        {nume: 2, to:'nulidad grave', type:'int' , name: 'Confirma'},
        {nume: 3, to:'nulidad grave', type:'int' , name: 'Modifica'},
        {nume: 4, to:'nulidad grave', type:'int' , name: 'Revoca'},
        //end interlocutorias
    {nume: 5, to:'nulidad grave', type:'def int', name: 'Sobreseimiento'},
     //grave
    {nume: 6, to:'grave', type:'def' , name: 'Con responsabilidad'},
    {nume: 7, to:'grave', type:'def' , name: 'Sin responsabilidad'},
     //end grave
        //nulidad/lesividad
        {nume: 8, to:'nulidad', type:'def' , name: 'Nulidad e invalidez'},
        {nume: 9, to:'nulidad',type:'def' , name: 'Validez'},
        //end nulidad/lesividad
    //interlocutorias
    {nume: 10, to:'nulidad grave', type:'int' , name: 'Procedente'},
    {nume: 11, to:'nulidad grave', type:'int' , name: 'Improcedente'},
    //end interlocutorias
    */
]

export const TypeVotations = [
    {nume: 1, name: 'Unanimidad'},
    {nume: 2, name: 'Mayoría'},
    {nume: 3, name: 'Returno por no alcanzar la mayoría'},
    {nume: 4, name: 'Retiro para nuevo estudio'}
]

//const type judgment nulidad
export const ListadoAcuerdosSalas = [
    {nume: 1, name: 'Primera Sala Regional Toluca'},
    {nume: 2, name: 'Segunda Sala Regional Naucalpan de Juárez'},
    {nume: 3, name: 'Tercera Sala Regional Tlalnepantla de Baz'},
    {nume: 4, name: 'Cuarta Sala Regional Ecatepec de Morelos'},
    {nume: 5, name: 'Quinta Sala Regional Nezahualcóyotl'},
    {nume: 6, name: 'Sexta Sala Regional Atizapán de Zaragoza'},
    {nume: 7, name: 'Séptima Sala Regional Toluca'},
    {nume: 8, name: 'Octava Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de Toluca'},
    {nume: 9, name: 'Novena Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de México'},
    {nume: 10,name:  'Primera Sección de la Sala Superior Toluca'},
    {nume: 11,name:  'Segunda Sección de la Sala Superior Tlalnepantla de Baz'},
    {nume: 12,name:  'Tercera Sección de la Sala Superior Ecatepec de Morelos'},
    {nume: 13,name:  'Cuarta Sección de la Sala Superior en Materia de Responsabilidades Administrativas Toluca'},
];
export const TypeJudgmentNulidad2 = [
    //nulidad nuevos
    {nume:18 ,name:'Acción popular', handle:'accion_popular', type:'nulidad', depa:'regional'},
    //nulidad nuevos
    //{nume:1, name: 'Conflicto de Competencia entre Salas Regionales', handle:'conflicto', type: 'nulidad', depa:'superior'},
    //{nume:14,name:'Cuadernillo de Amparo Sala Regional', handle:'cuadernillo_regional', type:'nulidad', depa:'regional'},
    {nume:15,name:'Conflicto de competencia', handle:'conflicto_competencia', type:'nulidad', depa:'superior'},
    {nume:2,name:'Cuadernillo de Amparo en Sala Superior', handle:'cuadernillo_superior', type:'nulidad', depa:'superior'},
    {nume:17 ,name:'Cuadernillo de antecedentes de expediente', handle:'cuadernillo_antecedentes_expediente', type:'nulidad', depa:'regional'},

    {nume:3,name:'Cuaderno auxiliar', handle:'cuaderno', type:'nulidad', depa:'ambos'},
    {nume:4,name:'Cumplimiento de Sentencia en Sala Superior', handle:'cumplimiento', type:'nulidad', depa:'superior'},
    {nume:5,name:'Excitativa de Justicia', handle:'excitativas', type:'nulidad', depa:'superior'},
    {nume:16,name:'Excusa por impedimento', handle:'excusa_impedimento', type:'nulidad', depa:'superior'},

    {nume:6,name:'Expediente de varios', handle:'expediente', type:'nulidad', depa:'ambos'},
    {nume:8,name:'Juicio Administrativo', handle:'juicio_administrativo', type:'nulidad', depa:'regional'},
    {nume:7,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
    //{nume:9,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
    {nume:10,name:'Juicio Sumario', handle:'juicio_sumario', type:'nulidad', depa:'regional'},
    
    {nume:11,name:'Procedimiento de Responsabilidad Administrativa por Faltas Graves', handle:'responsabilidad_administrativa_falta_grave', type:'nulidad', depa:'regional'},
    {nume:12,name:'Recurso de Apelación', handle:'recurso_apelacion', type:'nulidad', depa:'superior'},
    {nume:14,name:'Recurso de Inconformidad', handle:'recurso_inconformidad', type:'nulidad', depa:'regional'},
    {nume:13,name:'Recurso de Reclamación ', handle:'recurso_reclamacion', type:'nulidad', depa:'regional'},
    {nume:19,name:'Recurso de Revisión', handle:'recurso_revision', type:'nulidad', depa:'superior'},
    
]
export const TypeJudgmentNulidad = [
     //nulidad nuevos
     {nume:18 ,name:'Acción popular', handle:'accion_popular', type:'nulidad', depa:'regional'},
     //nulidad nuevos
     //{nume:1, name: 'Conflicto de Competencia entre Salas Regionales', handle:'conflicto', type: 'nulidad', depa:'superior'},
     //{nume:14,name:'Cuadernillo de Amparo Sala Regional', handle:'cuadernillo_regional', type:'nulidad', depa:'regional'},
     {nume:15,name:'Conflicto de competencia', handle:'conflicto_competencia', type:'nulidad', depa:'superior'},
     {nume:2,name:'Cuadernillo de Amparo en Sala Superior', handle:'cuadernillo_superior', type:'nulidad', depa:'superior'},
     {nume:17 ,name:'Cuadernillo de antecedentes de expediente', handle:'cuadernillo_antecedentes_expediente', type:'nulidad', depa:'regional'},
 
     {nume:3,name:'Cuaderno auxiliar', handle:'cuaderno', type:'nulidad', depa:'ambos'},
     {nume:4,name:'Cumplimiento de Sentencia en Sala Superior', handle:'cumplimiento', type:'nulidad', depa:'superior'},
     {nume:5,name:'Excitativa de Justicia', handle:'excitativas', type:'nulidad', depa:'superior'},
     {nume:16,name:'Excusa por impedimento', handle:'excusa_impedimento', type:'nulidad', depa:'superior'},
 
     {nume:6,name:'Expediente de varios', handle:'expediente', type:'nulidad', depa:'ambos'},
     {nume:8,name:'Juicio Administrativo', handle:'juicio_administrativo', type:'nulidad', depa:'regional'},
     {nume:7,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
     //{nume:9,name:'Juicio Fiscal', handle:'juicio_fiscal', type:'nulidad', depa:'regional'},
     {nume:10,name:'Juicio Sumario', handle:'juicio_sumario', type:'nulidad', depa:'regional'},
     
     {nume:11,name:'Procedimiento de Responsabilidad Administrativa por Faltas Graves', handle:'responsabilidad_administrativa_falta_grave', type:'nulidad', depa:'regional'},
     {nume:12,name:'Recurso de Apelación', handle:'recurso_apelacion', type:'nulidad', depa:'superior'},
     {nume:14,name:'Recurso de Inconformidad', handle:'recurso_inconformidad', type:'nulidad', depa:'regional'},
     {nume:13,name:'Recurso de Reclamación ', handle:'recurso_reclamacion', type:'nulidad', depa:'regional'},
     {nume:19,name:'Recurso de Revisión', handle:'recurso_revision', type:'nulidad', depa:'superior'},
     
    //nulidad viejos
    /*{nume:1, name: 'Administrativo', handle: 'administrativo', type:'nulidad'},
    {nume:2, name: 'Fiscal', handle: 'fiscal', type:'nulidad'},
    {nume:3, name: 'Responsabilidades Administrativas', handle: 'responsabilidad_administrativa', type:'nulidad'},
    {nume:4, name: 'Sumario', handle: 'sumario', type:'nulidad'},
    {nume:5, name: 'Acción Popular', handle: 'accion_popular', type:'nulidad'},
    {nume:6, name: 'Responsabilidades Administrativas por faltas graves', handle: 'responsabilidad_administrativa_falta_grave', type:'nulidad'},
    {nume: 7, name: 'Expediente de varios', handle: 'expediente_varios', type:'nulidad'},
    {nume: 8, name: 'Cuaderno auxiliar', handle: 'cuaderno_auxiliar', type: 'nulidad'}*/
]

export const TypeProces = [
    //grave
    {nume:10, name: 'Conflicto de competencia', handle: 'conflicto_competencias', type:'grave'},
    {nume:13, name: 'Cumplimiento de ejecutoria de amparo', handle: 'cumplimiento_ejecutoria_amparo', type:'grave'},
    {nume:11, name: 'Excitativa de justicia', handle: 'excitativa_justicia', type:'grave'},
    {nume:12, name: 'Excusa', handle: 'excusa', type:'grave'},

    {nume:1, name: 'Juicio ordinario', handle: 'juicio_ordinario', type:'grave'},
    {nume:2, name: 'Juicio sumario', handle: 'juicio_sumario', type:'grave'},
    {nume:9, name: 'Procedimiento de cumplimiento de sentencia', handle: 'procedimiento_cumplimiento_sentencia', type:'grave'},
    {nume:8, name: 'Procedimiento de responsabilidad administrativa', handle: 'procedimiento_responsabilidad_administrativa', type:'grave'},
    {nume:7, name: 'Recurso de apelación', handle: 'recurso_apelacion', type:'grave'},
    {nume:6, name: 'Recurso de inconformidad', handle: 'recurso_inconformidad', type:'grave'},
    {nume:5, name: 'Recurso de reclamación', handle: 'recurso_reclamacion', type:'grave'},
    {nume:4, name: 'Recurso de revocación', handle: 'recurso_revocacion', type:'grave'},
    {nume:3, name: 'Recurso de revisión', handle: 'recurso_revision', type:'grave'},
]

export const TypeProces2 = [
     {nume:10, name: 'Conflicto de competencia', handle: 'conflicto_competencias', type:'grave'},
    {nume:13, name: 'Cumplimiento de ejecutoria de amparo', handle: 'cumplimiento_ejecutoria_amparo', type:'grave'},
    {nume:11, name: 'Excitativa de justicia', handle: 'excitativa_justicia', type:'grave'},
    {nume:12, name: 'Excusa', handle: 'excusa', type:'grave'},

    {nume:1, name: 'Juicio ordinario', handle: 'juicio_ordinario', type:'grave'},
    {nume:2, name: 'Juicio sumario', handle: 'juicio_sumario', type:'grave'},
    {nume:9, name: 'Procedimiento de cumplimiento de sentencia', handle: 'procedimiento_cumplimiento_sentencia', type:'grave'},
    {nume:8, name: 'Procedimiento de responsabilidad administrativa', handle: 'procedimiento_responsabilidad_administrativa', type:'grave'},
    {nume:7, name: 'Recurso de apelación', handle: 'recurso_apelacion', type:'grave'},
    {nume:6, name: 'Recurso de inconformidad', handle: 'recurso_inconformidad', type:'grave'},
    {nume:5, name: 'Recurso de reclamación', handle: 'recurso_reclamacion', type:'grave'},
    {nume:4, name: 'Recurso de revocación', handle: 'recurso_revocacion', type:'grave'},
    {nume:3, name: 'Recurso de revisión', handle: 'recurso_revision', type:'grave'},
]
export const TipoRecurso = [
    {nume:10, name: 'Conflicto de competencia', handle: 'conflicto_competencias', type:'grave'},
    {nume:13, name: 'Cumplimiento de ejecutoria de amparo', handle: 'cumplimiento_ejecutoria_amparo', type:'grave'},
    {nume:11, name: 'Excitativa de justicia', handle: 'excitativa_justicia', type:'grave'},
    {nume:12, name: 'Excusa', handle: 'excusa', type:'grave'},

    {nume:1, name: 'Juicio ordinario', handle: 'juicio_ordinario', type:'grave'},
    {nume:2, name: 'Juicio sumario', handle: 'juicio_sumario', type:'grave'},
    {nume:9, name: 'Procedimiento de cumplimiento de sentencia', handle: 'procedimiento_cumplimiento_sentencia', type:'grave'},
    {nume:8, name: 'Procedimiento de responsabilidad administrativa', handle: 'procedimiento_responsabilidad_administrativa', type:'grave'},
    {nume:7, name: 'Recurso de apelación', handle: 'recurso_apelacion', type:'grave'},
    {nume:6, name: 'Recurso de inconformidad', handle: 'recurso_inconformidad', type:'grave'},
    {nume:5, name: 'Recurso de reclamación', handle: 'recurso_reclamacion', type:'grave'},
    {nume:4, name: 'Recurso de revocación', handle: 'recurso_revocacion', type:'grave'},
    {nume:3, name: 'Recurso de revisión', handle: 'recurso_revision', type:'grave'},
   
];

export const TypeProcesMagSup = [
    //grave nuevos
    {nume:10, name: 'Conflicto de competencias', handle: 'conflicto_competencias', type:'grave'},
    {nume:13, name: 'Cumplimiento de ejecutoria de amparo', handle: 'cumplimiento_ejecutoria_amparo', type:'grave'},
    {nume:11, name: 'Excitativa de justicia', handle: 'excitativa_justicia', type:'grave'},
    {nume:12, name: 'Excusa', handle: 'excusa', type:'grave'},

    {nume:1, name: 'Juicio ordinario', handle: 'juicio_ordinario', type:'grave'},
    {nume:2, name: 'Juicio sumario', handle: 'juicio_sumario', type:'grave'},
    {nume:9, name: 'Procedimiento de cumplimiento de sentencia', handle: 'procedimiento_cumplimiento_sentencia', type:'grave'},
    {nume:8, name: 'Procedimiento de responsabilidad administrativa', handle: 'procedimiento_responsabilidad_administrativa', type:'grave'},
    {nume:7, name: 'Recurso de apelación', handle: 'recurso_apelacion', type:'grave'},
    {nume:6, name: 'Recurso de inconformidad', handle: 'recurso_inconformidad', type:'grave'},
    {nume:5, name: 'Recurso de reclamación', handle: 'recurso_reclamacion', type:'grave'},
    {nume:4, name: 'Recurso de revocación', handle: 'recurso_revocacion', type:'grave'},
    {nume:3, name: 'Recurso de revisión', handle: 'recurso_revision', type:'grave'},
  
]

export const MagPortalSentences = [
    {name:'Claudio Gorostieta Cedillo'},
    {name:'Gerardo Rodrigo Lara García'},
    {name:'Miguel Ángel Vázquez del Pozo'},
    {name:'Erick Ismael Lara Cuellar'},
    {name:'Alma Delia Aguilar González'},
    {name:'Blanca Dannaly Argumedo Guerra'},
    {name:'Víctor Alfonso Chávez López'},
    {name:'Baruch Florente Delgado Carbajal'},
    {name:'Lydia Elizalde Mendoza'},
    {name:'Gabriela Fuentes Reyes'},
    {name:'Alberto Gándara Ruiz Esparza'},
    {name:'Myrna Araceli García Morón'},
    {name:'Rafael González Oses Cerezo'},
    {name:'Agustín Guerrero Traspaderne'},
    {name:'Laura Xóchitl Hernández Vargas'},
    {name:'Arlen Siu Jaime Merlos'},
    {name:'Teresa de Jesús Martínez Ibáñez'},
    {name:'Luis Octavio Martínez Quijada'},
    {name:'Cesar De Jesús Molina Suárez'},
    {name:'Teresita Del Niño Jesús Palacios Iniestra'},
    {name:'Diana Elda Pérez Medina'},
    {name:'Jorge Torres Rodríguez'},
    {name:'América Elizabeth Trejo de la Luz'},
    {name:'Ana Luisa Villegas Brito'},
    {name:'Alberta Virginia Valdés Chávez'},
    {name:'María de los Ángeles Ávila Nativitas'},
    {name:'Rocío Sánchez Molina'},
    {name:'Carlos Antonio Alpizar Salazár'},
    {name:'Joel Alejandro Gutiérrez Toledano'},
    {name:'Juan Cuellar Durán'},
    {name:'Salvador Valle Santana'},
    {name:'Gilda Anahí González Reynoso'},
    {name:'Miguel Ángel Terrón Mendoza'},
    {name:'Sergio Ramón Macedo López'},
    {name:'Hipólito Galicia Ruíz Gerardo Becker Ania'},
    {name:'Ana Laura Martínez Moreno'},
    {name:'Mirna Mónica Ochoa López'},
    {name:'Gerardo Castrejón Carrasco'},
    {name:'Jacinto Policarpo Montes de Oca Vázquez'},
    {name:'Eduardo Salgado Pedraza'},
    {name:'Hilda Nely Servín Moreno'},
    {name:'Reyna Adela González Avilés'},
    {name:'Salvador Salazar Barrientos'},
]

export const OrganoEmisorPortal = [
    {name:'Primera Sección de la Sala Superior'},
    {name:'Segunda Sección de la Sala Superior'},
    {name:'Tercera Sección de la Sala Superior'},
    {name:'Cuarta Sección de la Sala Superior Especializada en Materia de Responsabilidades Administrativas'},
    {name:'Primera Sala Regional Toluca'},
    {name:'Segunda Sala Regional Naucalpan de Juárez'},
    {name:'Tercera Sala Regional Tlalnepantla de Baz'},
    {name:'Cuarta Sala Regional Ecatepec de Morelos'},
    {name:'Quinta Sala Regional Nezahualcóyotl'},
    {name:'Sexta Sala Regional Atizapán de Zaragoza'},
    {name:'Séptima Sala Regional Toluca'},
    {name:'Octava Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de Toluca'},
    {name:'Novena Sala Regional Especializada en Materia de Responsabilidades Administrativas Valle de México'},
    {name:'Magistraturas Supernumerarias Valle de Toluca'},
]

export const MateriaPortal = [
    {name:'Jurisdicción Ordinaria (Responsabilidades Administrativas)'},
    {name:'Jurisdicción Ordinaria'},
    {name:'Jurisdicción Especializada en Materia de Responsabilidades Administrativas'},
]

export const TypeEmiPortal = [
    {name:'Nueva'},
    {name:'Dictada por la Sala Superior en cumplimiento a la ejecutoria de amparo'},
    {name:'Emitida por Salas Regionales en cumplimiento de lo ordenado por la Sala Superior'},
    {name:'Aclaración de Sentencia'},
    {name:'Convenio'},
]