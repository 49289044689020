
import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourcesProvider } from 'app/providers/resources.provider';
import { ExpedientProvider } from 'app/providers/expedient.provider';
import { UsersProvider } from "app/providers/users.provider";
import { MatStepper } from '@angular/material/stepper';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { FileAnexo } from 'app/main/promotions/demanda-form/options.data.form';

import {MatIconModule} from '@angular/material/icon';

@Component({
    selector     : 'pdfMod',
    templateUrl  : './pdf.component.html',
    styleUrls    : ['./pdf.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MailComposeDialogComponentPdf
{
    isLoading    :boolean = false;
    //pdf
    page:number = 1;
    pdfSrc:string = '';

    @ViewChild('adju') adju:ElementRef;
    @ViewChild('stepper') stepper:MatStepper;

    /**
     * Constructor
     *
     * @param {MatDialogRef<MailComposeDialogComponentPdf>} matDialogRef
     * @param _data
     */
    constructor(
        public matDialogRef: MatDialogRef<MailComposeDialogComponentPdf>,
        private _formBuilder: FormBuilder, 
        private _formBuilder2: FormBuilder, 
        private cd: ChangeDetectorRef,
        private _expedientServices: ExpedientProvider,
        private _usersService: UsersProvider,
        private _resourcesService: ResourcesProvider,
        private _settings: AppConfigService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    )
    {

    }

    ngOnInit() {
        
        //const fileUpload = this[`${ this._data }`].nativeElement;
        //const [file1] = fileUpload.files;
        //console.log(this._data);
        if (this._data.type == 0) {
            let pdfUrl = URL.createObjectURL(this._data.file);
            document.querySelector('#previewF').setAttribute('src', pdfUrl);
        } else {
            document.querySelector('#previewF').setAttribute('src', this._data.file);
        }
        //const reader = new FileReader();     
        //console.log(this._data.file, pdfUrl);
            
    }





}

const ArrayFiles = [
    new FileAnexo('anexoFile0'),
]; 
